import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import { useGetCountriesQuery, useGetDistrictsQuery, useGetTehsilsQuery, useGetUnionCouncilsQuery, useGetVillagesQuery } from "../../services/Api";
import Select from 'react-select';
import PhoneInput from "react-phone-input-2";

const AddCenterModal = (props) => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedTehsil, setSelectedTehsil] = useState(null);
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState(null);
    const [selectedVillage, setSelectedVillage] = useState(null);

    const [countryName, setCountryName] = useState('');
    const [districtName, setDistrictName] = useState('');
    const [tehsilName, setTehsilName] = useState('');
    const [unionCouncilName, setUnionCouncilName] = useState('');
    const [villageName, setVillageName] = useState('');

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const {
        data: getDistricts,
        refetch: getDistrictsRefetch,
    } = useGetDistrictsQuery();

    const {
        data: getTehsils,
        refetch: getTehsilsRefetch,
    } = useGetTehsilsQuery();

    const {
        data: getUnionCouncils,
        refetch: getUnionCouncilsRefetch,
    } = useGetUnionCouncilsQuery();

    const {
        data: getVillages,
        refetch: getVillagesRefetch,
    } = useGetVillagesQuery();

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    const onSubmit = () => {

        const data = {
            name: name,
            village: selectedVillage?.value || villageName?.value,
            // unionCouncil: selectedUnionCouncil?.value || unionCouncilName?.value,
            // tehsil: selectedTehsil?.value || tehsilName?.value,
            // district: selectedDistrict?.value || districtName?.value,
            country: selectedCountry?.value || countryName?.value,
            address: address,
            contactNo: "+" + phone,
        };

        const dataWithId = {
            name: name,
            village: selectedVillage?.value || villageName?.value,
            // unionCouncil: selectedUnionCouncil?.value || unionCouncilName?.value,
            // tehsil: selectedTehsil?.value || tehsilName?.value,
            // district: selectedDistrict?.value || districtName?.value,
            country: selectedCountry?.value || countryName?.value,
            address: address,
            contactNo: "+" + phone,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    const validationHandler = () => {
        let noError = false;

        nameValidationHandler();
        addressValidationHandler();
        phoneValidationHandler();
        countryValidationHandler();
        villageValidationHandler();
        // districtValidationHandler();
        // tehsilValidationHandler();
        // ucValidationHandler();

        // if (name && (selectedCountry || countryName?.value) && (selectedDistrict || districtName?.value) && (selectedTehsil || tehsilName?.value) && (selectedUnionCouncil || unionCouncilName?.value) && (selectedVillage || villageName?.value)) {
        //     onSubmit();
        // } else {
        //     return false;
        // }

        if (name && (selectedCountry || countryName?.value) && phone && address && (selectedVillage || villageName?.value)) {
            onSubmit();
        } else {
            return false;
        }

        return noError;
    };

    const nameValidationHandler = () => {
        if (!name) {
            setError("name", { type: "custom", message: "Name is required" });
        } else {
            clearErrors("name");
        }
    };

    const addressValidationHandler = () => {
        if (!address) {
            setError("address", { type: "custom", message: "Address is required" });
        } else {
            clearErrors("address");
        }
    };

    const phoneValidationHandler = () => {
        if (!phone) {
            setError("phone", { type: "custom", message: "Phone is required" });
        } else {
            clearErrors("phone");
        }
    };

    const countryValidationHandler = () => {
        if (!selectedCountry && !countryName) {
            setError("country", { type: "custom", message: "Country is required" });
        } else {
            clearErrors("country");
        }
    };

    const districtValidationHandler = () => {
        if (!selectedDistrict && !districtName) {
            setError("district", { type: "custom", message: "District is required" });
        } else {
            clearErrors("district");
        }
    };

    const tehsilValidationHandler = () => {
        if (!selectedTehsil && !tehsilName) {
            setError("tehsil", { type: "custom", message: "Tehsil is required" });
        } else {
            clearErrors("tehsil");
        }
    };

    const ucValidationHandler = () => {
        if (!selectedUnionCouncil && !unionCouncilName) {
            setError("unionCouncil", { type: "custom", message: "Union Council is required" });
        } else {
            clearErrors("unionCouncil");
        }
    };

    const villageValidationHandler = () => {
        if (!selectedVillage && !villageName) {
            setError("village", { type: "custom", message: "Village is required" });
        } else {
            clearErrors("village");
        }
    };

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            case 'district':
                setSelectedDistrict(selectedOption);
                break;
            case 'tehsil':
                setSelectedTehsil(selectedOption);
                break;
            case 'unionCouncil':
                setSelectedUnionCouncil(selectedOption);
                break;
            case 'village':
                setSelectedVillage(selectedOption);
                break;
            default:
                break;
        }
    };

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
            id: locations?.countryId
        }));
    };

    // const useFilteredData = (data, selectedValue, key) => {

    //     const [filteredData, setFilteredData] = useState([]);

    //     useEffect(() => {
    //         if (selectedValue) {
    //             const filtered = data?.filter(
    //                 (item) => item[key] == selectedValue?.value
    //             );
    //             setFilteredData(filtered);
    //         } else {
    //             setFilteredData([]);
    //         }
    //     }, [selectedValue, data, key]);

    //     return filteredData;
    // };

    // const filteredTehsils = useFilteredData(getTehsils, selectedDistrict, 'districtId');
    // const filteredUnionCouncils = useFilteredData(getUnionCouncils, selectedTehsil, 'tehsilId');
    // const filteredVillages = useFilteredData(getVillages, selectedUnionCouncil, 'unionCouncilId');

    const filteredVillages = getVillages?.filter(data => data?.countryId == selectedCountry?.id) || [];

    const allCountries = preprocessLocationData(getCountries || []);
    const allDistricts = preprocessLocationData(getDistricts || []);
    const allTehsils = preprocessLocationData(getTehsils || []);
    const allUnionCouncils = preprocessLocationData(getUnionCouncils || []);
    const allVillages = preprocessLocationData(filteredVillages || []);

    useEffect(() => {
        updateName('country', props?.data?.country);
        updateName('district', props?.data?.district);
        updateName('tehsil', props?.data?.tehsil);
        updateName('unionCouncil', props?.data?.unionCouncil);
        updateName('village', props?.data?.village);
    }, [getCountries, getDistricts, getTehsils, getUnionCouncils, getVillages]);

    useEffect(() => {
        setName(props?.data?.name);
        setAddress(props?.data?.address);
        setPhone(props?.data?.contactNo);
    }, [props?.data]);

    const updateName = (type, value) => {
        const allEntities = {
            country: allCountries,
            district: allDistricts,
            tehsil: allTehsils,
            unionCouncil: allUnionCouncils,
            village: allVillages
        };

        const setNameFunctions = {
            country: setCountryName,
            district: setDistrictName,
            tehsil: setTehsilName,
            unionCouncil: setUnionCouncilName,
            village: setVillageName
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.value == value);
            if (entity) {
                setName(entity);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        getCountriesRefetch();
        getVillagesRefetch();
        // getDistrictsRefetch();
        // getTehsilsRefetch();
        // getUnionCouncilsRefetch();
    }, []);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddCenterModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Center</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Center</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Name' name="name" onChange={(e) => setName(e.target.value)} value={name} disabled={props?.view ? true : false} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Address</label>
                                <input type="text" className="themeInput fs-4" placeholder='Address' name="address" onChange={(e) => setAddress(e.target.value)} value={address} disabled={props?.view ? true : false} />

                                {errors.address && <p className='errorMsg'>{errors.address.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Phone</label>
                                <PhoneInput country={'pk'} value={phone} onChange={setPhone} disabled={props?.view ? true : false} />

                                {errors.phone && <p className='errorMsg'>{errors.phone.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Country</label>
                                <Select
                                    options={allCountries}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="country"
                                    placeholder={props?.data?.country?.name}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.country && <p className='errorMsg'>{errors.country.message}</p>}
                            </div>

                            {/* <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Districts</label>
                                <Select
                                    options={allDistricts}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="district"
                                    placeholder={districtName?.label}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.district && <p className='errorMsg'>{errors.district.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Tehsils</label>
                                <Select
                                    options={allTehsils}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="tehsil"
                                    placeholder={tehsilName?.label}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.tehsil && <p className='errorMsg'>{errors.tehsil.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Union Councils</label>
                                <Select
                                    options={allUnionCouncils}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="unionCouncil"
                                    placeholder={unionCouncilName?.label}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.unionCouncil && <p className='errorMsg'>{errors.unionCouncil.message}</p>}
                            </div> */}

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Village</label>
                                <Select
                                    options={allVillages}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="village"
                                    placeholder={props?.data?.village?.name}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.village && <p className='errorMsg'>{errors.village.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={validationHandler}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddCenterModal;