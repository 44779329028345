import React, { useEffect } from 'react';
import { halfLogo } from '../../assets/imagesPath';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { useGetEmailTemplatesQuery } from '../../services/Api';

const EmailTemplates = () => {

    const navigate = useNavigate();

    const {
        data: getEmailTemplates,
        refetch: getEmailTemplatesRefetch,
    } = useGetEmailTemplatesQuery();

    useEffect(() => {
        getEmailTemplatesRefetch();
    }, []);

    return (
        <>
            <div className="row">
                {getEmailTemplates?.map((data, index) => {
                    return (
                        <div className="col-md-4" key={index}>
                            <div class="card p-4 cursor" onClick={() => navigate(PATHS.editEmailTemplates, { state: { data: data } })}>
                                <div className='text-center'>
                                    <img src={halfLogo} class="card-img-top w-25" alt="" />
                                </div>
                                <div class="card-body">
                                    <h3 class="mb-3 text-capitalize"><b>Name: {data?.type}</b></h3>
                                    <h3 class="mb-0"><b>Subject: {data?.subject}</b></h3>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default EmailTemplates