import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useAddContactsMutation, useDeleteContactsMutation, useEditContactsMutation, useGetCountriesQuery, useGetContactsQuery, useSendEmailsMutation } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { active, deactive, del, dots, edit, loader, view } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import Select from 'react-select';
import AddContactModal from '../../components/allModals/AddContactModal';
import SendEmailModal from '../../components/allModals/SendEmailModal';

const Contacts = () => {

    const [viewContactModal, setViewContactModal] = useState(false);
    const [addContactModal, setAddContactModal] = useState(false);
    const [editContactModal, setEditContactModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sendEmailModal, setSendEmailModal] = useState(false);
    const [viewContactData, setViewContactData] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [sector, setSector] = useState(null);

    const {
        data: getContacts,
        refetch: getContactsRefetch,
        isLoading: getContactsLoading,
    } = useGetContactsQuery();

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const sectorOptions = [
        { value: "clear", label: "All" },
        { value: "Education", label: "Education" },
        { value: "Health and Nutrition", label: "Health and Nutrition" },
        { value: "Infrastructure and Engineering", label: "Infrastructure and Engineering" },
        { value: "Climate Change and Environment", label: "Climate Change and Environment" },
        { value: "Water, Sanitation & Waste Management", label: "Water, Sanitation & Waste Management" },
        { value: "Monitoring, Evaluation and Learning", label: "Monitoring, Evaluation and Learning" },
        { value: "ICT Based Survey & Research/Digitization", label: "ICT Based Survey & Research/Digitization" },
        { value: "Agriculture & Rural development", label: "Agriculture & Rural development" },
        { value: "Supplies, contract & Procurement", label: "Supplies, contract & Procurement" },
        { value: "Advocacy & Strategy", label: "Advocacy & Strategy" },
        { value: "Energy", label: "Energy" },
        { value: "Humanterian Assistance", label: "Humanterian Assistance" },
        { value: "Crisis and Peacebuilding", label: "Crisis and Peacebuilding" },
        { value: "Democracy & Governance", label: "Democracy & Governance" },
        { value: "International Development", label: "International Development" },
        { value: "Human Resource Development", label: "Human Resource Development" },
    ];

    const handleLocationChange = (selectedOption, { name }) => {
        if (selectedOption && selectedOption?.value == "clear") {
            setSector(null);
        } else {
            switch (name) {
                case 'country':
                    setSelectedCountry(selectedOption);
                    break;
                case 'sector':
                    setSector(selectedOption);
                    break;
                default:
                    break;
            }
        }
    };

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const allCountries = preprocessLocationData(getCountries || []);

    const viewContactsModalHandler = (data) => {
        setViewContactModal((prev) => !prev);
        setViewContactData(data);
    };

    const addContactsModalHandler = () => {
        setAddContactModal((prev) => !prev);
    };

    const sendEmailModalHandler = () => {
        setSendEmailModal((prev) => !prev);
    };

    const editContactsModalHandler = (data) => {
        setEditContactModal((prev) => !prev);
        setViewContactData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewContactData(data);
    };

    const [addContacts, { isLoading: addContactsLoading }] = useAddContactsMutation();
    const [editContacts, { isLoading: editContactsLoading }] = useEditContactsMutation();
    const [deleteContacts, { isLoading: deleteContactsLoading }] = useDeleteContactsMutation();
    const [sendEmails, { isLoading: sendEmailsLoading }] = useSendEmailsMutation();

    const addContactApiHandler = (data) => {
        addContacts({ data: data })
            .unwrap()
            .then(() => {
                addContactsModalHandler();
                getContactsRefetch();
                toast.success("Contact added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editContactsApiHandler = (data) => {
        editContacts({ data: data })
            .unwrap()
            .then(() => {
                editContactsModalHandler();
                getContactsRefetch();
                toast.success("Contact updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const sendEmailApiHandler = (data) => {
        sendEmails({ data: data })
            .unwrap()
            .then(() => {
                sendEmailModalHandler();
                toast.success("Email sent successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteContactsApiHandler = (id) => {
        deleteContacts({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getContactsRefetch();
                toast.success("Contact deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    useEffect(() => {
        const applyFilters = () => {
            let filtered = getContacts || [];
            if (selectedCountry) {
                filtered = filtered?.filter(Contact => Contact?.country == selectedCountry?.label);
            }

            if (sector) {
                filtered = filtered?.filter(Contact => Contact?.sector == sector?.label);
            }
            setFilteredData(filtered);
        };

        applyFilters();
    }, [sector, selectedCountry, getContacts]);

    useEffect(() => {
        getContactsRefetch();
        getCountriesRefetch();
    }, []);

    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            setSelectedIds(prevSelected => [...prevSelected, id]);
        } else {
            setSelectedIds(prevSelected => prevSelected.filter(selectedId => selectedId !== id));
        }
    };

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Contacts</h1>

                <div className='row'>
                    <div className='col-md-3'>
                        <input
                            id='localSearchInput'
                            className='themeInput w-100'
                            placeholder="Search Contact"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => localSearchTableFunction(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Select
                            options={sectorOptions}
                            onChange={handleLocationChange}
                            className='w-100 me-3 fs-4'
                            name="sector"
                            placeholder="Filter by Sector"
                        />
                    </div>
                    <div className='col-md-3'>
                        <Select
                            options={allCountries}
                            onChange={handleLocationChange}
                            className='w-100 me-3 fs-4'
                            name="country"
                            placeholder="Filter by Country"
                        />
                    </div>
                    <div className='col-md-3 text-end'>
                        {selectedIds?.length ?
                            <Button
                                label="Send Email"
                                className="themeBtn me-3"
                                onClick={sendEmailModalHandler}
                            />
                            : null}

                        <Button
                            label="+ Add Contact"
                            className="themeBtn"
                            onClick={addContactsModalHandler}
                        />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    checked={filteredData.length > 0 && selectedIds.length === filteredData.length}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedIds(filteredData.map(data => data._id));
                                        } else {
                                            setSelectedIds([]);
                                        }
                                    }}
                                />
                            </th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Country</th>
                            <th>Sector</th>
                            <th>Organization</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.length ? filteredData?.map((data, index) => (
                            <tr
                                className="border-top mainDiv cursor"
                                key={index}
                                onClick={() => viewContactsModalHandler(data)}
                            >
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedIds.includes(data._id)}
                                        onChange={(e) => handleCheckboxChange(e, data._id)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </td>
                                <td>{index + 1}</td>
                                <td>{data?.name}</td>
                                <td>{data?.country}</td>
                                <td>{data?.sector}</td>
                                <td>{data?.organizationName}</td>
                                <td onClick={(e) => e.stopPropagation()}>
                                    <div className='d-flex align-items-center gap-3'>
                                        <img src={view} alt="" className='actionIcons' onClick={() => viewContactsModalHandler(data)} title='View' />
                                        <img src={edit} alt="" className='actionIcons' onClick={() => editContactsModalHandler(data)} title='Edit' />
                                        <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                    </div>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={8}>
                                    {getContactsLoading ? (
                                        <div className='text-center'>
                                            <img src={loader} height={100} alt="" />
                                        </div>
                                    ) : (
                                        <h2 className='text-center mt-5'>No Record Found</h2>
                                    )}
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td colSpan={8}>
                                <p className='text-center mt-2 fs-5 mb-0 w-100' id="noRecordsFound" style={{ display: "none" }}>No Record Found</p>
                            </td>
                        </tr>

                    </tbody>
                </Table>

                {/* <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} /> */}

                {addContactModal &&
                    <AddContactModal
                        handleCloseAddContactModal={addContactsModalHandler}
                        action={addContactApiHandler}
                        loading={addContactsLoading}
                    />
                }

                {sendEmailModal &&
                    <SendEmailModal
                        handleCloseSendEmailModal={sendEmailModalHandler}
                        action={sendEmailApiHandler}
                        ids={selectedIds}
                        loading={sendEmailsLoading}
                    />
                }

                {editContactModal &&
                    <AddContactModal
                        handleCloseAddContactModal={editContactsModalHandler}
                        action={editContactsApiHandler}
                        loading={editContactsLoading}
                        data={viewContactData}
                    />
                }

                {viewContactModal &&
                    <AddContactModal
                        handleCloseAddContactModal={viewContactsModalHandler}
                        data={viewContactData}
                        view={true}
                    />
                }

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Contact"
                        id={viewContactData?._id}
                        action={deleteContactsApiHandler}
                        loading={deleteContactsLoading}
                    />
                }
            </div>
        </>
    )
}

export default Contacts;