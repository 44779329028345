export const BASE_URL = 'https://mis-server-2kjx.onrender.com/api/v1/';
// export const BASE_URL = 'http://localhost:5001/api/v1/';
export const BASE_URL_IMAGES = 'https://mis-server-2kjx.onrender.com/images/';
export const BASE_URL_FILES = 'https://mis-server-2kjx.onrender.com/files/';

// export const BASE_URL = 'https://momentum-mis.onrender.com/api/v1/';
// export const BASE_URL_IMAGES = 'https://momentum-mis.onrender.com/images/';

export const API_END_POINTS = {
    /////////////////////////////<===MUTATIONS===>//////////////////////////////
    login: BASE_URL + 'users/signin',
    usersCrud: BASE_URL + 'users',
    usersCrudList: BASE_URL + 'users/list',
    countryCrud: BASE_URL + 'countries',
    statesCrud: BASE_URL + 'states',
    citiesCrud: BASE_URL + 'cities',
    districtsCrud: BASE_URL + 'districts',
    tehsilsCrud: BASE_URL + 'tehsils',
    unionCouncilsCrud: BASE_URL + 'unionCouncils',
    villagesCrud: BASE_URL + 'villages',
    partnersCrud: BASE_URL + 'partners',
    centersCrud: BASE_URL + 'centers',
    projectsCrud: BASE_URL + 'projects',
    teachersCrud: BASE_URL + 'teachers',
    uploadTeacherAvatar: BASE_URL + 'teachers/uploadAvatar',
    questionsCrud: BASE_URL + 'questions',
    questionnairesCrud: BASE_URL + 'questionnaires',
    uploadGuideFile: BASE_URL + 'questionnaires/uploadGuide',
    assesmentsCrud: BASE_URL + 'assessments',
    uploadCountries: BASE_URL + 'countries/upload',
    uploadStates: BASE_URL + 'states/upload',
    uploadCities: BASE_URL + 'cities/upload',
    uploadDistricts: BASE_URL + 'districts/upload',
    uploadTehsils: BASE_URL + 'tehsils/upload',
    uploadUnionCouncils: BASE_URL + 'unionCouncils/upload',
    uploadVillages: BASE_URL + 'villages/upload',
    forgotPassword: BASE_URL + 'users/forgot-password',
    verifyOtp: BASE_URL + 'users/forgot-password/verify-otp',
    newPassword: BASE_URL + 'users/forgot-password/new-password',
    emailTemplatesCrud: BASE_URL + 'emailTemplates',
    answersCrud: BASE_URL + 'answers',
    getCollectorAssesments: BASE_URL + 'assessments/collector',
    getCollectorRecords: BASE_URL + 'assessments/collector-records',
    getCollectorStatus: BASE_URL + 'assessments/collector-status',
    startNewRecord: BASE_URL + 'assessmentRecord',
    questionnaireRecord: BASE_URL + 'questionnaireRecord',
    getAssesmentsByCountry: BASE_URL + 'assessments/country',
    filterChartByAnswers: BASE_URL + 'answers/aggregated',
    contactsCrud: BASE_URL + 'contacts',
    contactEmailsCrud: BASE_URL + 'contactEmails',
    contactEmailTemplatesCrud: BASE_URL + 'contactEmailTemplates',
    contactSentEmails: BASE_URL + 'contactSentEmails',
    dashboardCount: BASE_URL + 'dashboard/count',
};
