import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useAddProjectsMutation, useDeleteProjectsMutation, useEditProjectsMutation, useGetCentersQuery, useGetCountriesQuery, useGetProjectsQuery, useGetUsersQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { active, deactive, del, dots, edit, loader, view } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import AddProjectModal from '../../components/allModals/AddProjectModal';
import moment from 'moment/moment';
import ChangeStatusModal from '../../components/allModals/ChangeStatusModal';
import Select from 'react-select';

const Projects = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [viewProjectModal, setViewProjectModal] = useState(false);
    const [addProjectModal, setAddProjectModal] = useState(false);
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewProjectData, setViewProjectData] = useState("");
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [status, setStatus] = useState('all');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const {
        data: getProjects,
        refetch: getProjectsRefetch,
        isLoading: getProjectsLoading,
    } = useGetProjectsQuery();

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const {
        data: getCenters,
        refetch: getCentersRefetch,
    } = useGetCentersQuery();

    const {
        data: getUsers,
        refetch: getUsersRefetch,
    } = useGetUsersQuery();

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            default:
                break;
        }
    };

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const allCountries = preprocessLocationData(getCountries || []);

    const itemsPerPage = 20;
    const totalItems = getProjects?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getProjects?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const viewProjectsModalHandler = (data) => {
        setViewProjectModal((prev) => !prev);
        setViewProjectData(data);
    };

    const addProjectsModalHandler = () => {
        setAddProjectModal((prev) => !prev);
    };

    const editProjectsModalHandler = (data) => {
        setEditProjectModal((prev) => !prev);
        setViewProjectData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewProjectData(data);
    };

    const changeStatusModalHandler = (data) => {
        setChangeStatusModal((prev) => !prev);
        setViewProjectData(data);
    };

    const [addProjects, { isLoading: addProjectsLoading }] = useAddProjectsMutation();
    const [editProjects, { isLoading: editProjectsLoading }] = useEditProjectsMutation();
    const [deleteProjects, { isLoading: deleteProjectsLoading }] = useDeleteProjectsMutation();
    const [changeStatus, { isLoading: changeStatusLoading }] = useEditProjectsMutation();

    const addProjectApiHandler = (data) => {
        addProjects({ data: data })
            .unwrap()
            .then(() => {
                addProjectsModalHandler();
                getProjectsRefetch();
                toast.success("Project added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editProjectsApiHandler = (data) => {
        editProjects({ data: data })
            .unwrap()
            .then(() => {
                editProjectsModalHandler();
                getProjectsRefetch();
                toast.success("Project updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteProjectsApiHandler = (id) => {
        deleteProjects({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getProjectsRefetch();
                toast.success("Project deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const changeStatusApiHandler = (user) => {

        const data = {
            isActive: user?.isActive ? false : true,
            id: user?.id,
        };

        changeStatus({ data: data })
            .unwrap()
            .then(() => {
                changeStatusModalHandler();
                getProjectsRefetch();
                toast.success("Status changed successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    useEffect(() => {
        const applyFilters = () => {
            let filtered = getProjects || [];
            if (selectedCountry) {
                filtered = filtered?.filter(partner => partner?.country?.id == selectedCountry?.value);
            }
            if (status != 'all') {
                filtered = filtered.filter(partner => partner.isActive == (status == 'active'));
            }
            setFilteredData(filtered);
        };

        applyFilters();
    }, [status, selectedCountry, getProjects]);

    useEffect(() => {
        getProjectsRefetch();
        getCountriesRefetch();
        getCentersRefetch();
        getUsersRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Projects</h1>

                <div className='row'>
                    <div className='col-md-3'>
                        <input
                            id='localSearchInput'
                            className='themeInput w-100'
                            placeholder="Search project"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => localSearchTableFunction(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3'>
                        <select className='themeInput w-100' value={status} onChange={handleStatusChange}>
                            <option value="all">Filter by Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <Select
                            options={allCountries}
                            onChange={handleLocationChange}
                            className='w-100 me-3 fs-4'
                            name="country"
                            placeholder="Filter by Country"
                        />
                    </div>
                    <div className='col-md-3 text-end'>
                        <Button
                            label="+ Add Project"
                            className="themeBtn"
                            onClick={addProjectsModalHandler}
                        />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.length ? filteredData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index} onClick={() => viewProjectsModalHandler(data)}>
                                    <td>{index + 1}</td>
                                    <td>{data?.name}</td>
                                    <td>{moment(data?.startDate).format("DD-MM-YYYY")}</td>
                                    <td>{moment(data?.endDate).format("DD-MM-YYYY")}</td>
                                    <td>{data?.location}</td>
                                    <td>{data?.isActive ? "Active" : "Inactive"}</td>
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <div className='d-flex align-items-center gap-3'>
                                            <img src={view} alt="" className='actionIcons' onClick={() => viewProjectsModalHandler(data)} title='View' />
                                            <img src={edit} alt="" className='actionIcons' onClick={() => editProjectsModalHandler(data)} title='Edit' />
                                            <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                            <img src={data?.isActive ? active : deactive} alt="" className='actionIconsd' onClick={() => changeStatusModalHandler(data)} title={data?.isActive ? 'Deactivate' : 'Activate'} style={{ width: "3.5rem" }} />
                                        </div>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-transparent table-dropdown-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img
                                                    src={dots}
                                                    className="img-fluid dots"
                                                    height={20}
                                                    width={20}
                                                    alt=""
                                                />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={() => viewProjectsModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >View Project</a>
                                                </li>
                                                <li onClick={() => editProjectsModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >Edit Project</a>
                                                </li>
                                                <li onClick={() => deleteModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >Delete Project</a>
                                                </li>
                                                <li onClick={() => changeStatusModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >{data?.isActive ? "Deactivate" : "Activate"}</a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={7}>
                                {getProjectsLoading ?
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                    : <h2 className='text-center mt-5'>No Record Found</h2>}
                            </td>
                        </tr>}

                        <tr>
                            <td colSpan={7}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {addProjectModal &&
                    <AddProjectModal
                        handleCloseAddProjectModal={addProjectsModalHandler}
                        action={addProjectApiHandler}
                        loading={addProjectsLoading}
                        centers={getCenters}
                        users={getUsers}
                    />
                }

                {editProjectModal &&
                    <AddProjectModal
                        handleCloseAddProjectModal={editProjectsModalHandler}
                        action={editProjectsApiHandler}
                        loading={editProjectsLoading}
                        data={viewProjectData}
                        centers={getCenters}
                        users={getUsers}
                    />
                }

                {viewProjectModal &&
                    <AddProjectModal
                        handleCloseAddProjectModal={viewProjectsModalHandler}
                        data={viewProjectData}
                        view={true}
                        centers={getCenters}
                        users={getUsers}
                    />
                }

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Project"
                        id={viewProjectData?._id}
                        action={deleteProjectsApiHandler}
                        loading={deleteProjectsLoading}
                    />
                }

                {changeStatusModal &&
                    <ChangeStatusModal
                        handleCloseChangeStatusModal={changeStatusModalHandler}
                        confirmationMessage={`Are you sure you want to ${viewProjectData?.isActive ? "Deactivate" : "Activate"}`}
                        name={viewProjectData?.name}
                        id={viewProjectData?._id}
                        data={viewProjectData}
                        action={changeStatusApiHandler}
                        loading={changeStatusLoading}
                    />
                }
            </div>
        </>
    )
}

export default Projects;