import React from 'react';

const ViewQuestion = ({ sectionId, subsectionId, question, responses }) => {

    const getQuestionLabel = () => (
        <>
            {question?.text} {question?.required && <span style={{ color: 'red' }}>*</span>}
        </>
    );

    // const getValue = () => {
    //     if (subsectionId) {
    //         return responses[sectionId]?.[subsectionId]?.[question?._id] || '';
    //     } else {
    //         return responses[sectionId]?.[question?._id] || '';
    //     }
    // };

    const getValue = () => {
        if (question?.answer !== null && question?.answer !== undefined) {
            return question.answer; // Use the answer if it exists
        }
        if (subsectionId) {
            return responses[sectionId]?.[subsectionId]?.[question?._id] || '';
        } else {
            return responses[sectionId]?.[question?._id] || '';
        }
    };

    // switch (question?.type) {
    //     case 'text':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="text"
    //                     className='themeInput'
    //                     placeholder='Type..'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'radio':
    //         return (
    //             <div>
    //                 <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
    //                 {question?.options?.map(option => (
    //                     <div className='d-flex align-items-center gap-3 mb-3' key={option}>
    //                         <input
    //                             type="radio"
    //                             name={question?._id}
    //                             value={option}
    //                             checked={getValue() === option}
    //                         />
    //                         <h5 className='mb-0'>{option}</h5>
    //                     </div>
    //                 ))}
    //             </div>
    //         );
    //     case 'checkbox':
    //         return (
    //             <div>
    //                 <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
    //                 {question?.options?.map(option => (
    //                     <div className='d-flex align-items-center gap-3 mb-3' key={option}>
    //                         <input
    //                             type="checkbox"
    //                             value={option}
    //                             checked={(getValue() || []).includes(option)}
    //                         />
    //                         <h5 className='mb-0'>{option}</h5>
    //                     </div>
    //                 ))}
    //             </div>
    //         );
    //     case 'file-upload':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="file"
    //                 />
    //             </div>
    //         );
    //     case 'date':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="date"
    //                     className='themeInput'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'time':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="time"
    //                     className='themeInput'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'date-time':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="datetime-local"
    //                     className='themeInput'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'phone-number':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="number"
    //                     className='themeInput'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'number':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="number"
    //                     className='themeInput'
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     case 'rating':
    //         return (
    //             <div>
    //                 <h3 className='mt-4'>{getQuestionLabel()}</h3>
    //                 <input
    //                     type="number"
    //                     className='themeInput'
    //                     min="1"
    //                     max="5"
    //                     value={getValue()}
    //                 />
    //             </div>
    //         );
    //     default:
    //         return null;
    // }

    switch (question?.type) {
        case 'text':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="text"
                        className='themeInput'
                        placeholder='Type..'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'radio':
            return (
                <div>
                    <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
                    {question?.options?.map(option => (
                        <div className='d-flex align-items-center gap-3 mb-3' key={option}>
                            <input
                                type="radio"
                                name={question?._id}
                                value={option}
                                checked={getValue() === option}
                                disabled
                            />
                            <h5 className='mb-0'>{option}</h5>
                        </div>
                    ))}
                </div>
            );
        case 'checkbox':
            return (
                <div>
                    <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
                    {question?.options?.map(option => (
                        <div className='d-flex align-items-center gap-3 mb-3' key={option}>
                            <input
                                type="checkbox"
                                value={option}
                                checked={(getValue() || []).includes(option)}
                                disabled
                            />
                            <h5 className='mb-0'>{option}</h5>
                        </div>
                    ))}
                </div>
            );
        case 'file-upload':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="file"
                        disabled
                    />
                </div>
            );
        case 'date':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="date"
                        className='themeInput'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'time':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="time"
                        className='themeInput'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'date-time':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="datetime-local"
                        className='themeInput'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'phone-number':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'number':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        case 'rating':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        min="1"
                        max="5"
                        value={getValue()}
                        disabled
                    />
                </div>
            );
        default:
            return null;
    }
};

export default ViewQuestion;