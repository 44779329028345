import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/button/Button";
import { useForm } from "react-hook-form";
import { useAddAssesmentsMutation, useEditAssesmentsMutation, useGetCountriesQuery, useGetPartnersQuery, useGetProjectsQuery, useGetQuestionnairesQuery, useGetUsersQuery } from "../../services/Api";
import Select from 'react-select';
import moment from "moment/moment";
import { add } from "../../assets/imagesPath";
import AddPartnerModal from "../../components/allModals/AddPartnerModal";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PATHS from "../../routes/Paths";

const AddAssesment = (props) => {

    const [title, setTitle] = useState("");
    const [records, setRecords] = useState("");
    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState('');
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [partnerName, setPartnerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const [openPartnerModalCondition, setOpenPartnerModalCondition] = useState("");
    const [success, setSuccess] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryName, setCountryName] = useState('');
    const [showQuestDropdown, setShowQuestDropdown] = useState(false);
    const [showQuest, setShowQuest] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: getPartners,
        refetch: getPartnersRefetch,
    } = useGetPartnersQuery();

    const {
        data: getProjects,
        refetch: getProjectsRefetch,
    } = useGetProjectsQuery();

    const {
        data: getQuestionnaires,
        refetch: getQuestionnairesRefetch,
    } = useGetQuestionnairesQuery();

    const publishedQuestions = getQuestionnaires?.filter(question => question?.status == 'published') || [];

    // const {
    //     data: getUsers,
    //     refetch: getUsersRefetch,
    // } = useGetUsersQuery();

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const addPartnersModalHandler = (status) => {
        setOpenPartnerModal((prev) => !prev);
        setOpenPartnerModalCondition(status);
    };

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    useEffect(() => {

        const transformedUserArray = location?.state?.data?.users?.map(item => ({
            user: item?.user,
            status: 'not_started'
        }));

        const initialSelectedQuestionnaire = location?.state?.data?.questionnaires || [];
        setSelectedQuestionnaire(initialSelectedQuestionnaire);
        setSelectedUser(transformedUserArray || []);
    }, [location?.state?.data?.questionnaires, location?.state?.data?.users]);

    const getQuestionnaireFinalData = () => {
        return selectedQuestionnaire;
    };

    const questIdsArray = showQuest?.map(item => item?.id);

    const [addAssesments, { isLoading: addAssesmentsLoading }] = useAddAssesmentsMutation();
    const [editAssesments, { isLoading: editAssesmentsLoading }] = useEditAssesmentsMutation();

    const onSubmit = () => {

        const questionnaireFinalData = getQuestionnaireFinalData();
        const mergedQuestArray = [...questIdsArray, ...questionnaireFinalData];
        const uniqueQuestArray = [...new Set(mergedQuestArray)];

        let newQuestionnaireForUsers = uniqueQuestArray?.map(item => ({ questionnare: item, status: 'pending' }));

        const transformedUserArray = selectedUser?.map(item => ({
            user: item?.value,
            status: 'not_started',
            questionnaires: newQuestionnaireForUsers,
        }));

        const transformedSelectedUserArray = selectedUser?.map(item => ({
            user: item?.value || item?.user,
            status: 'not_started',
            questionnaires: newQuestionnaireForUsers,
        }));

        const data = {
            title: title,
            description: description,
            dueDate: dueDate,
            numberOfRecords: Number(records),
            partner: selectedPartner?.value || partnerName?.value || selectedPartner?.id,
            project: selectedProject?.value || projectName?.value,
            questionnaires: questIdsArray,
            users: transformedUserArray,
            country: selectedCountry?.value || countryName?.value || selectedCountry?.id,
        };

        const dataWithId = {
            title: title,
            description: description,
            dueDate: dueDate,
            numberOfRecords: Number(records),
            partner: selectedPartner?.value || location?.state?.data?.partner?.id,
            project: selectedProject?.value || location?.state?.data?.project?.id,
            questionnaires: uniqueQuestArray,
            users: transformedSelectedUserArray,
            id: location?.state?.data?._id,
            country: selectedCountry?.value || location?.state?.data?.country?.id,
        };

        if (location?.state?.data) {
            editAssesmentsApiHandler(dataWithId);
        } else {
            addAssesmentApiHandler(data);
        }
    };

    const addAssesmentApiHandler = (data) => {
        addAssesments({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Assesment added successfully");
                navigate(PATHS.assesments);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editAssesmentsApiHandler = (data) => {
        editAssesments({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Assesment updated successfully");
                navigate(PATHS.assesments);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const validationHandler = () => {
        let noError = false;

        titleValidationHandler();
        descriptionValidationHandler();
        dueDateValidationHandler();
        partnerValidationHandler();
        projectValidationHandler();
        questionnaireValidationHandler();
        userValidationHandler();

        if (title && description && dueDate && (selectedPartner || location?.state?.data?.partner?.id) && (selectedProject || location?.state?.data?.project?.id) && (showQuest?.length || location?.state?.data?.questionnaires) && (selectedUser?.length || location?.state?.data?.users)) {
            onSubmit();
        } else {
            return false;
        }

        return noError;
    };

    const titleValidationHandler = () => {
        if (!title) {
            setError("title", { type: "custom", message: "Title is required" });
        } else {
            clearErrors("title");
        }
    };

    const descriptionValidationHandler = () => {
        if (!description) {
            setError("description", { type: "custom", message: "Description is required" });
        } else {
            clearErrors("description");
        }
    };

    const dueDateValidationHandler = () => {
        if (!dueDate) {
            setError("dueDate", { type: "custom", message: "Date is required" });
        } else {
            clearErrors("dueDate");
        }
    };

    const partnerValidationHandler = () => {
        if (!selectedPartner && !partnerName) {
            setError("partner", { type: "custom", message: "Partner is required" });
        } else {
            clearErrors("partner");
        }
    };

    const projectValidationHandler = () => {
        if (!selectedProject && !projectName) {
            setError("project", { type: "custom", message: "Project is required" });
        } else {
            clearErrors("project");
        }
    };

    const questionnaireValidationHandler = () => {
        if (!showQuest?.length && !location?.state?.data?.questionnaires) {
            setError("questionnaire", { type: "custom", message: "Questionnaire is required" });
        } else {
            clearErrors("questionnaire");
        }
    };

    const userValidationHandler = () => {
        if (!selectedUser?.length && !location?.state?.data?.users) {
            setError("user", { type: "custom", message: "User is required" });
        } else {
            clearErrors("user");
        }
    };

    const handleChange = (selectedOption, { name }) => {
        switch (name) {
            case 'partner':
                setSelectedPartner(selectedOption);
                break;
            case 'project':
                setSelectedProject(selectedOption);
                break;
            default:
                break;
        }
    };

    const findProjectById = (id) => {
        const foundProject = getProjects?.find((project) => project?.id == id);
        setSelectedPartner(foundProject?.partner);
        setSelectedCountry(foundProject?.country);
    };

    useEffect(() => {
        findProjectById(selectedProject?.value);
    }, [selectedProject]);

    const handleUsersChange = (selectedOptions) => {
        let users = selectedOptions?.map(option => option);
        setSelectedUser(users);
    };

    const handleQuestionnaireChange = (selectedOptions) => {
        let questionnaire = selectedOptions?.map(option => option?.value);
        setSelectedQuestionnaire(questionnaire);
    };

    const preprocessData = (data) => {
        return data?.map(data => ({
            label: data?.name || data?.firstName || data?.title,
            value: data?.id || data?._id,
        }));
    };

    const useFilteredData = (data, selectedValue, key) => {

        const [filteredData, setFilteredData] = useState([]);

        useEffect(() => {
            if (selectedValue) {
                const filtered = data?.filter(
                    (item) => item[key] == selectedValue?.value
                );
                setFilteredData(filtered);
            } else {
                setFilteredData([]);
            }
        }, [selectedValue, data, key]);

        return filteredData;
    };

    // const filteredProjects = useFilteredData(getProjects, selectedPartner, 'partner');
    const allProjects = preprocessData(getProjects || []);
    // const allProjects = preprocessData(filteredProjects || []);
    const allPartners = preprocessData(getPartners || []);
    // const allQuestionnaires = preprocessData(location?.state?.quest || []);
    const allUsers = preprocessData(location?.state?.users || []);
    const allCountries = preprocessData(getCountries || []);

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            default:
                break;
        }
    };

    const getQuestionnaireNamesByIds = (ids, data) => {
        return ids?.map(id => {
            const values = data?.find(values => values?.id == id);
            return values ? values : null;
        });
    };

    const getUserNamesByIds = (ids, data) => {
        return ids?.map(id => {
            const users = data?.find(users => users?.value == id?.user);
            return users ? users : null;
        });
    };

    const getQuestionnaireNames = getQuestionnaireNamesByIds(location?.state?.data?.questionnaires, location?.state?.quest);
    const getUserNames = getUserNamesByIds(location?.state?.data?.users, allUsers);

    const handleSuccess = (status) => {
        setSuccess(status);
    };

    useEffect(() => {
        if (location?.state?.data) {
            updateName('partner', location?.state?.data?.partner);
            updateName('project', location?.state?.data?.project);
            updateName('country', location?.state?.data?.country);
        }
    }, [getPartners, getProjects, getCountries]);

    useEffect(() => {
        if (location?.state?.data) {
            let dueDate = moment(location?.state?.data?.dueDate).format("YYYY-MM-DD");
            setTitle(location?.state?.data?.title);
            setDescription(location?.state?.data?.description);
            setDueDate(dueDate);
            setRecords(location?.state?.data?.numberOfRecords);
        }
    }, [location?.state?.data]);

    const updateName = (type, value) => {
        const allEntities = {
            partner: allPartners,
            project: allProjects,
        };

        const setNameFunctions = {
            partner: setPartnerName,
            project: setProjectName,
            country: setCountryName,
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.value == value);
            if (entity) {
                setName(entity);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        getPartnersRefetch();
        getProjectsRefetch();
        getQuestionnairesRefetch();
        // getUsersRefetch();
        getCountriesRefetch();
    }, []);

    useEffect(() => {
        if (success) {
            getPartnersRefetch();
        }
    }, [success]);

    const localSearchFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowQuestDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleQuestClick = (data) => {
        if (!showQuest?.some(item => item?.title == data?.title && item?.description == data?.description)) {
            setShowQuest((prevItems) => [...prevItems, data]);
        }
        setShowQuestDropdown(false);
    };

    const handleRemoveQuest = (index) => {
        setShowQuest((prevItems) => prevItems.filter((_, i) => i != index));
    };

    return (
        <div className="row mt-5" >
            <div className="col-12">
                {props?.view ?
                    <h1 className="text-center">View Assesment</h1>
                    :
                    <h1 className="text-center">{location?.state?.data ? "Edit" : "Add"} Assesment</h1>
                }
                <div className="row mt-4">

                    <div className="col-12 mb-3">
                        <label className="fs-4 mb-3">Title</label>
                        <input type="text" className="themeInput fs-4" placeholder='Title' name="title" onChange={(e) => setTitle(e.target.value)} value={title} disabled={props?.view ? true : false} />

                        {errors.title && <p className='errorMsg'>{errors.title.message}</p>}
                    </div>

                    <div className="col-12 mb-3">
                        <label className="fs-4 mb-3">Description</label>
                        <textarea type="text" className="themeInput themeTextarea fs-4" placeholder='Description' name="name" onChange={(e) => setDescription(e.target.value)} value={description} disabled={props?.view ? true : false} />

                        {errors.description && <p className='errorMsg'>{errors.description.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">Due Date</label>
                        <input type="date" className="themeInput fs-4" name="dueDate" onChange={(e) => setDueDate(e.target.value)} value={dueDate} disabled={props?.view ? true : false} />

                        {errors.dueDate && <p className='errorMsg'>{errors.dueDate.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4 mb-3'>Project</label>
                        <Select
                            options={allProjects}
                            onChange={handleChange}
                            className='w-100 me-3'
                            name="project"
                            placeholder={location?.state?.data?.project?.name}
                            isDisabled={props?.view ? true : false}
                        />

                        {errors.project && <p className='errorMsg'>{errors.project.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4 mb-3'>Country</label>
                        <Select
                            options={allCountries}
                            onChange={handleLocationChange}
                            className='w-100 me-3'
                            name="country"
                            placeholder={selectedCountry?.name || location?.state?.data?.country?.name}
                        // isDisabled={props?.view ? true : false}
                        />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4 mb-3'>Partner</label>

                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allPartners}
                                onChange={handleChange}
                                className='w-100 me-3'
                                name="partner"
                                placeholder={selectedPartner?.name || location?.state?.data?.partner?.name}
                                isDisabled={props?.view ? true : false}
                            />

                            <img src={add} alt="" className='actionIcons' onClick={() => addPartnersModalHandler(true)} />
                        </div>

                        {errors.partner && <p className='errorMsg'>{errors.partner.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3 position-relative" ref={dropdownRef}>
                        <label className="fs-4 mb-3">Questionnaires</label>
                        <input type="text" id="localSearchInput" className="themeInput fs-4" placeholder='Search...' onChange={(e) => localSearchFunction(e.target.value)} onClick={() => setShowQuestDropdown(true)} />

                        {errors.questionnaire && <p className='errorMsg'>{errors.questionnaire.message}</p>}

                        {showQuestDropdown &&
                            <div className="dropdownDiv">
                                {publishedQuestions?.map((data, index) => {
                                    return (
                                        <p
                                            className="mb-2 fs-4 mainDiv cursor"
                                            key={index}
                                            onClick={() => handleQuestClick(data)}
                                        >
                                            {data?.title}
                                        </p>
                                    )
                                })}

                                <p className='text-center mt-2 fs-5 mb-0' id="noRecordsFound" style={{ display: "none" }}>No Record Found</p>
                            </div>
                        }

                        {getQuestionnaireNames?.length ?
                            getQuestionnaireNames?.map((item, index) => (
                                <div className="appendedItems mt-3 position-relative">
                                    <div key={index} className="appendedItem">
                                        <p className="fs-5 mb-1"><b>TITLE: </b>{item?.title}</p>
                                        <p className="fs-5 mb-0"><b>DESCRIPTION: </b>{item?.description}</p>

                                        {location?.state?.data ? null :
                                            <span className="crossIcon" onClick={() => handleRemoveQuest(index)}>X</span>
                                        }
                                    </div>
                                </div>
                            ))
                            : null}

                        {showQuest?.length ?
                            showQuest?.map((item, index) => (
                                <div className="appendedItems mt-3 position-relative">
                                    <div key={index} className="appendedItem">
                                        <p className="fs-5 mb-1"><b>TITLE: </b>{item?.title}</p>
                                        <p className="fs-5 mb-0"><b>DESCRIPTION: </b>{item?.description}</p>

                                        <span className="crossIcon" onClick={() => handleRemoveQuest(index)}>X</span>
                                    </div>
                                </div>
                            ))
                            : null}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4 mb-3'>Collector</label>
                        <Select
                            options={allUsers}
                            onChange={handleUsersChange}
                            className='w-100 me-3'
                            name="user"
                            defaultValue={getUserNames}
                            isMulti={true}
                            isDisabled={props?.view ? true : false}
                        />

                        {errors.user && <p className='errorMsg'>{errors.user.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">No. of Records</label>
                        <input type="number" className="themeInput fs-4" placeholder='No. of Records' onChange={(e) => setRecords(e.target.value)} value={records} />
                    </div>

                </div>

                {!props?.view ?
                    <div className="text-center">
                        <Button
                            label="Submit"
                            className="mt-5 themeBtn"
                            loading={addAssesmentsLoading || editAssesmentsLoading}
                            onClick={validationHandler}
                        />
                    </div>
                    : null}

                {openPartnerModal &&
                    <AddPartnerModal
                        handleCloseAddPartnerModal={addPartnersModalHandler}
                        isOpenCondition={openPartnerModalCondition}
                        success={handleSuccess}
                    />
                }
            </div>
        </div >
    );
};

export default AddAssesment;