import React, { useState, memo, useEffect, useRef } from 'react';
import { Box, FormControl, Button, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, IconButton } from '@mui/material';
import Section from './Section';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useEditQuestionnairesMutation, useGetQuestionnairesByIdQuery, useUploadGuideFileMutation } from '../../../services/Api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from "../../../routes/Paths";
import { file, loader, upload } from '../../../assets/imagesPath';
import { BASE_URL_FILES } from '../../../services/ApiEndpoints';

const EditQuestionnaire = () => {

    const location = useLocation();

    const {
        data: getQuestionnairesById,
        refetch: getQuestionnairesByIdRefetch,
        isLoading: getQuestionnairesByIdLoading,
    } = useGetQuestionnairesByIdQuery({ params: { id: location?.state?.data?.id } });

    const initialQuestionnaire = {
        title: getQuestionnairesById?.title,
        description: getQuestionnairesById?.description,
        sections: getQuestionnairesById?.sections
    };

    const [questionnaire, setQuestionnaire] = useState(initialQuestionnaire);
    const [status, setStatus] = useState('draft');
    const [guide, setGuide] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (getQuestionnairesById) {
            setQuestionnaire({
                title: getQuestionnairesById.title || '',
                description: getQuestionnairesById.description || '',
                sections: getQuestionnairesById.sections || []
            });
        }
    }, [getQuestionnairesById]);

    const [editQuestionnaires, { isLoading: editQuestionnairesLoading }] = useEditQuestionnairesMutation();
    const [uploadGuideFile, { isLoading: uploadGuideFileLoading }] = useUploadGuideFileMutation();

    const handleAddSection = () => {
        const newSection = {
            _id: Date.now().toString(),
            title: '',
            questions: [],
            subsections: []
        };
        setQuestionnaire((prev) => ({
            ...prev,
            sections: [...prev.sections, newSection]
        }));
    };

    const addGuideFileApiHandler = (file) => {

        let formData = new FormData();

        formData.append('guideFile', file);

        uploadGuideFile({ data: formData })
            .unwrap()
            .then((payload) => {
                setGuide(payload?.fileName);
                toast.success("Guide added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addQuestionnaireApiHandler = (data) => {
        data._id = getQuestionnairesById?._id;
        data.status = status;
        data.guideFile = guide || getQuestionnairesById?.guideFile;

        editQuestionnaires({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Questionnaire updated successfully");
                navigate(PATHS.questionnaires)
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const handleSave = () => {
        addQuestionnaireApiHandler(questionnaire);
    };

    const handleDeleteSection = (sectionId) => {
        setQuestionnaire((prev) => ({
            ...prev,
            sections: prev?.sections?.filter((sec) => sec?._id !== sectionId)
        }));
    };

    const fileInputRef = useRef(null);

    const handleFileUpload = (file) => {
        addGuideFileApiHandler(file);
    };

    useEffect(() => {
        getQuestionnairesByIdRefetch();
    }, []);

    return (
        <>
            {getQuestionnairesByIdLoading ?
                <div className='text-center'>
                    <img src={loader} alt="" height={100} />
                </div>
                :
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 2 }}>
                        <FormControl sx={{ width: 160 }}>
                            <InputLabel>Status</InputLabel>
                            <Select value={status} onChange={(e) => setStatus(e?.target?.value)} label="Status">
                                <MenuItem value={'draft'}>Draft</MenuItem>
                                <MenuItem value={'published'}>Published</MenuItem>
                                <MenuItem value={'in-active'}>In-active</MenuItem>
                            </Select>
                        </FormControl>

                        <IconButton
                            title="Preview"
                            onClick={() => navigate(PATHS.viewSolvedAssesment, { state: { data: getQuestionnairesById, recordId: '', userId: '', assessmentId: '', viewOnly: true } })}
                        >
                            <PreviewIcon sx={{ fontSize: 25 }} />
                        </IconButton>

                        {editQuestionnairesLoading ?
                            <img src={loader} alt="" width={50} />
                            :
                            <IconButton title="Save" onClick={handleSave}>
                                <SaveIcon sx={{ fontSize: 25, color: '#0098d9' }} />
                            </IconButton>
                        }
                    </Box>

                    <Paper sx={{ p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item lg={12}>
                                <Typography variant="subtitle1">Questionnaire title</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Title"
                                    value={questionnaire?.title}
                                    onChange={(e) => setQuestionnaire({ ...questionnaire, title: e.target.value })}
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <Typography variant="subtitle1">Questionnaire description</Typography>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    placeholder="Add Description"
                                    value={questionnaire?.description}
                                    onChange={(e) => setQuestionnaire({ ...questionnaire, description: e.target.value })}
                                />
                            </Grid>

                            <Grid item lg={12} >
                                <Typography variant="subtitle1">Upload Guide</Typography>

                                <div className='position-relative mb-3'>
                                    <img src={uploadGuideFileLoading ? loader : upload} alt="" width={100} />
                                    <input type="file" className='guideUploadBtn' accept='application/pdf' onChange={(e) => handleFileUpload(e.target.files[0])} ref={fileInputRef} />
                                </div>

                                {guide ?
                                    <div className='d-flex align-items-center mt-3'>
                                        <a href={BASE_URL_FILES + guide} target="_blank">
                                            <img src={file} alt="" width={50} />
                                        </a>
                                        <span className='fs-4 me-5'>{guide}</span>
                                    </div>
                                    : getQuestionnairesById?.guideFile ?
                                        <div className='d-flex align-items-center mt-3'>
                                            <a href={BASE_URL_FILES + getQuestionnairesById?.guideFile} target="_blank">
                                                <img src={file} alt="" width={50} />
                                            </a>
                                            <span className='fs-4 me-5'>{getQuestionnairesById?.guideFile}</span>
                                        </div>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Paper>

                    {questionnaire?.sections?.map((section, index) => (
                        <>
                            {/* <Button onClick={() => handleAddSectionBetween(index)}>Add New Section</Button> */}
                            <Section
                                key={section?._id}
                                section={section}
                                updateSection={(updatedSection) => {
                                    setQuestionnaire((prev) => ({
                                        ...prev,
                                        sections: prev.sections.map((sec) => (sec._id === updatedSection._id ? updatedSection : sec))
                                    }));
                                }}
                                deleteSection={handleDeleteSection}
                            />
                        </>
                    ))}

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button sx={{ px: 10, py: 2 }} variant="contained" onClick={handleAddSection}>
                            Add Section
                        </Button>
                    </Box>
                </Box>
            }
        </>
    );
};

export default memo(EditQuestionnaire);