import React from 'react';

const SelectQuestion = ({ question, handleChange, disabled }) => {

    const handleSelectChange = (event) => {
        handleChange(question.id, event.target.value, null, 'select');
    };

    return (
        <>
            <h3 className='fs-2 mt-4'>{question?.text}</h3>
            <select className='themeInput w-25' name={question?.id} onChange={handleSelectChange} disabled={false}>
                {question?.options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </>
    );
};

export default SelectQuestion;