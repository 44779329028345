import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useGetSentEmailsQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { active, deactive, del, dots, edit, loader, view } from '../../assets/imagesPath';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';

const SentEmails = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const {
        data: getSentEmails,
        refetch: getSentEmailsRefetch,
        isLoading: getSentEmailsLoading,
    } = useGetSentEmailsQuery();

    const itemsPerPage = 20;
    const totalItems = getSentEmails?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getSentEmails?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    useEffect(() => {
        getSentEmailsRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Sent Email Templates</h1>

                <div className='row'>
                    <div className='col-md-3'>
                        <input
                            id='localSearchInput'
                            className='themeInput w-100'
                            placeholder="Search"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => localSearchTableFunction(e.target.value)}
                        />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Subject</th>
                            <th>Title</th>
                            <th>From</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length ? paginatedData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index}
                                    onClick={() => navigate(PATHS.viewSentEmails, { state: { data: data } })}
                                >
                                    <td>{index + 1}</td>
                                    <td>{data?.subject}</td>
                                    <td>{data?.from?.emailTitle}</td>
                                    <td>{data?.from?.emailUser}</td>
                                    {/* <td onClick={(e) => e.stopPropagation()}>
                                        <div className='d-flex align-items-center gap-3'>
                                            <img src={view} alt="" className='actionIcons' onClick={() => viewContactsModalHandler(data)} title='View' />
                                            <img src={edit} alt="" className='actionIcons' onClick={() => navigate(PATHS.addContactEmailTemplates, { state: { data: data } })} title='Edit' />
                                            <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                        </div>
                                    </td> */}
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={8}>
                                {getSentEmailsLoading ?
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                    : <h2 className='text-center mt-5'>No Record Found</h2>}
                            </td>
                        </tr>}

                        <tr>
                            <td colSpan={8}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
            </div>
        </>
    )
};

export default SentEmails;