import React from 'react';

const RadioQuestion = ({ question, handleChange, disabled }) => {

    const handleRadioChange = (event) => {
        handleChange(question.id, event.target.value, null, 'radio');
    };

    return (
        <>
            <h3 className='fs-2 mt-4'>{question?.text}</h3>
            <div className='d-flex align-items-center gap-5'>
                {question?.options.map(option => (
                    <div className='d-flex align-items-center' key={option}>
                        <input
                            type="radio"
                            id={option}
                            name={question?.id}
                            value={option}
                            onChange={handleRadioChange}
                            disabled={disabled}
                        />
                        <label className='fs-4 ms-2' htmlFor={option}>{option}</label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default RadioQuestion;