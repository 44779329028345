import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAssessmentRecordQuery } from '../../../services/Api';
import { loader } from '../../../assets/imagesPath';
import Button from '../../../components/button/Button';
import { BASE_URL_FILES } from '../../../services/ApiEndpoints';
import moment from 'moment/moment';
import PATHS from '../../../routes/Paths';

const ViewAssesmentDetailByAdmin = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const assesmentId = location?.state?.assessmentId;
    const recordId = location?.state?.recordId;
    const collectorId = location?.state?.collectorId;

    const {
        data: assessmentRecord,
        isLoading: assessmentRecordLoading,
        refetch: assessmentRecordRefetch,
    } = useAssessmentRecordQuery({ params: { assessmentId: assesmentId, collectorId: collectorId, recordId: recordId } });

    let dueDate = moment(assessmentRecord?.dueDate).format("YYYY-MM-DD");

    useEffect(() => {
        assessmentRecordRefetch();
    }, []);

    return (
        <>
            {assessmentRecordLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row'>

                    <div className="col-12 mb-5">
                        <div className="assesDiv">
                            <div className='d-flex align-items-start justify-content-between'>
                                <div>
                                    <h3>Title</h3>
                                    <p>{assessmentRecord?.title}</p>
                                </div>

                                <Button
                                    label={assessmentRecord?.status?.replace(/_/g, " ")}
                                    className={`${assessmentRecord?.status == "pending" ? "bg-warning" : assessmentRecord?.status == "in_progress" ? "bg-info" : assessmentRecord?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                />
                            </div>

                            <div className='mt-4'>
                                <h3>Description</h3>
                                <p>{assessmentRecord?.description}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Record Id</h3>
                                <p>{recordId}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Project</h3>
                                <p>{assessmentRecord?.project}</p>
                            </div>

                            <div className="row mt-4">
                                <div className='col-md-4'>
                                    <h3>Country</h3>
                                    <p>{assessmentRecord?.country}</p>
                                </div>
                                <div className="col-md-4">
                                    <h3>Partner</h3>
                                    <p>{assessmentRecord?.partner}</p>
                                </div>
                                <div className="col-md-4">
                                    <h3>Submitted At</h3>
                                    <p>{dueDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link text-dark active" data-bs-toggle="tab" href="#Questionnaires"><h2 className="fs-1">Questionnaires</h2></a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane active" id="Questionnaires">
                                {assessmentRecord?.questionnaires?.map((data, index) => {
                                    return (
                                        <div className="col-12 mb-4 mt-5" key={index}>
                                            <div className="assesDiv">
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div>
                                                        <h3>Title</h3>
                                                        <p>{data?.title}</p>
                                                    </div>

                                                    <div className='d-flex align-items-start gap-3'>
                                                        {data?.guideFile ?
                                                            <a href={BASE_URL_FILES + data?.guideFile} target="_blank">
                                                                <Button
                                                                    label="View User Guide"
                                                                    className="themeBtn"
                                                                />
                                                            </a>
                                                            : null}

                                                        <Button
                                                            label="View"
                                                            className="themeBtn bg-warning border-0"
                                                            onClick={() => navigate(PATHS.viewSolvedAssesment, { state: { data: data, recordId: recordId, userId: collectorId, assessmentId: assesmentId } })}
                                                        />

                                                        <Button
                                                            label={data?.status}
                                                            className={`${data?.status == "pending" ? "bg-warning" : data?.status == "in_progress" ? "bg-info" : data?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <h3>Description</h3>
                                                    <p>{data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>

                </div>
            }
        </>
    )
}

export default ViewAssesmentDetailByAdmin;