import React, { useEffect, useState } from 'react';
import { useAddCitiesMutation, useAddCountryMutation, useAddDistrictsMutation, useAddStatesMutation, useAddTehsilsMutation, useAddUnionCouncilsMutation, useAddVillagesMutation, useDeleteCitiesMutation, useDeleteCountryMutation, useDeleteDistrictsMutation, useDeleteStatesMutation, useDeleteTehsilsMutation, useDeleteUnionCouncilsMutation, useDeleteVillagesMutation, useEditCitiesMutation, useEditCountryMutation, useEditDistrictsMutation, useEditStatesMutation, useEditTehsilsMutation, useEditUnionCouncilsMutation, useEditVillagesMutation, useGetCitiesQuery, useGetCountriesQuery, useGetDistrictsQuery, useGetStatesQuery, useGetTehsilsQuery, useGetUnionCouncilsQuery, useGetVillagesQuery, useUploadCitiesMutation, useUploadCountriesMutation, useUploadDistrictsMutation, useUploadStatesMutation, useUploadTehsilsMutation, useUploadUnionCouncilsMutation, useUploadVillagesMutation } from '../../services/Api';
import { add, del, edit, importCsv } from '../../assets/imagesPath';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import Select from 'react-select';
import AddCountryModal from '../../components/allModals/AddCountryModal';
import AddStateModal from '../../components/allModals/AddStateModal';
import AddCityModal from '../../components/allModals/AddCityModal';
import AddDistrictModal from '../../components/allModals/AddDistrictModal';
import AddTehsilModal from '../../components/allModals/AddTehsilModal';
import AddUnionCouncilModal from '../../components/allModals/AddUnionCouncilModal';
import AddVillageModal from '../../components/allModals/AddVillageModal';
import AddCSVFileModal from '../../components/allModals/AddCSVFileModal';

const Location = () => {

    const [addCsvDataModal, setAddCsvDataModal] = useState(false);
    const [csvDataType, setCsvDataType] = useState("");
    const [addCountryModal, setAddCountryModal] = useState(false);
    const [addStatesModal, setAddStatesModal] = useState(false);
    const [addCitiesModal, setAddCitiesModal] = useState(false);
    const [addDistrictsModal, setAddDistrictsModal] = useState(false);
    const [addTehsilsModal, setAddTehsilsModal] = useState(false);
    const [addUnionCouncilsModal, setAddUnionCouncilsModal] = useState(false);
    const [addVillagesModal, setAddVillagesModal] = useState(false);

    const [editCountryModal, setEditCountryModal] = useState(false);
    const [editStatesModal, setEditStatesModal] = useState(false);
    const [editCitiesModal, setEditCitiesModal] = useState(false);
    const [editDistrictsModal, setEditDistrictsModal] = useState(false);
    const [editTehsilsModal, setEditTehsilsModal] = useState(false);
    const [editUnionCouncilsModal, setEditUnionCouncilsModal] = useState(false);
    const [editVillagesModal, setEditVillagesModal] = useState(false);

    const [deleteCountryModal, setDeleteCountryModal] = useState(false);
    const [deleteStatesModal, setDeleteStatesModal] = useState(false);
    const [deleteCitiesModal, setDeleteCitiesModal] = useState(false);
    const [deleteDistrictsModal, setDeleteDistrictsModal] = useState(false);
    const [deleteTehsilsModal, setDeleteTehsilsModal] = useState(false);
    const [deleteUnionCouncilsModal, setDeleteUnionCouncilsModal] = useState(false);
    const [deleteVillagesModal, setDeleteVillagesModal] = useState(false);

    const [viewCountryData, setViewCountryData] = useState("");
    const [viewStatesData, setViewStatesData] = useState("");
    const [viewCitiesData, setViewCitiesData] = useState("");
    const [viewDistrictsData, setViewDistrictsData] = useState("");
    const [viewTehsilsData, setViewTehsilsData] = useState("");
    const [viewUnionCouncilsData, setViewUnionCouncilsData] = useState("");
    const [viewVillagesData, setViewVillagesData] = useState("");

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedTehsil, setSelectedTehsil] = useState(null);
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState(null);
    const [selectedVillage, setSelectedVillage] = useState(null);

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const {
        data: getStates,
        refetch: getStatesRefetch,
    } = useGetStatesQuery();

    const {
        data: getCities,
        refetch: getCitiesRefetch,
    } = useGetCitiesQuery();

    const {
        data: getDistricts,
        refetch: getDistrictsRefetch,
    } = useGetDistrictsQuery();

    const {
        data: getTehsils,
        refetch: getTehsilsRefetch,
    } = useGetTehsilsQuery();

    const {
        data: getUnionCouncils,
        refetch: getUnionCouncilsRefetch,
    } = useGetUnionCouncilsQuery();

    const {
        data: getVillages,
        refetch: getVillagesRefetch,
    } = useGetVillagesQuery();

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?.value,
        }));
    };

    const useFilteredData = (data, selectedValue, key) => {

        const [filteredData, setFilteredData] = useState([]);

        useEffect(() => {
            if (selectedValue) {
                const filtered = data?.filter(
                    (item) => item[key] == selectedValue?.value
                );
                setFilteredData(filtered);
            } else {
                setFilteredData([]);
            }
        }, [selectedValue, data, key]);

        return filteredData;
    };

    const filteredStates = useFilteredData(getStates, selectedCountry, 'countryId');
    const filteredCities = useFilteredData(getCities, selectedState, 'stateId');
    const filteredDistricts = useFilteredData(getDistricts, selectedCity, 'cityId');
    const filteredTehsils = useFilteredData(getTehsils, selectedDistrict, 'districtId');
    const filteredUnionCouncils = useFilteredData(getUnionCouncils, selectedTehsil, 'tehsilId');
    const filteredVillages = useFilteredData(getVillages, selectedUnionCouncil, 'unionCouncilId');

    const allCountries = preprocessLocationData(getCountries || []);
    const allStates = preprocessLocationData(filteredStates || []);
    const allCities = preprocessLocationData(filteredCities || []);
    const allDistricts = preprocessLocationData(filteredDistricts || []);
    const allTehsils = preprocessLocationData(filteredTehsils || []);
    const allUnionCouncils = preprocessLocationData(filteredUnionCouncils || []);
    const allVillages = preprocessLocationData(filteredVillages || []);

    const addCsvDataModalHandler = (type) => {
        setAddCsvDataModal((prev) => !prev);
        setCsvDataType(type);
    };

    const addCountryModalHandler = () => {
        setAddCountryModal((prev) => !prev);
    };

    const addStatesModalHandler = () => {
        if (selectedCountry) {
            setAddStatesModal((prev) => !prev);
        } else {
            toast.warning("Please select country first");
        }
    };

    const addCitiesModalHandler = () => {
        if (selectedState) {
            setAddCitiesModal((prev) => !prev);
        } else {
            toast.warning("Please select state first");
        }
    };

    const addDistrictsModalHandler = () => {
        if (selectedCity) {
            setAddDistrictsModal((prev) => !prev);
        } else {
            toast.warning("Please select city first");
        }
    };

    const addTehsilsModalHandler = () => {
        if (selectedDistrict) {
            setAddTehsilsModal((prev) => !prev);
        } else {
            toast.warning("Please select district first");
        }
    };

    const addUnionCouncilsModalHandler = () => {
        if (selectedTehsil) {
            setAddUnionCouncilsModal((prev) => !prev);
        } else {
            toast.warning("Please select tehsil first");
        }
    };

    const addVillagesModalHandler = () => {
        if (selectedUnionCouncil) {
            setAddVillagesModal((prev) => !prev);
        } else {
            toast.warning("Please select union council first");
        }
    };

    const editCountryModalHandler = (data) => {
        setEditCountryModal((prev) => !prev);
        setViewCountryData(data);
    };

    const editStatesModalHandler = (data) => {
        if (selectedCountry) {
            setEditStatesModal((prev) => !prev);
            setViewStatesData(data);
        } else {
            toast.warning("Please select country first");
        }
    };

    const editCitiesModalHandler = (data) => {
        if (selectedState) {
            setEditCitiesModal((prev) => !prev);
            setViewCitiesData(data);
        } else {
            toast.warning("Please select state first");
        }
    };

    const editDistrictsModalHandler = (data) => {
        if (selectedCity) {
            setEditDistrictsModal((prev) => !prev);
            setViewDistrictsData(data);
        } else {
            toast.warning("Please select city first");
        }
    };

    const editTehsilsModalHandler = (data) => {
        if (selectedDistrict) {
            setEditTehsilsModal((prev) => !prev);
            setViewTehsilsData(data);
        } else {
            toast.warning("Please select district first");
        }
    };

    const editUnionCouncilsModalHandler = (data) => {
        if (selectedTehsil) {
            setEditUnionCouncilsModal((prev) => !prev);
            setViewUnionCouncilsData(data);
        } else {
            toast.warning("Please select tehsil first");
        }
    };

    const editVillagesModalHandler = (data) => {
        if (selectedUnionCouncil) {
            setEditVillagesModal((prev) => !prev);
            setViewVillagesData(data);
        } else {
            toast.warning("Please select Union Council first");
        }
    };

    const deleteCountryModalHandler = (data) => {
        setDeleteCountryModal((prev) => !prev);
        setViewCountryData(data);
    };

    const deleteStatesModalHandler = (data) => {
        setDeleteStatesModal((prev) => !prev);
        setViewStatesData(data);
    };

    const deleteCitiesModalHandler = (data) => {
        setDeleteCitiesModal((prev) => !prev);
        setViewCitiesData(data);
    };

    const deleteDistrictsModalHandler = (data) => {
        setDeleteDistrictsModal((prev) => !prev);
        setViewDistrictsData(data);
    };

    const deleteTehsilsModalHandler = (data) => {
        setDeleteTehsilsModal((prev) => !prev);
        setViewTehsilsData(data);
    };

    const deleteUnionCouncilsModalHandler = (data) => {
        setDeleteUnionCouncilsModal((prev) => !prev);
        setViewUnionCouncilsData(data);
    };

    const deleteVillagesModalHandler = (data) => {
        setDeleteVillagesModal((prev) => !prev);
        setViewVillagesData(data);
    };

    const [addCountry, { isSuccess: addCountrySuccess, isLoading: addCountryLoading }] = useAddCountryMutation();
    const [editCountry, { isSuccess: editCountrySuccess, isLoading: editCountryLoading }] = useEditCountryMutation();
    const [deleteCountry, { isSuccess: deleteCountrySuccess, isLoading: deleteCountryLoading }] = useDeleteCountryMutation();

    const [addStates, { isSuccess: addStatesSuccess, isLoading: addStatesLoading }] = useAddStatesMutation();
    const [editStates, { isSuccess: editStatesSuccess, isLoading: editStatesLoading }] = useEditStatesMutation();
    const [deleteStates, { isSuccess: deleteStatesSuccess, isLoading: deleteStatesLoading }] = useDeleteStatesMutation();

    const [addCities, { isSuccess: addCitiesSuccess, isLoading: addCitiesLoading }] = useAddCitiesMutation();
    const [editCities, { isSuccess: editCitiesSuccess, isLoading: editCitiesLoading }] = useEditCitiesMutation();
    const [deleteCities, { isSuccess: deleteCitiesSuccess, isLoading: deleteCitiesLoading }] = useDeleteCitiesMutation();

    const [addDistricts, { isSuccess: addDistrictsSuccess, isLoading: addDistrictsLoading }] = useAddDistrictsMutation();
    const [editDistricts, { isSuccess: editDistrictsSuccess, isLoading: editDistrictsLoading }] = useEditDistrictsMutation();
    const [deleteDistricts, { isSuccess: deleteDistrictsSuccess, isLoading: deleteDistrictsLoading }] = useDeleteDistrictsMutation();

    const [addTehsils, { isSuccess: addTehsilsSuccess, isLoading: addTehsilsLoading }] = useAddTehsilsMutation();
    const [editTehsils, { isSuccess: editTehsilsSuccess, isLoading: editTehsilsLoading }] = useEditTehsilsMutation();
    const [deleteTehsils, { isSuccess: deleteTehsilsSuccess, isLoading: deleteTehsilsLoading }] = useDeleteTehsilsMutation();

    const [addUnionCouncils, { isSuccess: addUnionCouncilsSuccess, isLoading: addUnionCouncilsLoading }] = useAddUnionCouncilsMutation();
    const [editUnionCouncils, { isSuccess: editUnionCouncilsSuccess, isLoading: editUnionCouncilsLoading }] = useEditUnionCouncilsMutation();
    const [deleteUnionCouncils, { isSuccess: deleteUnionCouncilsSuccess, isLoading: deleteUnionCouncilsLoading }] = useDeleteUnionCouncilsMutation();

    const [addVillages, { isSuccess: addVillagesSuccess, isLoading: addVillagesLoading }] = useAddVillagesMutation();
    const [editVillages, { isSuccess: editVillagesSuccess, isLoading: editVillagesLoading }] = useEditVillagesMutation();
    const [deleteVillages, { isSuccess: deleteVillagesSuccess, isLoading: deleteVillagesLoading }] = useDeleteVillagesMutation();

    const addCountryApiHandler = (data) => {
        addCountry({ data: data })
            .unwrap()
            .then(() => {
                addCountryModalHandler();
                getCountriesRefetch();
                toast.success("Country added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addStatesApiHandler = (data) => {
        addStates({ data: data })
            .unwrap()
            .then(() => {
                addStatesModalHandler();
                getStatesRefetch();
                toast.success("State added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addCitiesApiHandler = (data) => {
        addCities({ data: data })
            .unwrap()
            .then(() => {
                addCitiesModalHandler();
                getCitiesRefetch();
                toast.success("City added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addDistrictsApiHandler = (data) => {
        addDistricts({ data: data })
            .unwrap()
            .then(() => {
                addDistrictsModalHandler();
                getDistrictsRefetch();
                toast.success("District added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addTehsilsApiHandler = (data) => {
        addTehsils({ data: data })
            .unwrap()
            .then(() => {
                addTehsilsModalHandler();
                getTehsilsRefetch();
                toast.success("Tehsil added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addUnionCouncilsApiHandler = (data) => {
        addUnionCouncils({ data: data })
            .unwrap()
            .then(() => {
                addUnionCouncilsModalHandler();
                getUnionCouncilsRefetch();
                toast.success("Union Council added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addVillagesApiHandler = (data) => {
        addVillages({ data: data })
            .unwrap()
            .then(() => {
                addVillagesModalHandler();
                getVillagesRefetch();
                toast.success("Village added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editCountryApiHandler = (data) => {
        editCountry({ data: data })
            .unwrap()
            .then(() => {
                editCountryModalHandler();
                getCountriesRefetch();
                toast.success("Country updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editStatesApiHandler = (data) => {
        editStates({ data: data })
            .unwrap()
            .then(() => {
                editStatesModalHandler();
                getCountriesRefetch();
                toast.success("State updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editCitiesApiHandler = (data) => {
        editCities({ data: data })
            .unwrap()
            .then(() => {
                editCitiesModalHandler();
                getCountriesRefetch();
                toast.success("City updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editDistrictsApiHandler = (data) => {
        editDistricts({ data: data })
            .unwrap()
            .then(() => {
                editDistrictsModalHandler();
                getCountriesRefetch();
                toast.success("District updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editTehsilsApiHandler = (data) => {
        editTehsils({ data: data })
            .unwrap()
            .then(() => {
                editTehsilsModalHandler();
                getCountriesRefetch();
                toast.success("Tehsil updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editUnionCouncilsApiHandler = (data) => {
        editUnionCouncils({ data: data })
            .unwrap()
            .then(() => {
                editUnionCouncilsModalHandler();
                getCountriesRefetch();
                toast.success("Union Council updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editVillagesApiHandler = (data) => {
        editVillages({ data: data })
            .unwrap()
            .then(() => {
                editVillagesModalHandler();
                getCountriesRefetch();
                toast.success("Village updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteCountryApiHandler = (id) => {
        deleteCountry({ data: id })
            .unwrap()
            .then(() => {
                deleteCountryModalHandler();
                getCountriesRefetch();
                toast.success("Country deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteStatesApiHandler = (id) => {
        deleteStates({ data: id })
            .unwrap()
            .then(() => {
                deleteStatesModalHandler();
                getCountriesRefetch();
                toast.success("State deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteCitiesApiHandler = (id) => {
        deleteCities({ data: id })
            .unwrap()
            .then(() => {
                deleteCitiesModalHandler();
                getCountriesRefetch();
                toast.success("City deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteDistrictsApiHandler = (id) => {
        deleteDistricts({ data: id })
            .unwrap()
            .then(() => {
                deleteDistrictsModalHandler();
                getCountriesRefetch();
                toast.success("District deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteTehsilsApiHandler = (id) => {
        deleteTehsils({ data: id })
            .unwrap()
            .then(() => {
                deleteTehsilsModalHandler();
                getCountriesRefetch();
                toast.success("Tehsil deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteUnionCouncilsApiHandler = (id) => {
        deleteUnionCouncils({ data: id })
            .unwrap()
            .then(() => {
                deleteUnionCouncilsModalHandler();
                getCountriesRefetch();
                toast.success("Union Council deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteVillagesApiHandler = (id) => {
        deleteVillages({ data: id })
            .unwrap()
            .then(() => {
                deleteVillagesModalHandler();
                getCountriesRefetch();
                toast.success("Village deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            case 'state':
                setSelectedState(selectedOption);
                break;
            case 'city':
                setSelectedCity(selectedOption);
                break;
            case 'district':
                setSelectedDistrict(selectedOption);
                break;
            case 'tehsil':
                setSelectedTehsil(selectedOption);
                break;
            case 'unionCouncil':
                setSelectedUnionCouncil(selectedOption);
                break;
            case 'village':
                setSelectedVillage(selectedOption);
                break;
            default:
                break;
        }
    };

    const [uploadCountries, { isLoading: uploadCountriesLoading }] = useUploadCountriesMutation();
    const [uploadStates, { isLoading: uploadStatesLoading }] = useUploadStatesMutation();
    const [uploadCities, { isLoading: uploadCitiesLoading }] = useUploadCitiesMutation();
    const [uploadDistricts, { isLoading: uploadDistrictsLoading }] = useUploadDistrictsMutation();
    const [uploadTehsils, { isLoading: uploadTehsilsLoading }] = useUploadTehsilsMutation();
    const [uploadUnionCouncils, { isLoading: uploadUnionCouncilsLoading }] = useUploadUnionCouncilsMutation();
    const [uploadVillages, { isLoading: uploadVillagesLoading }] = useUploadVillagesMutation();

    const uploadCountriesApiHandler = (data) => {
        uploadCountries({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getCountriesRefetch();
                toast.success("Countries uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadStatesApiHandler = (data) => {
        uploadStates({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getStatesRefetch();
                toast.success("States uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadCitiesApiHandler = (data) => {
        uploadCities({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getCitiesRefetch();
                toast.success("Cities uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadDistrictsApiHandler = (data) => {
        uploadDistricts({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getDistrictsRefetch();
                toast.success("Districts uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadTehsilsApiHandler = (data) => {
        uploadTehsils({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getTehsilsRefetch();
                toast.success("Tehsils uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadUnionCouncilsApiHandler = (data) => {
        uploadUnionCouncils({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getUnionCouncilsRefetch();
                toast.success("Union Councils uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const uploadVillagesApiHandler = (data) => {
        uploadVillages({ data: data })
            .unwrap()
            .then(() => {
                addCsvDataModalHandler();
                getVillagesRefetch();
                toast.success("Villages uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        getCountriesRefetch();
        getStatesRefetch();
        getCitiesRefetch();
        getDistrictsRefetch();
        getTehsilsRefetch();
        getUnionCouncilsRefetch();
        getVillagesRefetch();
    }, []);

    useEffect(() => {
        if (addCountrySuccess || editCountrySuccess || deleteCountrySuccess || addStatesSuccess || editStatesSuccess || deleteStatesSuccess || addCitiesSuccess || editCitiesSuccess || deleteCitiesSuccess || addDistrictsSuccess || editDistrictsSuccess || deleteDistrictsSuccess || addTehsilsSuccess || editTehsilsSuccess || deleteTehsilsSuccess || addUnionCouncilsSuccess || editUnionCouncilsSuccess || deleteUnionCouncilsSuccess || addVillagesSuccess || editVillagesSuccess || deleteVillagesSuccess) {
            window.location.reload();
        }
    }, [addCountrySuccess, editCountrySuccess, deleteCountrySuccess, addStatesSuccess, editStatesSuccess, deleteStatesSuccess, addCitiesSuccess, editCitiesSuccess, deleteCitiesSuccess, addDistrictsSuccess, editDistrictsSuccess, deleteDistrictsSuccess, addTehsilsSuccess, editTehsilsSuccess, deleteTehsilsSuccess, addUnionCouncilsSuccess, editUnionCouncilsSuccess, deleteUnionCouncilsSuccess, addVillagesSuccess, editVillagesSuccess, deleteVillagesSuccess]);

    return (
        <div className='row'>

            <h1 className='mb-4'>Locations</h1>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Countries</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allCountries}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="country"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addCountryModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("country")} />
                        {selectedCountry ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editCountryModalHandler(selectedCountry)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteCountryModalHandler(selectedCountry)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>States</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allStates}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="state"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addStatesModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("state")} />
                        {selectedState ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editStatesModalHandler(selectedState)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteStatesModalHandler(selectedState)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Cities</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allCities}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="city"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addCitiesModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("city")} />
                        {selectedCity ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editCitiesModalHandler(selectedCity)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteCitiesModalHandler(selectedCity)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Districts</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allDistricts}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="district"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addDistrictsModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("district")} />
                        {selectedDistrict ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editDistrictsModalHandler(selectedDistrict)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteDistrictsModalHandler(selectedDistrict)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Tehsils</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allTehsils}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="tehsil"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addTehsilsModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("tehsil")} />
                        {selectedTehsil ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editTehsilsModalHandler(selectedTehsil)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteTehsilsModalHandler(selectedTehsil)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Union Councils</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allUnionCouncils}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="unionCouncil"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addUnionCouncilsModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("uc")} />
                        {selectedUnionCouncil ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editUnionCouncilsModalHandler(selectedUnionCouncil)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteUnionCouncilsModalHandler(selectedUnionCouncil)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-sm-12 mb-5">
                <label className='fs-4'>Villages</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <Select
                        options={allVillages}
                        onChange={handleLocationChange}
                        className='w-100 me-3'
                        name="village"
                    />
                    <div className='d-flex gap-3'>
                        <img src={add} alt="" className='actionIcons' onClick={addVillagesModalHandler} />
                        <img src={importCsv} alt="" className='actionIcons' onClick={() => addCsvDataModalHandler("village")} />
                        {selectedVillage ?
                            <>
                                <img src={edit} alt="" className='actionIcons' onClick={() => editVillagesModalHandler(selectedVillage)} />
                                <img src={del} alt="" className='actionIcons' onClick={() => deleteVillagesModalHandler(selectedVillage)} />
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

            {addCsvDataModal &&
                <AddCSVFileModal
                    handleCloseAddCSVFileModal={addCsvDataModalHandler}
                    type={csvDataType}
                    actionCountry={uploadCountriesApiHandler}
                    actionState={uploadStatesApiHandler}
                    actionCity={uploadCitiesApiHandler}
                    actionDistrict={uploadDistrictsApiHandler}
                    actionTehsil={uploadTehsilsApiHandler}
                    actionUc={uploadUnionCouncilsApiHandler}
                    actionVillage={uploadVillagesApiHandler}
                    loading={uploadCountriesLoading || uploadStatesLoading || uploadCitiesLoading || uploadDistrictsLoading || uploadTehsilsLoading || uploadUnionCouncilsLoading || uploadVillagesLoading}
                />
            }

            {addCountryModal &&
                <AddCountryModal
                    handleCloseAddCountryModal={addCountryModalHandler}
                    action={addCountryApiHandler}
                    loading={addCountryLoading}
                />
            }

            {addStatesModal &&
                <AddStateModal
                    handleCloseAddStateModal={addStatesModalHandler}
                    action={addStatesApiHandler}
                    loading={addStatesLoading}
                    country={selectedCountry}
                />
            }

            {addCitiesModal &&
                <AddCityModal
                    handleCloseAddCityModal={addCitiesModalHandler}
                    action={addCitiesApiHandler}
                    loading={addCitiesLoading}
                    state={selectedState}
                />
            }

            {addDistrictsModal &&
                <AddDistrictModal
                    handleCloseAddDistrictModal={addDistrictsModalHandler}
                    action={addDistrictsApiHandler}
                    loading={addDistrictsLoading}
                    city={selectedCity}
                />
            }

            {addTehsilsModal &&
                <AddTehsilModal
                    handleCloseAddTehsilModal={addTehsilsModalHandler}
                    action={addTehsilsApiHandler}
                    loading={addTehsilsLoading}
                    district={selectedDistrict}
                />
            }

            {addUnionCouncilsModal &&
                <AddUnionCouncilModal
                    handleCloseAddUnionCouncilModal={addUnionCouncilsModalHandler}
                    action={addUnionCouncilsApiHandler}
                    loading={addUnionCouncilsLoading}
                    tehsil={selectedTehsil}
                />
            }

            {addVillagesModal &&
                <AddVillageModal
                    handleCloseAddVillageModal={addVillagesModalHandler}
                    action={addVillagesApiHandler}
                    loading={addVillagesLoading}
                    unionCouncil={selectedUnionCouncil}
                />
            }

            {editCountryModal &&
                <AddCountryModal
                    handleCloseAddCountryModal={editCountryModalHandler}
                    actionUpdate={editCountryApiHandler}
                    loading={editCountryLoading}
                    data={viewCountryData}
                />
            }

            {editStatesModal &&
                <AddStateModal
                    handleCloseAddStateModal={editStatesModalHandler}
                    actionUpdate={editStatesApiHandler}
                    loading={editStatesLoading}
                    country={selectedCountry}
                    data={viewStatesData}
                />
            }

            {editCitiesModal &&
                <AddCityModal
                    handleCloseAddCityModal={editCitiesModalHandler}
                    actionUpdate={editCitiesApiHandler}
                    loading={editCitiesLoading}
                    state={selectedState}
                    data={viewCitiesData}
                />
            }

            {editDistrictsModal &&
                <AddDistrictModal
                    handleCloseAddDistrictModal={editDistrictsModalHandler}
                    actionUpdate={editDistrictsApiHandler}
                    loading={editDistrictsLoading}
                    city={selectedCity}
                    data={viewDistrictsData}
                />
            }

            {editTehsilsModal &&
                <AddTehsilModal
                    handleCloseAddTehsilModal={editTehsilsModalHandler}
                    actionUpdate={editTehsilsApiHandler}
                    loading={editTehsilsLoading}
                    district={selectedDistrict}
                    data={viewTehsilsData}
                />
            }

            {editUnionCouncilsModal &&
                <AddUnionCouncilModal
                    handleCloseAddUnionCouncilModal={editUnionCouncilsModalHandler}
                    actionUpdate={editUnionCouncilsApiHandler}
                    loading={editUnionCouncilsLoading}
                    tehsil={selectedTehsil}
                    data={viewUnionCouncilsData}
                />
            }

            {editVillagesModal &&
                <AddVillageModal
                    handleCloseAddVillageModal={editVillagesModalHandler}
                    actionUpdate={editVillagesApiHandler}
                    loading={editVillagesLoading}
                    unionCouncil={selectedUnionCouncil}
                    data={viewVillagesData}
                />
            }

            {deleteCountryModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteCountryModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="Country"
                    id={viewCountryData?.value}
                    action={deleteCountryApiHandler}
                    loading={deleteCountryLoading}
                />
            }

            {deleteStatesModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteStatesModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="State"
                    id={viewStatesData?.value}
                    action={deleteStatesApiHandler}
                    loading={deleteStatesLoading}
                />
            }

            {deleteCitiesModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteCitiesModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="City"
                    id={viewCitiesData?.value}
                    action={deleteCitiesApiHandler}
                    loading={deleteCitiesLoading}
                />
            }

            {deleteDistrictsModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteDistrictsModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="District"
                    id={viewDistrictsData?.value}
                    action={deleteDistrictsApiHandler}
                    loading={deleteDistrictsLoading}
                />
            }

            {deleteTehsilsModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteTehsilsModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="Tehsil"
                    id={viewTehsilsData?.value}
                    action={deleteTehsilsApiHandler}
                    loading={deleteTehsilsLoading}
                />
            }

            {deleteUnionCouncilsModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteUnionCouncilsModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="Union Council"
                    id={viewUnionCouncilsData?.value}
                    action={deleteUnionCouncilsApiHandler}
                    loading={deleteUnionCouncilsLoading}
                />
            }

            {deleteVillagesModal &&
                <DeleteModal
                    handleCloseDeleteModal={deleteVillagesModalHandler}
                    confirmationMessage="Are you sure you want to delete this"
                    name="Village"
                    id={viewVillagesData?.value}
                    action={deleteVillagesApiHandler}
                    loading={deleteVillagesLoading}
                />
            }
        </div>
    )
}

export default Location;