import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddTeacherExperienceModal = (props) => {

    const [isCurrentJob, setIsCurrentJob] = useState("");

    const validationSchema = Yup.object().shape({
        Organization: Yup.string().required("Organization is required"),
        Designation: Yup.string().required("Designation is required"),
        DateFrom: Yup.string().required("Date From is required"),
        DateTo: Yup.string().required('Date To is required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            organization: values?.Organization,
            designation: values?.Designation,
            dateFrom: values?.DateFrom,
            dateTo: values?.DateTo,
            isCurrentJob: isCurrentJob,
        };

        props?.experienceData(data);
    };

    // useEffect(() => {
    //     if (props?.data) {
    //         let { name, address, industry, type } = props?.data;
    //         reset({ name: name, address: address, industry: industry, type: type });
    //     }
    // }, [props?.data]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddTeacherExperienceModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">{props?.data ? "Edit" : "Add"} Teacher Experience</h1>
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Organization</label>
                                <input type="text" className="themeInput fs-4" placeholder='Organization' {...register("Organization")} />

                                {errors.Organization && <p className='errorMsg'>{errors.Organization.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Designation</label>
                                <input type="text" className="themeInput fs-4" placeholder='Designation' {...register("Designation")} />

                                {errors.Designation && <p className='errorMsg'>{errors.Designation.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Date From</label>
                                <input type="date" className="themeInput fs-4" {...register("DateFrom")} />

                                {errors.DateFrom && <p className='errorMsg'>{errors.DateFrom.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Date To</label>
                                <input type="date" className="themeInput fs-4" {...register("DateTo")} />

                                {errors.DateTo && <p className='errorMsg'>{errors.DateTo.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Is Current Job</label>
                                <select className="themeInput fs-4" onChange={(e) => setIsCurrentJob(e.target.value)} value={isCurrentJob}>
                                    <option value="">Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>

                        </div>

                        <div className="text-center">
                            <Button
                                label="Submit"
                                className="mt-5 themeBtn"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddTeacherExperienceModal;