import { Col, Row } from "reactstrap";
import TopCards from "../../components/dashboard/TopCards";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { useDashboardCountQuery, useGetAssesmentsByCountryQuery, useGetCountriesQuery, useGetQuestionnairesByIdQuery } from "../../services/Api";
import QuestionnairesCollapse from "./QuestionnairesCollapse";
import { loader } from '../../assets/imagesPath';

const Dashboard = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [questions, setQuestions] = useState([]);

  const {
    data: getCountries,
    refetch: getCountriesRefetch,
  } = useGetCountriesQuery();

  const {
    data: getAssesmentsByCountry,
    refetch: getAssesmentsByCountryRefetch,
  } = useGetAssesmentsByCountryQuery({ params: { id: selectedCountry?.value } }, { skip: !selectedCountry });

  const handleLocationChange = (selectedOption, { name }) => {
    if (name == 'country') {
      setSelectedCountry(selectedOption);
      setSelectedQuestionnaire(null);
      setQuestionnaires([]);
      setQuestions([]);
    } else if (name == 'questionnaire') {
      const selectedQues = getAssesmentsByCountry?.find(item => item?.id == selectedOption?.value);
      setSelectedQuestionnaire(selectedOption);
      setQuestions(selectedQues?.questionnaires?.map(question => ({
        value: question.id,
        label: question.title,
      })));
    }
  };

  const handleQuestionChange = (selectedOption) => {
    setSelectedQuestion(selectedOption);
  };

  const {
    data: getQuestionnairesById,
    refetch: getQuestionnairesByIdRefetch,
    isLoading: getQuestionnairesByIdLoading,
  } = useGetQuestionnairesByIdQuery(
    { params: { id: selectedQuestion?.value } },
    { skip: !selectedQuestion }
  );

  const {
    data: dashboardCount,
    refetch: dashboardCountRefetch,
  } = useDashboardCountQuery();

  const preprocessLocationData = (locations) => {
    return locations?.map(location => ({
      label: location?.name,
      value: location?._id,
    }));
  };

  const allCountries = preprocessLocationData(getCountries || []);

  useEffect(() => {
    getCountriesRefetch();
    dashboardCountRefetch();
  }, []);

  useEffect(() => {
    if (getAssesmentsByCountry && selectedCountry) {
      const filteredQuestionnaires = getAssesmentsByCountry
        ?.filter(item => item?.country === selectedCountry?.value)
        ?.map(item => ({ value: item?.id, label: item?.title }));

      setQuestionnaires(filteredQuestionnaires);
    }
  }, [getAssesmentsByCountry, selectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      getAssesmentsByCountryRefetch({ params: { id: selectedCountry?.value } });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedQuestion) {
      getQuestionnairesByIdRefetch({ params: { id: selectedQuestion?.value } });
    }
  }, [selectedQuestion]);

  return (
    <div>
      <Row>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-success text-success"
            subtitle="Assessments"
            earning={dashboardCount?.assessmentCount}
            icon="bi bi-building-check"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-danger text-danger"
            subtitle="Questionnaires"
            earning={dashboardCount?.questionnaireCount}
            icon="bi bi-newspaper"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-warning text-warning"
            subtitle="Users"
            earning={dashboardCount?.userCount}
            icon="bi bi-people"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-info text-info"
            subtitle="Projects"
            earning={dashboardCount?.projectCount}
            icon="bi bi-buildings"
          />
        </Col>
      </Row>

      <div className="row">
        <div className="col-md-4">
          <Select
            options={allCountries}
            onChange={handleLocationChange}
            name="country"
            className='w-100 me-3 fs-4'
            placeholder="Select Country"
          />
        </div>

        <div className="col-md-4">
          <Select
            options={questionnaires}
            value={selectedQuestionnaire}
            onChange={handleLocationChange}
            name="questionnaire"
            className='w-100 me-3 fs-4'
            placeholder="Select Assesment"
            isDisabled={!questionnaires.length}
          />
        </div>

        <div className="col-md-4">
          <Select
            options={questions}
            onChange={handleQuestionChange}
            className='w-100 me-3 fs-4'
            placeholder="Select Questionnaire"
            isDisabled={!questions.length}
          />
        </div>

        <div className="col-12 mt-5">
          {getQuestionnairesByIdLoading ?
            <div className="text-center">
              <img src={loader} alt="" style={{ height: "5rem" }} />
            </div>
            :
            <QuestionnairesCollapse data={getQuestionnairesById} questionnaireId={selectedQuestionnaire?.value} assesmentId={selectedQuestion?.value} />
          }
        </div>
      </div>
    </div>
  );
};

export default Dashboard;