import React, { useState, memo, useEffect } from 'react';
import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import Section from './Section';
import { useGetQuestionnairesByIdQuery } from '../../../services/Api';
import { useLocation } from 'react-router-dom';
import { loader } from '../../../assets/imagesPath';

const ViewQuestionnaire = () => {
    const location = useLocation();

    const {
        data: getQuestionnairesById,
        refetch: getQuestionnairesByIdRefetch,
        isLoading: getQuestionnairesByIdLoading
    } = useGetQuestionnairesByIdQuery({
        params: {
            id: location?.state?.data?.id,
            userId: location?.state?.userId,
            assessmentId: location?.state?.assessmentId,
            recordId: location?.state?.recordId
        }
    });

    const initialQuestionnaire = {
        title: getQuestionnairesById?.title,
        description: getQuestionnairesById?.description,
        sections: getQuestionnairesById?.sections
    };

    const [questionnaire, setQuestionnaire] = useState(initialQuestionnaire);

    useEffect(() => {
        if (getQuestionnairesById) {
            setQuestionnaire({
                title: getQuestionnairesById.title || '',
                description: getQuestionnairesById.description || '',
                sections: getQuestionnairesById.sections || []
            });
        }
    }, [getQuestionnairesById]);

    const handleDeleteSection = (sectionId) => {
        setQuestionnaire((prev) => ({
            ...prev,
            sections: prev?.sections?.filter((sec) => sec?._id !== sectionId)
        }));
    };

    useEffect(() => {
        getQuestionnairesByIdRefetch();
    }, []);

    return (
        <>
            {getQuestionnairesByIdLoading ? (
                <div className="text-center">
                    <img src={loader} alt="" height={100} />
                </div>
            ) : (
                <fieldset disabled={location?.state?.viewOnly ? true : false}>
                    <Box sx={{ p: 3 }}>
                        <Paper sx={{ p: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <Typography variant="subtitle1">Guide Name</Typography>
                                    <TextField fullWidth placeholder="Guide Name" value={getQuestionnairesById?.guideFile} disabled />
                                </Grid>
                                <Grid item lg={12}>
                                    <Typography variant="subtitle1">Questionnaire title</Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter Title"
                                        value={questionnaire?.title}
                                        onChange={(e) => setQuestionnaire({ ...questionnaire, title: e.target.value })}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <Typography variant="subtitle1">Questionnaire description</Typography>
                                    <TextField
                                        multiline
                                        rows={3}
                                        fullWidth
                                        placeholder="Add Description"
                                        value={questionnaire?.description}
                                        onChange={(e) => setQuestionnaire({ ...questionnaire, description: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                        {questionnaire?.sections?.map((section, index) => (
                            <>
                                <Section
                                    key={section?._id}
                                    section={section}
                                    updateSection={(updatedSection) => {
                                        setQuestionnaire((prev) => ({
                                            ...prev,
                                            sections: prev.sections.map((sec) => (sec._id === updatedSection._id ? updatedSection : sec))
                                        }));
                                    }}
                                    deleteSection={handleDeleteSection}
                                    viewOnly={location?.state?.viewOnly}
                                />
                            </>
                        ))}
                    </Box>
                </fieldset>
            )}
        </>
    );
};

export default memo(ViewQuestionnaire);
