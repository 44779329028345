import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddTeacherQualificationModal = (props) => {

    const [qualiType, setQualiType] = useState("");
    const [level, setLevel] = useState("");

    const validationSchema = Yup.object().shape({
        PassingDate: Yup.string().required("Passing Date is required"),
        Duration: Yup.string().required("Duration is required"),
        ObtainedMarks: Yup.string().required("Obtained Marks is required"),
        TotalMarks: Yup.string().required('Total Marks is required'),
        Grade: Yup.string().required('Grade is required'),
        Decipline: Yup.string().required('Decipline is required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            qualificationLevel: qualiType,
            discipline: values?.Decipline,
            passingDate: values?.PassingDate,
            duration: values?.Duration,
            obtainedMarks: values?.ObtainedMarks,
            totalMarks: values?.TotalMarks,
            grade: values?.Grade,
        };

        props?.qualificationData(data);
    };

    // useEffect(() => {
    //     if (props?.data) {
    //         let { name, address, industry, type } = props?.data;
    //         reset({ name: name, address: address, industry: industry, type: type });
    //     }
    // }, [props?.data]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddTeacherQualificationModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">{props?.data ? "Edit" : "Add"} Teacher Qualification</h1>
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Qualification Type</label>
                                <select className="themeInput fs-4" onChange={(e) => setQualiType(e.target.value)} value={qualiType}>
                                    <option value="">Select</option>
                                    <option value="Academic">Academic</option>
                                    <option value="Professional">Professional</option>
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Qualification Level</label>
                                <select className="themeInput fs-4" onChange={(e) => setLevel(e.target.value)} value={level}>
                                    <option value="">Select</option>
                                    <option value="1">Matric</option>
                                    <option value="2">Bachelor</option>
                                    <option value="3">Master</option>
                                    <option value="4">Intermediate</option>
                                    <option value="5">Ph.d</option>
                                    <option value="7">Others</option>
                                    <option value="11">Diploma</option>
                                    <option value="13">M.Phil</option>
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Decipline</label>
                                <input type="text" className="themeInput fs-4" placeholder='Decipline' {...register("Decipline")} />

                                {errors.Decipline && <p className='errorMsg'>{errors.Decipline.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Passing Date</label>
                                <input type="date" className="themeInput fs-4" {...register("PassingDate")} />

                                {errors.PassingDate && <p className='errorMsg'>{errors.PassingDate.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Duration</label>
                                <input type="text" className="themeInput fs-4" placeholder='Duration' {...register("Duration")} />

                                {errors.Duration && <p className='errorMsg'>{errors.Duration.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Obtained Marks</label>
                                <input type="text" className="themeInput fs-4" placeholder='Obtained Marks' {...register("ObtainedMarks")} />

                                {errors.ObtainedMarks && <p className='errorMsg'>{errors.ObtainedMarks.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Total Marks</label>
                                <input type="text" className="themeInput fs-4" placeholder='Total Marks' {...register("TotalMarks")} />

                                {errors.TotalMarks && <p className='errorMsg'>{errors.TotalMarks.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">CGPA/Grade/Divsion</label>
                                <input type="text" className="themeInput fs-4" placeholder='CGPA/Grade/Divsion' {...register("Grade")} />

                                {errors.Grade && <p className='errorMsg'>{errors.Grade.message}</p>}
                            </div>

                        </div>

                        <div className="text-center">
                            <Button
                                label="Submit"
                                className="mt-5 themeBtn"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddTeacherQualificationModal;