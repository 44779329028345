import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import { useGetAllQuestionsQuery } from "../../services/Api";
import Select from 'react-select';

const AddQuestionnaireModal = (props) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [questions, setQuestions] = useState([]);

    const {
        data: getAllQuestions,
        refetch: getAllQuestionsRefetch,
    } = useGetAllQuestionsQuery();

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    const onSubmit = () => {

        const data = {
            title: title,
            description: description,
            questions: questions,
        };

        const dataWithId = {
            title: title,
            description: description,
            questions: questions,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    const validationHandler = () => {
        let noError = false;

        titleValidationHandler();
        descriptionValidationHandler();
        questionsValidationHandler();

        if (title && description && questions?.length) {
            onSubmit();
        } else {
            return false;
        }

        return noError;
    };

    const titleValidationHandler = () => {
        if (!title) {
            setError("title", { type: "custom", message: "Title is required" });
        } else {
            clearErrors("title");
        }
    };

    const descriptionValidationHandler = () => {
        if (!description) {
            setError("description", { type: "custom", message: "Description is required" });
        } else {
            clearErrors("description");
        }
    };

    const questionsValidationHandler = () => {
        if (!questions?.length) {
            setError("questions", { type: "custom", message: "Question(s) is required" });
        } else {
            clearErrors("questions");
        }
    };

    const handleChange = (selectedOptions) => {
        let questions = selectedOptions?.map(option => option?.value);
        setQuestions(questions);
    };

    const preprocessLocationData = (questions) => {
        return questions?.map(questions => ({
            label: questions?.text,
            value: questions?.id,
        }));
    };

    const allQuestions = preprocessLocationData(getAllQuestions || []);

    const names = props?.data?.questions?.map(questions => ({
        label: questions?.text,
        value: questions?.id,
    }));

    useEffect(() => {
        if (props?.data) {
            setTitle(props?.data?.title);
            setDescription(props?.data?.description);
        }
    }, [props?.data]);

    useEffect(() => {
        getAllQuestionsRefetch();
    }, []);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddQuestionnaireModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Questionnaire</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Questionnaire</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-12 mb-3">
                                <label className="fs-4 mb-3">Title</label>
                                <input type="text" className="themeInput fs-4" placeholder='Title' name="title" onChange={(e) => setTitle(e.target.value)} value={title} disabled={props?.view ? true : false} />

                                {errors.title && <p className='errorMsg'>{errors.title.message}</p>}
                            </div>

                            <div className="col-12 mb-3">
                                <label className="fs-4 mb-3">Description</label>
                                <textarea type="text" className="themeInput themeTextarea fs-4" placeholder='Description' name="name" onChange={(e) => setDescription(e.target.value)} value={description} disabled={props?.view ? true : false} />

                                {errors.description && <p className='errorMsg'>{errors.description.message}</p>}
                            </div>

                            <div className="col-12 mb-3">
                                <label className='fs-4 mb-3'>Questions</label>
                                <Select
                                    options={allQuestions}
                                    onChange={handleChange}
                                    className='w-100 me-3'
                                    name="center"
                                    defaultValue={names}
                                    isDisabled={props?.view ? true : false}
                                    isMulti={true}
                                />

                                {errors.questions && <p className='errorMsg'>{errors.questions.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={validationHandler}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddQuestionnaireModal;