import React from 'react';

const Question = ({ sectionId, subsectionId, errors, question, responses, handleInputChange }) => {

    const handleChange = (e) => {
        handleInputChange(sectionId, subsectionId, question?._id, e.target.value);
    };

    const getQuestionLabel = () => (
        <>
            {question?.text} {question?.required && <span style={{ color: 'red' }}>*</span>}
        </>
    );

    const getValue = () => {
        if (subsectionId) {
            return responses[sectionId]?.[subsectionId]?.[question?._id] || '';
        } else {
            return responses[sectionId]?.[question?._id] || '';
        }
    };

    const getErrors = () => (
        <>
            {errors &&
                <div className="text-danger fs-5">
                    {errors[question._id] || errors?.[subsectionId]?.[question?._id]}
                </div>
            }
        </>
    );

    switch (question?.type) {
        case 'text':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="text"
                        className='themeInput'
                        placeholder='Type..'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'radio':
            return (
                <div>
                    <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
                    {question?.options?.map(option => (
                        <div className='d-flex align-items-center gap-3 mb-3' key={option}>
                            <input
                                type="radio"
                                name={question?._id}
                                value={option}
                                checked={getValue() === option}
                                onChange={handleChange}
                            />
                            <h5 className='mb-0'>{option}</h5>
                        </div>
                    ))}
                    {getErrors()}
                </div>
            );
        case 'checkbox':
            return (
                <div>
                    <h3 className='mt-4 mb-3'>{getQuestionLabel()}</h3>
                    {question?.options?.map(option => (
                        <div className='d-flex align-items-center gap-3 mb-3' key={option}>
                            <input
                                type="checkbox"
                                value={option}
                                checked={(getValue() || []).includes(option)}
                                onChange={(e) => {
                                    const currentResponses = getValue() || [];
                                    const newValue = e.target.checked
                                        ? [...currentResponses, option]
                                        : currentResponses?.filter(val => val !== option);
                                    handleInputChange(sectionId, subsectionId, question?._id, newValue);
                                }}
                            />
                            <h5 className='mb-0'>{option}</h5>
                        </div>
                    ))}
                    {getErrors()}
                </div>
            );
        case 'file-upload':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="file"
                        onChange={(e) => handleInputChange(sectionId, subsectionId, question?._id, e.target.files[0])}
                    />
                    {getErrors()}
                </div>
            );
        case 'date':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="date"
                        className='themeInput'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'time':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="time"
                        className='themeInput'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'date-time':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="datetime-local"
                        className='themeInput'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'phone-number':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'number':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        case 'rating':
            return (
                <div>
                    <h3 className='mt-4'>{getQuestionLabel()}</h3>
                    <input
                        type="number"
                        className='themeInput'
                        min="1"
                        max="5"
                        value={getValue()}
                        onChange={handleChange}
                    />
                    {getErrors()}
                </div>
            );
        default:
            return null;
    }
};

export default Question;