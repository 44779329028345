import React from 'react';
import ViewQuestion from './ViewQuestion';
import ViewSubsection from './ViewSubsection';

const ViewSection = ({ section, responses }) => {
    return (
        <div className='sectionDiv'>
            <h2 className='sectionHeader p-4'>{section?.title}</h2>

            <div className='ps-4 pe-4'>
                {section?.questions?.map((question) => {
                    return (
                        <ViewQuestion
                            key={question?._id}
                            sectionId={section?._id}
                            question={question}
                            responses={responses}
                        />
                    )
                })}
            </div>

            {section?.subsections?.map(subsection => (
                <ViewSubsection
                    key={subsection?._id}
                    sectionId={section?._id}
                    subsection={subsection}
                    responses={responses}
                />
            ))}
        </div>
    );
};

export default ViewSection;