import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import { BASE_URL_IMAGES } from "../../services/ApiEndpoints";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ImageViewer from "../../components/imageViewer/ImageViewer";
import { useEditTeacherMutation, useGetCitiesQuery, useGetCountriesQuery, useGetDistrictsQuery, useGetPartnersQuery, useGetProjectsQuery, useGetStatesQuery, useGetTehsilsQuery, useGetUnionCouncilsQuery, useGetVillagesQuery, useUploadTeacherAvatarMutation } from "../../services/Api";
import Select from 'react-select';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PATHS from "../../routes/Paths";
import { Table } from "reactstrap";
import AddTeacherExperienceModal from "../../components/allModals/AddTeacherExperienceModal";
import AddTeacherTrainingModal from "../../components/allModals/AddTeacherTrainingModal";
import AddTeacherQualificationModal from "../../components/allModals/AddTeacherQualificationModal";
import moment from "moment/moment";

const EditTeacher = () => {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedTehsil, setSelectedTehsil] = useState(null);
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState(null);
    const [selectedVillage, setSelectedVillage] = useState(null);
    const [cnic, setCnic] = useState("");
    const [error, setError] = useState("");
    const [citizenship, setCitizenship] = useState('');
    const [guardianRelation, setGuardianRelation] = useState('');
    const [gender, setGender] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [teacherType, setTeacherType] = useState('');
    const [documentVerified, setDocumentVerified] = useState('false');
    const [androidPhone, setAndroidPhone] = useState('');
    const [network, setNetwork] = useState('');
    const [classAvailability, setClassAvailability] = useState('');
    const [classSize, setClassSize] = useState('');
    const [bankName, setBankName] = useState('');
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [fileName, setFileName] = useState('');
    const [teacherQualification, setTeacherQualification] = useState([]);
    const [teacherExperience, setTeacherExperience] = useState([]);
    const [teacherTraining, setTeacherTraining] = useState([]);
    const [addTeacherExpModal, setAddTeacherExpModal] = useState(false);
    const [addTeacherTrainingModal, setAddTeacherTrainingModal] = useState(false);
    const [addTeacherQualificationModal, setAddTeacherQualificationModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const propsData = location?.state?.data;

    const addTeacherQualificationModalHandler = () => {
        setAddTeacherQualificationModal((prev) => !prev);
    };

    const addTeacherExpModalHandler = () => {
        setAddTeacherExpModal((prev) => !prev);
    };

    const addTeacherTrainingModalHandler = () => {
        setAddTeacherTrainingModal((prev) => !prev);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        telephoneNo: Yup.string().required("Phone No. is required"),
        dateOfBirth: Yup.string().required("DOB is required"),
        joiningDate: Yup.string().required("Date is required"),
        guardianName: Yup.string().required("Guardian Name is required"),
        address: Yup.string().required("Address is required"),
        branchCode: Yup.string().required("Branch Code is required"),
        accountNo: Yup.string().required("Account No. is required"),
        branchName: Yup.string().required("Branch Name is required"),
        salary: Yup.string().required("Salary is required"),
        salaryPaidUpToMonth: Yup.string().required("Salary Month is required"),
    });

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const {
        data: getStates,
        refetch: getStatesRefetch,
    } = useGetStatesQuery();

    const {
        data: getCities,
        refetch: getCitiesRefetch,
    } = useGetCitiesQuery();

    const {
        data: getDistricts,
        refetch: getDistrictsRefetch,
    } = useGetDistrictsQuery();

    const {
        data: getTehsils,
        refetch: getTehsilsRefetch,
    } = useGetTehsilsQuery();

    const {
        data: getUnionCouncils,
        refetch: getUnionCouncilsRefetch,
    } = useGetUnionCouncilsQuery();

    const {
        data: getPartners,
        refetch: getPartnersRefetch,
    } = useGetPartnersQuery();

    const {
        data: getProjects,
        refetch: getProjectsRefetch,
    } = useGetProjectsQuery();

    const {
        data: getVillages,
        refetch: getVillagesRefetch,
    } = useGetVillagesQuery();

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?.value,
            id: locations?._id,
        }));
    };

    const useFilteredData = (data, selectedValue, key) => {

        const [filteredData, setFilteredData] = useState([]);

        useEffect(() => {
            if (selectedValue) {
                const filtered = data?.filter(
                    (item) => item[key] == selectedValue?.value
                );
                setFilteredData(filtered);
            } else {
                setFilteredData([]);
            }
        }, [selectedValue, data, key]);

        return filteredData;
    };

    const [uploadTeacherAvatar] = useUploadTeacherAvatarMutation();

    const filteredStates = useFilteredData(getStates, selectedCountry, 'countryId');
    const filteredCities = useFilteredData(getCities, selectedState, 'stateId');
    const filteredDistricts = useFilteredData(getDistricts, selectedCity, 'cityId');
    const filteredTehsils = useFilteredData(getTehsils, selectedDistrict, 'districtId');
    const filteredUnionCouncils = useFilteredData(getUnionCouncils, selectedTehsil, 'tehsilId');
    const filteredVillages = useFilteredData(getVillages, selectedUnionCouncil, 'unionCouncilId');

    let { avatar } = watch();

    const [editTeacher, { isLoading: editTeacherLoading }] = useEditTeacherMutation();

    const onSubmit = (values) => {

        let data = {
            id: propsData?._id,
            country: selectedCountry?.id || propsData?.country?.id,
            state: selectedState?.id || propsData?.state?.id,
            city: selectedCity?.id || propsData?.city?.id,
            district: selectedDistrict?.id || propsData?.district?.id,
            tehsil: selectedTehsil?.id || propsData?.tehsil?.id,
            unionCouncil: selectedUnionCouncil?.id || propsData?.unionCouncil?.id,
            village: selectedVillage?.id || propsData?.village?.id,
            name: values?.name,
            avatar: fileName | propsData?.avatar,
            telephoneNo: values?.telephoneNo,
            citizenship: citizenship,
            cnic: cnic,
            dateOfBirth: values?.dateOfBirth,
            joiningDate: values?.joiningDate,
            partner: selectedPartner?.value || propsData?.partner?.id,
            project: selectedProject?.value || propsData?.project?.id,
            guardianName: values?.guardianName,
            relationToGuardian: guardianRelation,
            gender: gender,
            maritalStatus: maritalStatus,
            teacherType: teacherType,
            address: values?.address,
            documentVerified: documentVerified,
            hasAndroidPhone: androidPhone,
            preferredNetworkConnection: network,
            classRoomAvailability: classAvailability,
            classRoomSize: classSize,
            schoolGPSCoordinates: values?.schoolGPSCoordinates,
            bank: bankName,
            branchCode: values?.branchCode,
            accountNo: values?.accountNo,
            branchName: values?.branchName,
            salary: values?.salary,
            salaryPaidUpToMonth: values?.salaryPaidUpToMonth,
            qualifications: teacherQualification,
            teachingExperience: teacherExperience,
            trainings: teacherTraining,
        };

        editTeacher({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Teacher added successfully");
                navigate(PATHS.teachers);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });

    };

    const uploadImgHandler = (e) => {
        let { name, files } = e.target;
        setValue(name, files[0]);
        addAvatarApiHandler(files[0]);
    };

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            case 'state':
                setSelectedState(selectedOption);
                break;
            case 'city':
                setSelectedCity(selectedOption);
                break;
            case 'district':
                setSelectedDistrict(selectedOption);
                break;
            case 'tehsil':
                setSelectedTehsil(selectedOption);
                break;
            case 'unionCouncil':
                setSelectedUnionCouncil(selectedOption);
                break;
            case 'village':
                setSelectedVillage(selectedOption);
                break;
            default:
                break;
        }
    };

    const preprocessData = (data) => {
        return data?.map(data => ({
            label: data?.name || data?.firstName || data?.title,
            value: data?.id || data?._id,
        }));
    };

    const allCountries = preprocessLocationData(getCountries || []);
    const allStates = preprocessLocationData(filteredStates || []);
    const allCities = preprocessLocationData(filteredCities || []);
    const allDistricts = preprocessLocationData(filteredDistricts || []);
    const allTehsils = preprocessLocationData(filteredTehsils || []);
    const allUnionCouncils = preprocessLocationData(filteredUnionCouncils || []);
    const allVillages = preprocessLocationData(filteredVillages || []);
    const allProjects = preprocessData(getProjects || []);
    const allPartners = preprocessData(getPartners || []);

    useEffect(() => {
        getCountriesRefetch();
        getStatesRefetch();
        getCitiesRefetch();
        getDistrictsRefetch();
        getTehsilsRefetch();
        getUnionCouncilsRefetch();
        getVillagesRefetch();
        getPartnersRefetch();
        getProjectsRefetch();
    }, []);

    const validateCNIC = (cnic) => {
        const cnicRegex = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;
        return cnicRegex.test(cnic);
    };

    const handleCnicChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, "");
        if (value.length > 5) value = value.slice(0, 5) + '-' + value.slice(5);
        if (value.length > 13) value = value.slice(0, 13) + '-' + value.slice(13);
        setCnic(value);

        if (validateCNIC(value)) {
            setError("");
        } else {
            setError("Invalid CNIC format. Please use XXXXX-XXXXXXX-X.");
        }
    };

    const handleChange = (selectedOption, { name }) => {
        switch (name) {
            case 'partner':
                setSelectedPartner(selectedOption);
                break;
            case 'project':
                setSelectedProject(selectedOption);
                break;
            default:
                break;
        }
    };

    const addAvatarApiHandler = (file) => {

        let formData = new FormData();

        formData.append('avatar', file);

        uploadTeacherAvatar({ data: formData })
            .unwrap()
            .then((payload) => {
                setFileName(payload?.fileName);
                toast.success("Image uploaded successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addQualification = (newData) => {
        setTeacherQualification([...teacherQualification, newData]);
        addTeacherQualificationModalHandler();
    };

    const addExperience = (newData) => {
        setTeacherExperience([...teacherExperience, newData]);
        addTeacherExpModalHandler();
    };

    const addTraining = (newData) => {
        setTeacherTraining([...teacherTraining, newData]);
        addTeacherTrainingModalHandler();
    };

    useEffect(() => {
        if (propsData) {
            let { name, telephoneNo, cnic, citizenship, relationToGuardian, gender, maritalStatus, teacherType, documentVerified, hasAndroidPhone, preferredNetworkConnection, classRoomAvailability, classRoomSize, guardianName, address, schoolGPSCoordinates, bank, branchCode, branchName, accountNo, salary, dateOfBirth, joiningDate, salaryPaidUpToMonth, qualifications, teachingExperience, trainings } = propsData;

            let dateBirth = moment(dateOfBirth).format("YYYY-MM-DD");
            let joinDate = moment(joiningDate).format("YYYY-MM-DD");
            let salaryPaidMonth = moment(salaryPaidUpToMonth).format("YYYY-MM-DD");

            reset({ name: name, telephoneNo: telephoneNo, guardianName: guardianName, address: address, schoolGPSCoordinates: schoolGPSCoordinates, branchCode: branchCode, branchName: branchName, accountNo: accountNo, salary: salary, dateOfBirth: dateBirth, joiningDate: joinDate, salaryPaidUpToMonth: salaryPaidMonth });

            setCnic(cnic);
            setCitizenship(citizenship);
            setGuardianRelation(relationToGuardian);
            setGender(gender);
            setMaritalStatus(maritalStatus);
            setTeacherType(teacherType);
            setDocumentVerified(documentVerified);
            setAndroidPhone(hasAndroidPhone);
            setNetwork(preferredNetworkConnection);
            setClassAvailability(classRoomAvailability);
            setClassSize(classRoomSize);
            setBankName(bank);
            setTeacherQualification(qualifications);
            setTeacherExperience(teachingExperience);
            setTeacherTraining(trainings);
        }
    }, [propsData]);

    return (
        <div className="row">
            <div className="col-12">
                <h1>Edit Teacher</h1>

                <div className="row mt-4">
                    <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                        <div style={{ width: "10rem", height: "10rem" }}>
                            <ImageViewer
                                src={avatar || BASE_URL_IMAGES + propsData?.avatar}
                                alt=''
                                className='border img-fluid rounded-circle h-100'
                            />
                        </div>

                        <div className='position-relative m-4'>
                            <input type="file" className="opacity-0 uploadImgBtn" name='avatar'
                                accept="image/jpeg, image/png, image/jpg" onChange={uploadImgHandler} />
                            <p className="my-3 fs-3">Upload
                                Image</p>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Country</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allCountries}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="country"
                                placeholder={propsData?.country?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>State</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allStates}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="state"
                                placeholder={propsData?.state?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>City</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allCities}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="city"
                                placeholder={propsData?.city?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>District</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allDistricts}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="district"
                                placeholder={propsData?.district?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Tehsil</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allTehsils}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="tehsil"
                                placeholder={propsData?.tehsil?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Union Council</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allUnionCouncils}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="unionCouncil"
                                placeholder={propsData?.unionCouncil?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Village</label>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allVillages}
                                onChange={handleLocationChange}
                                className='w-100 me-3'
                                name="village"
                                placeholder={propsData?.village?.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Teacher Name</label>
                        <input type="text" className="themeInput fs-4" placeholder='Teacher Name' {...register("name")} />

                        {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Phone No.</label>
                        <input type="text" className="themeInput fs-4" placeholder='Teacher Name' {...register("telephoneNo")} />

                        {errors.telephoneNo && <p className='errorMsg'>{errors.telephoneNo.message}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Citizenship</label>
                        <select className='themeInput w-100' value={citizenship} onChange={(e) => setCitizenship(e.target.value)}>
                            <option value="">Select Citizenship</option>
                            <option value="Afghan">Afghan</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Burmese">Burmese</option>
                            <option value="Kashmiries">Kashmiries</option>
                            <option value="Pakistani">Pakistani</option>
                            <option value="SriLankan">Sri Lankan</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Relation to Guardian</label>
                        <select className='themeInput w-100' value={guardianRelation} onChange={(e) => setGuardianRelation(e.target.value)}>
                            <option value="">Select Relation</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Husband">Husband</option>
                            <option value="Wife">Wife</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Niece">Niece</option>
                            <option value="Nephew">Nephew</option>
                            <option value="Grand Child M">Grand Child Male</option>
                            <option value="Grand Child F">Grand Child Female</option>
                            <option value="Guardian">Guardian</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Gender</label>
                        <select className='themeInput w-100' value={gender} onChange={(e) => setGender(e.target.value)}>
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Marital Status</label>
                        <select className='themeInput w-100' value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}>
                            <option value="">Marital Status</option>
                            <option value="Married">Married</option>
                            <option value="Un Married">Un Married</option>
                            <option value="Widow">Widow</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Teacher Type</label>
                        <select className='themeInput w-100' value={teacherType} onChange={(e) => setTeacherType(e.target.value)}>
                            <option value="">Teacher Type</option>
                            <option value="Technical">Technical</option>
                            <option value="Subject">Subject</option>
                            <option value="Potential">Potential</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Document Verified</label>
                        <select className='themeInput w-100' value={documentVerified} onChange={(e) => setDocumentVerified(e.target.value)}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Have Andriod Phone?</label>
                        <select className='themeInput w-100' value={androidPhone} onChange={(e) => setAndroidPhone(e.target.value)}>
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Preferred Network Connection</label>
                        <select className='themeInput w-100' value={network} onChange={(e) => setNetwork(e.target.value)}>
                            <option value="">Select Network</option>
                            <option value="Moblink">Moblink</option>
                            <option value="Telenor">Telenor</option>
                            <option value="Ufone">Ufone</option>
                            <option value="Zong">Zong</option>
                            <option value="Jazz">Jazz</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Availability of Class Room</label>
                        <select className='themeInput w-100' value={classAvailability} onChange={(e) => setClassAvailability(e.target.value)}>
                            <option value="">Select</option>
                            <option value="Available">Available</option>
                            <option value="Not Available">Not Available</option>
                        </select>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Size of Class Room</label>
                        <select className='themeInput w-100' value={classSize} onChange={(e) => setClassSize(e.target.value)}>
                            <option value="">Select</option>
                            <option value="Less than 150Sqft">Less than 150Sqft</option>
                            <option value="Equal to 150Sqft">Equal to 150Sqft</option>
                            <option value="More than 150Sqft">More than 150Sqft</option>
                        </select>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">CNIC</label>

                        <input type="text" className="themeInput fs-4" value={cnic} onChange={handleCnicChange} placeholder="XXXXX-XXXXXXX-X" />
                        {error && <p className="errorMsg">{error}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">DOB</label>
                        <input type="date" className="themeInput fs-4" {...register("dateOfBirth")} />

                        {errors.dateOfBirth && <p className='errorMsg'>{errors.dateOfBirth.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Joining Data</label>
                        <input type="date" className="themeInput fs-4" {...register("joiningDate")} />

                        {errors.joiningDate && <p className='errorMsg'>{errors.joiningDate.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Guardian Name</label>
                        <input type="text" className="themeInput fs-4" placeholder='Guardian Name' {...register("guardianName")} />

                        {errors.guardianName && <p className='errorMsg'>{errors.guardianName.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Address</label>
                        <input type="text" className="themeInput fs-4" placeholder='Address' {...register("address")} />

                        {errors.address && <p className='errorMsg'>{errors.address.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">School GPS Coordinates</label>
                        <input type="text" className="themeInput fs-4" placeholder='40.7128,-74.0060' {...register("schoolGPSCoordinates")} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Project</label>
                        <Select
                            options={allProjects}
                            onChange={handleChange}
                            className='w-100 me-3'
                            name="project"
                            placeholder={propsData?.project?.name}
                        />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className='fs-4'>Partner</label>

                        <div className='d-flex align-items-center justify-content-between'>
                            <Select
                                options={allPartners}
                                onChange={handleChange}
                                className='w-100 me-3'
                                name="partner"
                                placeholder={propsData?.partner?.name}
                            />
                        </div>

                        {errors.partner && <p className='errorMsg'>{errors.partner.message}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                        <label className="fs-4">Bank Name</label>
                        <select className='themeInput w-100' value={bankName} onChange={(e) => setBankName(e.target.value)}>
                            <option value="">Select</option>
                            <option value="Al Barak Bank Ltd">Al Barak Bank Ltd</option>
                            <option value="Alfalah Bank Limited">Alfalah Bank Limited</option>
                            <option value="Allied Bank Limited">Allied Bank Limited</option>
                            <option value="Askari Bank Ltd">Askari Bank Ltd</option>
                            <option value="Bank Al Habib">Bank Al Habib</option>
                            <option value="Bank Islami Limited">Bank Islami Limited</option>
                            <option value="Faysal Bank Limited">Faysal Bank Limited</option>
                            <option value="First Women Bank Limited">First Women Bank Limited</option>
                            <option value="Habib Bank Limited">Habib Bank Limited</option>
                            <option value="Habib Metropolitan Bank Limited">Habib Metropolitan Bank Limited</option>
                            <option value="JS Bank Limited">JS Bank Limited</option>
                            <option value="Khushhali Bank Limited">Khushhali Bank Limited</option>
                            <option value="MCB Islamic Bank Limited">MCB Islamic Bank Limited</option>
                            <option value="Meezan Bank Limited">Meezan Bank Limited</option>
                            <option value="Mobilink Microfinance Bank Limited">Mobilink Microfinance Bank Limited</option>
                            <option value="Muslim Commercial Bank Limited">Muslim Commercial Bank Limited</option>
                            {/* <option value="22">N/A</option> */}
                            <option value="National Bank of Pakistan">National Bank of Pakistan</option>
                            <option value="NIB Bank Limited">NIB Bank Limited</option>
                            <option value="NRSP Micro Finance Bank Limited">NRSP Micro Finance Bank Limited</option>
                            <option value="Punjab Bank Limited">Punjab Bank Limited</option>
                            <option value="Sindh Bank">Sindh Bank</option>
                            <option value="Soneri Bank Limited">Soneri Bank Limited</option>
                            <option value="Standard Charterd Bank">Standard Charterd Bank</option>
                            <option value="Summit Bank Limited">Summit Bank Limited</option>
                            <option value="Telenor Microfinance Bank">Telenor Microfinance Bank</option>
                            <option value="The Bank of Khyber">The Bank of Khyber</option>
                            <option value="U Micro Finance Bank Limited">U Micro Finance Bank Limited</option>
                            <option value="United Bank Limited">United Bank Limited</option>
                            <option value="Zarai Taraqiati Bank Limited">Zarai Taraqiati Bank Limited</option>
                        </select>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Branch Code</label>
                        <input type="text" className="themeInput fs-4" placeholder='Branch Code' {...register("branchCode")} />

                        {errors.branchCode && <p className='errorMsg'>{errors.branchCode.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Account No.</label>
                        <input type="text" className="themeInput fs-4" placeholder='Account No.' {...register("accountNo")} />

                        {errors.accountNo && <p className='errorMsg'>{errors.accountNo.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Branch Name</label>
                        <input type="text" className="themeInput fs-4" placeholder='Branch Name' {...register("branchName")} />

                        {errors.branchName && <p className='errorMsg'>{errors.branchName.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Salary</label>
                        <input type="number" className="themeInput fs-4" placeholder='Salary' {...register("salary")} />

                        {errors.salary && <p className='errorMsg'>{errors.salary.message}</p>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Salary Paid Upto Month</label>
                        <input type="date" className="themeInput fs-4" {...register("salaryPaidUpToMonth")} />

                        {errors.salaryPaidUpToMonth && <p className='errorMsg'>{errors.salaryPaidUpToMonth.message}</p>}
                    </div>

                </div>

                <div className='row'>
                    <div className='col-12 text-end'>
                        <Button
                            label="+ Add Qualification"
                            className="themeBtn"
                            onClick={addTeacherQualificationModalHandler}
                        />
                    </div>

                    <div className="col-12 formTable">
                        <Table className="no-wrap mt-5 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    <th>Qualification Level</th>
                                    <th>Discipline</th>
                                    <th>Type</th>
                                    <th>Passing Year</th>
                                    <th>Obtained Marks</th>
                                    <th>Total Marks</th>
                                    <th>Grade</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherQualification?.length ? teacherQualification?.map((data, index) => {
                                    return (
                                        <tr className="border-top mainDiv cursor" key={index}>
                                            <td>{data?.qualificationLevel}</td>
                                            <td>{data?.discipline}</td>
                                            <td>{data?.passingDate}</td>
                                            <td>{data?.duration}</td>
                                            <td>{data?.obtainedMarks}</td>
                                            <td>{data?.totalMarks}</td>
                                            <td>{data?.grade}</td>
                                            {/* <td onClick={(e) => e.stopPropagation()}>
                                                <div className='d-flex align-items-center gap-3'>
                                                    <img src={edit} alt="" className='actionIcons' title='Edit' />
                                                    <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={7}>
                                        <h2 className='text-center mt-5'>No Record Found</h2>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-12 text-end'>
                        <Button
                            label="+ Add Experience"
                            className="themeBtn"
                            onClick={addTeacherExpModalHandler}
                        />
                    </div>

                    <div className="col-12 formTable">
                        <Table className="no-wrap mt-5 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    <th>Organization</th>
                                    <th>Designation</th>
                                    <th>Date From</th>
                                    <th>Date To</th>
                                    <th>Is Current Job ?</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherExperience?.length ? teacherExperience?.map((data, index) => {
                                    return (
                                        <tr className="border-top mainDiv cursor" key={index}>
                                            <td>{data?.organization}</td>
                                            <td>{data?.designation}</td>
                                            <td>{data?.dateFrom}</td>
                                            <td>{data?.dateTo}</td>
                                            <td>{data?.isCurrentJob == true ? "Yes" : "No"}</td>
                                            {/* <td onClick={(e) => e.stopPropagation()}>
                                                <div className='d-flex align-items-center gap-3'>
                                                    <img src={edit} alt="" className='actionIcons' title='Edit' />
                                                    <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={7}>
                                        <h2 className='text-center mt-5'>No Record Found</h2>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-12 text-end'>
                        <Button
                            label="+ Add Training"
                            className="themeBtn"
                            onClick={addTeacherTrainingModalHandler}
                        />
                    </div>

                    <div className="col-12 formTable">
                        <Table className="no-wrap mt-5 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Duration</th>
                                    <th>Date From</th>
                                    <th>Date To</th>
                                    <th>Provider</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherTraining?.length ? teacherTraining?.map((data, index) => {
                                    return (
                                        <tr className="border-top mainDiv cursor" key={index}>
                                            <td>{data?.title}</td>
                                            <td>{data?.duration}</td>
                                            <td>{data?.dateFrom}</td>
                                            <td>{data?.dateTo}</td>
                                            <td>{data?.provider}</td>
                                            {/* <td onClick={(e) => e.stopPropagation()}>
                                                <div className='d-flex align-items-center gap-3'>
                                                    <img src={edit} alt="" className='actionIcons' title='Edit' />
                                                    <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={7}>
                                        <h2 className='text-center mt-5'>No Record Found</h2>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="text-center">
                    <Button
                        label="Submit"
                        className="mt-5 themeBtn w-25"
                        loading={editTeacherLoading}
                        onClick={handleSubmit(onSubmit)}
                    />
                </div>

                {addTeacherQualificationModal &&
                    <AddTeacherQualificationModal
                        handleCloseAddTeacherQualificationModal={addTeacherQualificationModalHandler}
                        qualificationData={addQualification}
                    />
                }

                {addTeacherExpModal &&
                    <AddTeacherExperienceModal
                        handleCloseAddTeacherExperienceModal={addTeacherExpModalHandler}
                        experienceData={addExperience}
                    />
                }

                {addTeacherTrainingModal &&
                    <AddTeacherTrainingModal
                        handleCloseAddTeacherTrainingModal={addTeacherTrainingModalHandler}
                        trainingData={addTraining}
                    />
                }
            </div>
        </div>
    );
};

export default EditTeacher;