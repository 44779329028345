import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAddPartnersMutation, useGetCountriesQuery } from "../../services/Api";
import { toast } from "react-toastify";
import Select from 'react-select';

const AddPartnerModal = (props) => {

    const [sectors, setSectors] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryName, setCountryName] = useState('');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        address: Yup.string().required("Address is required"),
        phone: Yup.string().required("Phone is required"),
        email: Yup.string().required('Email is required').email('Email is invalid'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const updateName = (type, value) => {
        const allEntities = {
            country: allCountries,
        };

        const setNameFunctions = {
            country: setCountryName,
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.value == value);
            if (entity) {
                setName(entity);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        updateName('country', props?.data?.country);
    }, [getCountries]);

    const allCountries = preprocessLocationData(getCountries || []);

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            default:
                break;
        }
    };

    const [addPartners, { isLoading: addPartnersLoading, isSuccess: addPartnersSuccess }] = useAddPartnersMutation();

    const onSubmit = (values) => {

        const data = {
            name: values?.name,
            address: values?.address,
            industry: sectors,
            type: values?.type,
            phone: values?.phone,
            email: values?.email,
            country: selectedCountry?.value || countryName?.value,
        };

        const dataWithId = {
            name: values?.name,
            address: values?.address,
            industry: sectors,
            type: values?.type,
            phone: values?.phone,
            email: values?.email,
            country: selectedCountry?.value || countryName?.value,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    const onSubmitFromOtherPage = (values) => {

        const data = {
            name: values?.name,
            address: values?.address,
            industry: values?.industry,
            type: values?.type,
        };

        addPartners({ data: data })
            .unwrap()
            .then(() => {
                props.handleCloseAddPartnerModal();
                toast.success("Partner added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        if (props?.data) {
            let { name, address, industry, type } = props?.data;
            reset({ name: name, address: address, industry: industry, type: type });
        }
    }, [props?.data]);

    useEffect(() => {
        getCountriesRefetch();
    }, []);

    useEffect(() => {
        if (addPartnersSuccess) {
            props?.success(true);
        }
    }, [addPartnersSuccess]);

    const sectorOptions = [
        { value: "Education", label: "Education" },
        { value: "Health and Nutrition", label: "Health and Nutrition" },
        { value: "Infrastructure and Engineering", label: "Infrastructure and Engineering" },
        { value: "Climate Change and Environment", label: "Climate Change and Environment" },
        { value: "Water, Sanitation & Waste Management", label: "Water, Sanitation & Waste Management" },
        { value: "Monitoring, Evaluation and Learning", label: "Monitoring, Evaluation and Learning" },
        { value: "ICT Based Survey & Research/Digitization", label: "ICT Based Survey & Research/Digitization" },
        { value: "Agriculture & Rural development", label: "Agriculture & Rural development" },
        { value: "Supplies, contract & Procurement", label: "Supplies, contract & Procurement" },
        { value: "Advocacy & Strategy", label: "Advocacy & Strategy" },
        { value: "Energy", label: "Energy" },
        { value: "Humanterian Assistance", label: "Humanterian Assistance" },
        { value: "Crisis and Peacebuilding", label: "Crisis and Peacebuilding" },
        { value: "Democracy & Governance", label: "Democracy & Governance" },
        { value: "International Development", label: "International Development" },
        { value: "Human Resource Development", label: "Human Resource Development" },
    ];

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddPartnerModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Partner</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Partner</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Name' {...register("name")} disabled={props?.view ? true : false} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Sector</label>
                                <select className="themeInput fs-4" onChange={(e) => setSectors(e.target.value)} value={sectors} disabled={props?.view ? true : false}>
                                    <option value="">{props?.data ? props?.data?.industry : "Select sector"}</option>
                                    {sectorOptions?.map((data, index) => {
                                        return (
                                            <option value={data?.value} key={index}>{data?.label}</option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Type</label>
                                <input type="text" className="themeInput fs-4" placeholder='Type' {...register("type")} disabled={props?.view ? true : false} />

                                {errors.type && <p className='errorMsg'>{errors.type.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Country</label>
                                <Select
                                    options={allCountries}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="country"
                                    placeholder={props?.data ? props?.data?.country?.name : "Select"}
                                    isDisabled={props?.view ? true : false}
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Phone</label>
                                <input type="text" className="themeInput fs-4" placeholder='Phone' {...register("phone")} disabled={props?.view ? true : false} />

                                {errors.phone && <p className='errorMsg'>{errors.phone.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email</label>
                                <input type="text" className="themeInput fs-4" placeholder='Email' {...register("email")} disabled={props?.view ? true : false} />

                                {errors.email && <p className='errorMsg'>{errors.email.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Address</label>
                                <input type="text" className="themeInput fs-4" placeholder='Address' {...register("address")} disabled={props?.view ? true : false} />

                                {errors.address && <p className='errorMsg'>{errors.address.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading || addPartnersLoading}
                                    onClick={handleSubmit(props?.isOpenCondition ? onSubmitFromOtherPage : onSubmit)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddPartnerModal;