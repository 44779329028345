import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
    useAddQuestionnairesMutation,
    useDeleteQuestionnairesMutation,
    useEditQuestionnairesMutation,
    useGetQuestionnairesQuery
} from '../../services/Api';
import PaginationComponent from '../../components/pagination/Pagination';
import { del, dots, edit, loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from '../../components/allModals/DeleteModal';
import { toast } from 'react-toastify';
import AddQuestionnaireModal from '../../components/allModals/AddQuestionnaireModal';
import { Link, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';

const Questionnaires = () => {

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [viewQuestionnaireModal, setViewQuestionnaireModal] = useState(false);
    const [addQuestionnaireModal, setAddQuestionnaireModal] = useState(false);
    const [editQuestionnaireModal, setEditQuestionnaireModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewQuestionnaireData, setViewQuestionnaireData] = useState('');

    const { data: getQuestionnaires, refetch: getQuestionnairesRefetch, isLoading: getQuestionnairesLoading } = useGetQuestionnairesQuery();

    const itemsPerPage = 20;
    const totalItems = getQuestionnaires?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getQuestionnaires?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const viewQuestionnairesModalHandler = (data) => {
        setViewQuestionnaireModal((prev) => !prev);
        setViewQuestionnaireData(data);
    };

    const addQuestionnairesModalHandler = () => {
        setAddQuestionnaireModal((prev) => !prev);
    };

    const editQuestionnairesModalHandler = (data) => {
        setEditQuestionnaireModal((prev) => !prev);
        setViewQuestionnaireData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewQuestionnaireData(data);
    };

    const [addQuestionnaires, { isLoading: addQuestionnairesLoading }] = useAddQuestionnairesMutation();
    const [editQuestionnaires, { isLoading: editQuestionnairesLoading }] = useEditQuestionnairesMutation();
    const [deleteQuestionnaires, { isLoading: deleteQuestionnairesLoading }] = useDeleteQuestionnairesMutation();

    const addQuestionnaireApiHandler = (data) => {
        addQuestionnaires({ data: data })
            .unwrap()
            .then(() => {
                addQuestionnairesModalHandler();
                getQuestionnairesRefetch();
                toast.success('Questionnaire added successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editQuestionnairesApiHandler = (data) => {
        editQuestionnaires({ data: data })
            .unwrap()
            .then(() => {
                editQuestionnairesModalHandler();
                getQuestionnairesRefetch();
                toast.success('Questionnaire updated successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteQuestionnairesApiHandler = (id) => {
        deleteQuestionnaires({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getQuestionnairesRefetch();
                toast.success('Questionnaire deleted successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById('localSearchInput');
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName('mainDiv').length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById('noRecordsFound');

        for (var i = 0; i < length; i++) {
            if (document.getElementsByClassName('mainDiv')[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
                document.getElementsByClassName('mainDiv')[i].style.display = 'table-row';
                recordsFound = true;
            } else {
                document.getElementsByClassName('mainDiv')[i].style.display = 'none';
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = 'none';
        } else {
            noRecordsFoundMessage.style.display = 'block';
        }
    };

    useEffect(() => {
        getQuestionnairesRefetch();
    }, []);

    return (
        <>
            <div className="col-12">
                <h1 className="mb-4">Questionaires</h1>

                <div className="d-flex justify-content-between align-items-center">
                    <input
                        id="localSearchInput"
                        className="themeInput w-50"
                        placeholder="Search Questionnaires"
                        type="search"
                        autoComplete="off"
                        onChange={(e) => localSearchTableFunction(e.target.value)}
                    />

                    {/* <Button
                        label="+ Add Questionnaire"
                        className="themeBtn"
                        onClick={addQuestionnairesModalHandler}
                    /> */}
                    {/* <Link to={PATHS.addQuestionnaires}> */}
                    <Button label="+ Add Questionnaire" className="themeBtn" onClick={() => navigate('/addQuestionnaires')} />
                    {/* </Link> */}
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length ? (
                            paginatedData?.map((data, index) => {
                                return (
                                    <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.viewSolvedAssesment, { state: { data: data, recordId: '', userId: '', assessmentId: '', viewOnly: true } })}>
                                        <td>{index + 1}</td>
                                        <td>{data?.title}</td>
                                        <td>{data?.description}</td>
                                        <td className="text-capitalize">{data?.status}</td>
                                        <td onClick={(e) => e.stopPropagation()}>
                                            <div className='d-flex align-items-center gap-3'>
                                                <img src={edit} alt="" className='actionIcons' onClick={() => navigate(PATHS.editQuestionnaire, { state: { data: data } })} title='Edit' />
                                                {data?.status == "published" ? null :
                                                    <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                {getQuestionnairesLoading ? (
                                    <td colSpan={7}>
                                        <div className="text-center">
                                            <img src={loader} height={100} alt="" />
                                        </div>
                                    </td>
                                ) : (
                                    <td colSpan={7}>
                                        <h2 className="text-center mt-5">No Record Found</h2>
                                    </td>
                                )}
                            </tr>
                        )}

                        <tr>
                            <td colSpan={7}>
                                <h2 className="text-center mt-5" id="noRecordsFound" style={{ display: 'none' }}>
                                    No Record Found
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {addQuestionnaireModal && (
                    <AddQuestionnaireModal
                        handleCloseAddQuestionnaireModal={addQuestionnairesModalHandler}
                        action={addQuestionnaireApiHandler}
                        loading={addQuestionnairesLoading}
                    />
                )}

                {editQuestionnaireModal && (
                    <AddQuestionnaireModal
                        handleCloseAddQuestionnaireModal={editQuestionnairesModalHandler}
                        action={editQuestionnairesApiHandler}
                        loading={editQuestionnairesLoading}
                        data={viewQuestionnaireData}
                    />
                )}

                {viewQuestionnaireModal && (
                    <AddQuestionnaireModal
                        handleCloseAddQuestionnaireModal={viewQuestionnairesModalHandler}
                        data={viewQuestionnaireData}
                        view={true}
                    />
                )}

                {deleteModal && (
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Questionnaire"
                        id={viewQuestionnaireData?._id}
                        action={deleteQuestionnairesApiHandler}
                        loading={deleteQuestionnairesLoading}
                    />
                )}
            </div >
        </>
    );
};

export default Questionnaires;
