import React from 'react';

const CheckboxQuestion = ({ question, handleChange, disabled }) => {

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;
        handleChange(question.id, value, checked, 'checkbox');
    };

    return (
        <>
            <h3 className='fs-2 mt-4'>{question?.text}</h3>
            <div className='d-flex align-items-center gap-5'>
                {question?.options?.map(option => (
                    <div className='d-flex align-items-center' key={option}>
                        <input
                            type="checkbox"
                            id={option}
                            name={question?.id}
                            value={option}
                            onChange={handleCheckboxChange}
                            disabled={disabled}
                        />
                        <label className='fs-4 ms-2' htmlFor={option}>{option}</label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CheckboxQuestion;