import { Button, Nav, NavItem, UncontrolledCollapse } from 'reactstrap';
import Logo from './Logo';
import { NavLink } from 'react-router-dom';
import PATHS from '../routes/Paths';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const Sidebar = () => {

    const [isTestPagesOpen, setIsTestPagesOpen] = useState(false);

    const toggleTestPages = () => {
        setIsTestPagesOpen(!isTestPagesOpen);
    };

    const navigation = [
        {
            title: 'Dashboard',
            href: PATHS.dashboard,
            icon: 'bi bi-speedometer2'
        },
        {
            heading: 'Setup'
        },
        {
            title: 'Users',
            href: PATHS.users,
            icon: 'bi bi-people'
        },
        {
            title: 'Locations',
            href: PATHS.locations,
            icon: 'bi bi-geo-alt'
        },
        {
            title: 'Partners',
            href: PATHS.partners,
            icon: 'bi bi-person'
        },
        {
            title: 'Centers',
            href: PATHS.centers,
            icon: 'bi bi-building'
        },
        {
            title: 'Projects',
            href: PATHS.projects,
            icon: 'bi bi-briefcase'
        },
        {
            title: 'Teachers',
            href: PATHS.teachers,
            icon: 'bi bi-person'
        },
        {
            title: 'Email Templates',
            href: PATHS.templates,
            icon: 'bi bi-envelope'
        },
        {
            heading: 'Operations'
        },
        {
            title: 'Questionnaires',
            href: PATHS.questionnaires,
            icon: 'bi bi-list-check'
        },
        {
            title: 'Assessments',
            href: PATHS.assesments,
            icon: 'bi bi-clipboard-data'
        },
        {
            heading: 'Bulk Email Setup'
        },
        {
            title: 'Contact List',
            href: PATHS.contacts,
            icon: 'bi bi-file-earmark'
        },
        {
            title: 'Outgoing Emails',
            href: PATHS.contactsEmail,
            icon: 'bi bi-file-earmark'
        },
        {
            title: 'Email Template',
            href: PATHS.contactEmailTemplates,
            icon: 'bi bi-file-earmark'
        },
        {
            title: 'Sent Emails',
            href: PATHS.sentEmails,
            icon: 'bi bi-file-earmark'
        },
    ];

    const auth = useSelector((data) => data?.auth);
    const role = auth?.userDetail?.role;
    const permissions = auth?.userDetail?.permissions || [];

    const permissionMapping = {
        "dashboard": "Dashboard",
        "users": "Users",
        "locations": "Locations",
        "partners": "Partners",
        "centers": "Centers",
        "projects": "Projects",
        "templates": "Email Templates",
        "questionnaires": "Questionnaires",
        "assesments": "Assessments",
        "contactlist": "Contact List",
        "outgoingemails": "Outgoing Emails",
        "emailtemplate": "Email Template",
        "sentemails": "Sent Emails",
    };

    const updatedPermissions = permissions?.map(permission =>
        permissionMapping[permission] || permission
    );

    const filteredNavigation = role == 8989
        ? navigation
        : navigation.filter(navi => {
            if (navi.title) {
                return updatedPermissions.includes(navi.title);
            }
            return navi.heading;
        });

    const hasSetupTabs = filteredNavigation.some(navi => navi.title && navigation.slice(1, 7).includes(navi));
    const hasOperationsTabs = filteredNavigation.some(navi => navi.title && navigation.slice(8, 10).includes(navi));
    const hasBulkEmailTabs = filteredNavigation.some(navi => navi.title && navigation.slice(11, 14).includes(navi));

    const showMobilemenu = () => {
        document.getElementById('sidebarArea').classList.toggle('showSidebar');
    };

    return (
        <div className="p-3">
            <div className="d-flex align-items-center justify-content-center">
                <Logo />
                <span className="ms-auto d-lg-none">
                    <Button close size="sm" className="ms-auto d-lg-none" onClick={() => showMobilemenu()}></Button>
                </span>
            </div>

            <div className="pt-4 mt-2">
                <Nav vertical className="sidebarNav">
                    {filteredNavigation.map((navi, index) => {
                        if (navi.heading === 'Setup' && hasSetupTabs) {
                            return (
                                <div key={index} className="nav-heading mt-4 mb-4 px-3">
                                    <h3 className="text-warning">{navi.heading}</h3>
                                </div>
                            );
                        }
                        if (navi.heading === 'Operations' && hasOperationsTabs) {
                            return (
                                <div key={index} className="nav-heading mt-4 mb-4 px-3">
                                    <h3 className="text-warning">{navi.heading}</h3>
                                </div>
                            );
                        }
                        if (navi.heading === 'Bulk Email Setup' && hasBulkEmailTabs) {
                            return (
                                <div key={index} className="nav-heading mt-4 mb-4 px-3">
                                    <h3 className="text-warning">{navi.heading}</h3>
                                </div>
                            );
                        }
                        if (navi.title) {
                            return (
                                <NavItem key={index} className="sidenav-bg">
                                    <NavLink
                                        to={navi.href}
                                        className={({ isActive }) => (isActive ? 'active nav-link py-3' : 'nav-link py-3')}
                                    >
                                        <i className={navi.icon}></i>
                                        <span className="ms-3 d-inline-block">{navi.title}</span>
                                    </NavLink>
                                </NavItem>
                            );
                        }
                        return null;
                    })}

                    {role == 2003 && (
                        <NavItem className="sidenav-bg">
                            <NavLink
                                to={PATHS.assesmentsById}
                                className={({ isActive }) => (isActive ? 'active nav-link py-3' : 'nav-link py-3')}
                            >
                                <i className="bi bi-clipboard-data"></i>
                                <span className="ms-3 d-inline-block">Conductor Assessments</span>
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </div>
        </div>
    );
};

export default Sidebar;