import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextQuestion from "../questions/TextQuestion";

const AddOtherQuestionModal = (props) => {

    const [options, setOptions] = useState([{ id: Date.now(), value: '' }]);

    const validationSchema = Yup.object().shape({
        text: Yup.string().required("Question is required"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const addOptions = () => {
        setOptions([...options, { id: Date.now(), value: '' }]);
    };

    const removeOptions = (id) => {
        setOptions(options?.filter(option => option?.id !== id));
    };

    const handleChange = (id, value) => {
        setOptions(options.map(option =>
            option.id === id ? { ...option, value } : option
        ));
    };

    const onSubmit = (values) => {

        const optionsAdded = options?.map(option => option?.value);

        const data = {
            text: values?.text,
            type: props?.type,
            options: optionsAdded,
        };

        const dataWithId = {
            text: values?.text,
            type: props?.data?.type,
            options: optionsAdded,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    useEffect(() => {
        if (props?.data) {
            const { text, options: existingOptions } = props?.data;
            reset({ text: text });
            setOptions(existingOptions?.map(option => ({ id: Date.now() + Math.random(), value: option })));
        }
    }, [props?.data, reset]);

    return (
        <ModalComponent
            size="md"
            show={true}
            handleClose={props.handleCloseAddOtherQuestionModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View {props?.type || props?.data?.type} Question</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} {props?.type || props?.data?.type} Question</h1>
                        }
                        <div className="row mt-4 mb-5">
                            <div className="col-12">
                                <label className="fs-4 mb-3">Question</label>
                                <input type="text" className="themeInput fs-4" placeholder='Question' {...register("text")} disabled={props?.view ? true : false} />

                                {errors.text && <p className='errorMsg'>{errors.text.message}</p>}
                            </div>
                        </div>

                        {(!props?.view && props?.data?.type != "text") ?
                            <div className='d-flex align-items-center justify-content-end'>
                                <Button
                                    label="+ Add Options"
                                    className="themeBtn"
                                    onClick={addOptions}
                                />
                            </div>
                            : null}

                        {options?.map((option, index) => (
                            <div key={option.id} className='d-flex align-items-center mt-5 mb-5'>
                                <TextQuestion index={index} value={option.value} onChange={(value) => handleChange(option.id, value)} disabled={props?.view ? true : false} />
                                {!props?.view ?
                                    <span className='cursor fs-3' onClick={() => removeOptions(option?.id)}>❌</span>
                                    : null}
                            </div>
                        ))}

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="themeBtn"
                                    loading={props?.loading}
                                    onClick={handleSubmit(onSubmit)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default AddOtherQuestionModal;