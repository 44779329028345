const PATHS = {
    signin: '/signin',
    dashboard: '/dashboard',
    users: '/users',
    locations: '/locations',
    partners: '/partners',
    centers: '/centers',
    projects: '/projects',
    teachers: '/teachers',
    addTeacher: '/addTeacher',
    editTeacher: '/editTeacher',
    templates: '/emailTemplates',
    editEmailTemplates: '/editEmailTemplates',
    questions: '/questions',
    questionnaires: '/questionnaires',
    addQuestionnaires: '/addQuestionnaires',
    editQuestionnaire: '/editQuestionnaire',
    viewQuestionnaire: '/viewQuestionnaire',
    viewSolvedAssesment: '/viewSolvedAssesment',
    assesments: '/assesments',
    assesmentsForCouductor: '/assesmentsForCouductor',
    assesmentsById: '/assesmentsById',
    addAssesment: '/addAssesment',
    viewAssesment: '/viewAssesment',
    viewAssesmentForConductor: '/viewAssesmentForConductor',
    viewConductorAssesmentByAdmin: '/viewConductorAssesmentByAdmin',
    viewAssesmentDetailByAdmin: '/viewAssesmentDetailByAdmin',
    viewAssesmentForAdmin: '/viewAssesmentForAdmin',
    startAssesmentForConductor: '/startAssesmentForConductor',
    profile: '/profile',
    forgotPassword: '/forgotPassword',
    otp: '/otp',
    resetPassword: '/resetPassword',
    contacts: '/contacts',
    contactsEmail: '/contactsEmail',
    contactEmailTemplates: '/contactEmailTemplates',
    addContactEmailTemplates: '/addContactEmailTemplates',
    sentEmails: '/sentEmails',
    viewSentEmails: '/viewSentEmails',
};

export default PATHS;