import React, { useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as XLSX from "xlsx";
import { del, upload } from "../../assets/imagesPath";
import { Table } from "reactstrap";

const AddCSVFileModal = (props) => {

    const [csvFile, setCSVFile] = useState("");
    const [jsonData, setJsonData] = useState([]);
    const [headers, setHeaders] = useState([]);

    const handleCSVFileUpload = (file) => {

        setCSVFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX?.read(data, { type: "array" });
                const sheetName = workbook?.SheetNames[0];
                const worksheet = workbook?.Sheets[sheetName];
                const json = XLSX?.utils?.sheet_to_json(worksheet, { header: 1 });
                const headers = json[0];
                const rows = json.slice(1);
                setHeaders(headers);
                setJsonData(rows);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleCancelFile = () => {
        setCSVFile("");
        setJsonData([]);
        setHeaders([]);
    };

    const onSubmit = () => {

        let formData = new FormData();
        formData.append('file', csvFile);

        if (props?.type == "country") {
            props?.actionCountry(formData);
        } else if (props?.type == "state") {
            props?.actionState(formData);
        } else if (props?.type == "city") {
            props?.actionCity(formData);
        } else if (props?.type == "district") {
            props?.actionDistrict(formData);
        } else if (props?.type == "tehsil") {
            props?.actionTehsil(formData);
        } else if (props?.type == "uc") {
            props?.actionUc(formData);
        } else if (props?.type == "village") {
            props?.actionVillage(formData);
        }

    };

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddCSVFileModal}
        >
            <Modal.Body className="lgModals customScroll">

                <div className="row m-3">
                    <h1 className="text-center mb-4">Import CSV File</h1>

                    {!csvFile ?
                        <div className="col-12 csvUploadDiv pt-2 pb-2">
                            <center>
                                <img src={upload} alt="" className="uploadIcon" />
                                <input
                                    type="file"
                                    className="csvfile"
                                    accept=".xlsx, .xls, .csv"
                                    onChange={(e) => handleCSVFileUpload(e.target.files[0])}
                                />
                                <p className="csvtext">
                                    Drag and drop file
                                    <br />
                                    or
                                    <br />
                                    <span className="browse_file">
                                        Browse Files
                                    </span>
                                </p>
                            </center>
                        </div>
                        : null}
                </div>

                <div className="row">
                    <div className="col-12">
                        {csvFile ? (
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <div className="media">
                                        <div className="media-body ">
                                            <h5 className="mt-0 fs-2 mb-0">
                                                {csvFile?.name}
                                            </h5>
                                            <p className="fs-5 mb-0">
                                                {csvFile?.size}Kb
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-5">
                                        <img
                                            src={del}
                                            alt=""
                                            width="20px"
                                            height="20px"
                                            className="cursor"
                                            onClick={handleCancelFile}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 text-end">
                                    <div className="media">
                                        <div className="media-body ">
                                            <h5 className="mt-0 cardtitle mb-0 fs-2">
                                                {jsonData?.length}
                                            </h5>
                                            <p className="fs-5">
                                                Rows Will Be added
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {csvFile && jsonData?.length ? (
                            <Table className="no-wrap mt-5 align-middle" responsive borderless>
                                <thead>
                                    <tr>
                                        {headers?.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {jsonData?.map((row, rowIndex) => (
                                        <tr className="border-top mainDiv" key={rowIndex}>
                                            {row?.map((cell, cellIndex) => (
                                                <td key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}

                                    <tr>
                                        <td colSpan={headers?.length}>
                                            <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: jsonData?.length ? "none" : "block" }}>No Record Found</h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        ) : null}
                    </div>
                </div>

                {csvFile ?
                    <div className="text-center">
                        <Button
                            label="Submit"
                            className="mt-5 themeBtn"
                            loading={props?.loading}
                            onClick={onSubmit}
                        />
                    </div>
                    : null}
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddCSVFileModal;