import React from 'react';
import Subsection from './Subsection';
import Question from './Question';

const Section = ({ section, errors, responses, handleInputChange }) => {
    return (
        <div className='sectionDiv'>
            <h2 className='sectionHeader p-4'>{section?.title}</h2>

            <div className='ps-4 pe-4'>
                {section?.questions?.map((question) => {
                    return (
                        <Question
                            key={question?._id}
                            sectionId={section?._id}
                            question={question}
                            responses={responses}
                            errors={errors}
                            handleInputChange={handleInputChange}
                        />
                    )
                })}
            </div>

            {section?.subsections?.map(subsection => (
                <Subsection
                    key={subsection?._id}
                    sectionId={section?._id}
                    subsection={subsection}
                    responses={responses}
                    errors={errors}
                    handleInputChange={handleInputChange}
                />
            ))}
        </div>
    );
};

export default Section;