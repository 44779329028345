import React, { useEffect } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddTextQuestionModal = (props) => {

    const validationSchema = Yup.object().shape({
        text: Yup.string().required("Question is required"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            text: values?.text,
            type: "text",
        };

        const dataWithId = {
            text: values?.text,
            type: "text",
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    useEffect(() => {
        if (props?.data) {
            let { text } = props?.data;
            reset({ text: text });
        }
    }, [props?.data]);

    return (
        <ModalComponent
            size="md"
            show={true}
            handleClose={props.handleCloseAddTextQuestionModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Text Question</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Text Question</h1>
                        }
                        <div className="row mt-4 mb-5">
                            <div className="col-12">
                                <label className="fs-4 mb-3">Question</label>
                                <input type="text" className="themeInput fs-4" placeholder='Question' {...register("text")} disabled={props?.view ? true : false} />

                                {errors.text && <p className='errorMsg'>{errors.text.message}</p>}
                            </div>
                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="themeBtn"
                                    loading={props?.loading}
                                    onClick={handleSubmit(onSubmit)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default AddTextQuestionModal;