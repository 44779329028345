import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PATHS from './Paths';

import FullLayout from '../layouts/FullLayout.js';
import LoginPage from '../views/auth/Login.jsx';
import Dashboard from '../views/dashboard/Dashboard.jsx';
import Users from '../views/users/Users.jsx';
import Location from '../views/location/Location.jsx';
import Partners from '../views/partners/Partners.jsx';
import Centers from '../views/centers/Centers.jsx';
import Projects from '../views/projects/Projects.jsx';
import Questions from '../views/questions/Questions.jsx';
import Questionnaires from '../views/questions/Questionnaires.jsx';
import AddQuestionnaires from '../views/questions/AddQuestionnaires.jsx';
import Assesments from '../views/assesments/Assesments.jsx';
import ViewAssesment from '../views/assesments/ViewAssesment.jsx';
import Profile from '../views/profile/Profile.jsx';
import ForgotPassword from '../views/auth/ForgotPassword.jsx';
import Otp from '../views/auth/Otp.jsx';
import ResetPassword from '../views/auth/ResetPassword.jsx';
import EmailTemplates from '../views/emailTemplates/EmailTemplates.jsx';
import EditEmailTemplates from '../views/emailTemplates/EditEmailTemplates.jsx';
import EditQuestionnaire from '../views/questions/questionnaireForm/EditQuestionnaire.jsx';
import AddAssesment from '../views/assesments/AddAssesment.jsx';
import ViewAssesmentForConductor from '../views/assesments/ViewAssesmentForConductor.jsx';
import StartAssesmentForConductor from '../views/assesments/StartAssesmentForConductor.jsx';
import AssesmentsForCouductor from '../views/assesments/AssesmentsForCouductor.jsx';
import AssesmentsById from '../views/assesments/AssesmentsById.jsx';
import ViewQuestionnaire from '../views/questions/questionnaireForm/ViewQuestionnaire.jsx';
import ViewSolvedAssesment from '../views/assesments/ViewSolvedAssesment.jsx';
import ViewAssesmentForAdmin from '../views/assesments/adminAssesmentView/ViewAssesmentForAdmin.jsx';
import ViewConductorAssesmentByAdmin from '../views/assesments/adminAssesmentView/ViewConductorAssesmentByAdmin.jsx';
import ViewAssesmentDetailByAdmin from '../views/assesments/adminAssesmentView/ViewAssesmentDetailByAdmin.jsx';
import Teachers from '../views/teachers/Teachers.jsx';
import AddTeacher from '../views/teachers/AddTeacher.jsx';
import Contacts from '../views/settings/Contacts.jsx';
import EditTeacher from '../views/teachers/EditTeacher.jsx';
import ContactsEmail from '../views/settings/ContactsEmail.jsx';
import ContactEmailTemplates from '../views/settings/ContactEmailTemplates.jsx';
import AddContactEmailTemplates from '../views/settings/AddContactEmailTemplates.jsx';
import SentEmails from '../views/settings/SentEmails.jsx';
import ViewSentEmails from '../views/settings/VewSentEmails.jsx';

export default function Router() {
    const auth = useSelector((data) => data.auth);

    const router = createBrowserRouter([
        {
            path: '/',
            element: auth?.isLoggedIn && auth?.userDetail ? <FullLayout /> : <Navigate to={PATHS.signin} />
        },
        {
            path: '/signin',
            element: <LoginPage />
        },
        {
            path: '/',
            element: <FullLayout />,
            children: [
                { path: PATHS.dashboard, element: <Dashboard /> },
                { path: PATHS.users, element: <Users /> },
                { path: PATHS.locations, element: <Location /> },
                { path: PATHS.partners, element: <Partners /> },
                { path: PATHS.centers, element: <Centers /> },
                { path: PATHS.projects, element: <Projects /> },
                { path: PATHS.teachers, element: <Teachers /> },
                { path: PATHS.addTeacher, element: <AddTeacher /> },
                { path: PATHS.editTeacher, element: <EditTeacher /> },
                { path: PATHS.questions, element: <Questions /> },
                { path: PATHS.questionnaires, element: <Questionnaires /> },
                { path: PATHS.addQuestionnaires, element: <AddQuestionnaires /> },
                { path: PATHS.editQuestionnaire, element: <EditQuestionnaire /> },
                { path: PATHS.viewQuestionnaire, element: <ViewQuestionnaire /> },
                { path: PATHS.assesments, element: <Assesments /> },
                { path: PATHS.viewSolvedAssesment, element: <ViewSolvedAssesment /> },
                { path: PATHS.assesmentsForCouductor, element: <AssesmentsForCouductor /> },
                { path: PATHS.assesmentsById, element: <AssesmentsById /> },
                { path: PATHS.addAssesment, element: <AddAssesment /> },
                { path: PATHS.viewAssesment, element: <ViewAssesment /> },
                { path: PATHS.viewAssesmentForConductor, element: <ViewAssesmentForConductor /> },
                { path: PATHS.viewConductorAssesmentByAdmin, element: <ViewConductorAssesmentByAdmin /> },
                { path: PATHS.viewAssesmentDetailByAdmin, element: <ViewAssesmentDetailByAdmin /> },
                { path: PATHS.viewAssesmentForAdmin, element: <ViewAssesmentForAdmin /> },
                { path: PATHS.startAssesmentForConductor, element: <StartAssesmentForConductor /> },
                { path: PATHS.profile, element: <Profile /> },
                { path: PATHS.templates, element: <EmailTemplates /> },
                { path: PATHS.editEmailTemplates, element: <EditEmailTemplates /> },
                { path: PATHS.contacts, element: <Contacts /> },
                { path: PATHS.contactsEmail, element: <ContactsEmail /> },
                { path: PATHS.contactEmailTemplates, element: <ContactEmailTemplates /> },
                { path: PATHS.addContactEmailTemplates, element: <AddContactEmailTemplates /> },
                { path: PATHS.sentEmails, element: <SentEmails /> },
                { path: PATHS.viewSentEmails, element: <ViewSentEmails /> },
            ]
        },
        { path: PATHS.forgotPassword, element: <ForgotPassword /> },
        { path: PATHS.otp, element: <Otp /> },
        { path: PATHS.resetPassword, element: <ResetPassword /> },
        { path: '*', element: <Navigate to={PATHS.signin} /> }
    ]);

    return <RouterProvider router={router} />;
}
