import React from 'react';
import Question from './Question';

const Subsection = ({ sectionId,errors, subsection, responses, handleInputChange }) => {
    return (
        <div className='subSectionDiv'>
            <h3 className='sectionHeader p-4'>{subsection?.title}</h3>
            <div className='ps-4 pe-4'>
                {subsection?.questions?.map(question => (
                    <Question
                        key={question?._id}
                        sectionId={sectionId}
                        subsectionId={subsection?._id}
                        question={question}
                        errors={errors}
                        responses={responses}
                        handleInputChange={handleInputChange}
                    />
                ))}
            </div>
        </div>
    );
};

export default Subsection;