import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useAddContactsEmailMutation, useEditContactsEmailMutation, useDeleteContactsEmailMutation, useGetContactsEmailQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { active, deactive, del, dots, edit, loader, view } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import AddContactModal from '../../components/allModals/AddContactModal';
import AddContactsEmailModal from '../../components/allModals/AddContactsEmailModal';

const ContactsEmail = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [viewContactModal, setViewContactModal] = useState(false);
    const [addContactModal, setAddContactModal] = useState(false);
    const [editContactModal, setEditContactModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewContactData, setViewContactData] = useState("");

    const {
        data: getContactsEmail,
        refetch: getContactsEmailRefetch,
        isLoading: getContactsEmailLoading,
    } = useGetContactsEmailQuery();

    const itemsPerPage = 20;
    const totalItems = getContactsEmail?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getContactsEmail?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const viewContactsModalHandler = (data) => {
        setViewContactModal((prev) => !prev);
        setViewContactData(data);
    };

    const addContactsModalHandler = () => {
        setAddContactModal((prev) => !prev);
    };

    const editContactsModalHandler = (data) => {
        setEditContactModal((prev) => !prev);
        setViewContactData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewContactData(data);
    };

    const [addContactsEmail, { isLoading: addContactsEmailLoading }] = useAddContactsEmailMutation();
    const [editContactsEmail, { isLoading: editContactsEmailLoading }] = useEditContactsEmailMutation();
    const [deleteContactsEmail, { isLoading: deleteContactsEmailLoading }] = useDeleteContactsEmailMutation();

    const addContactApiHandler = (data) => {
        addContactsEmail({ data: data })
            .unwrap()
            .then(() => {
                addContactsModalHandler();
                getContactsEmailRefetch();
                toast.success("Contact added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editContactsApiHandler = (data) => {
        editContactsEmail({ data: data })
            .unwrap()
            .then(() => {
                editContactsModalHandler();
                getContactsEmailRefetch();
                toast.success("Contact updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteContactsApiHandler = (id) => {
        deleteContactsEmail({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getContactsEmailRefetch();
                toast.success("Contact deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    useEffect(() => {
        getContactsEmailRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Contacts</h1>

                <div className='row'>
                    <div className='col-md-3'>
                        <input
                            id='localSearchInput'
                            className='themeInput w-100'
                            placeholder="Search Contact"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => localSearchTableFunction(e.target.value)}
                        />
                    </div>
                    <div className='col-md-9 text-end'>
                        <Button
                            label="+ Add Contact Email"
                            className="themeBtn"
                            onClick={addContactsModalHandler}
                        />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Email Title</th>
                            <th>Email User</th>
                            <th>Host</th>
                            <th>Port</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length ? paginatedData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index} onClick={() => viewContactsModalHandler(data)}>
                                    <td>{index + 1}</td>
                                    <td>{data?.emailTitle}</td>
                                    <td>{data?.emailUser}</td>
                                    <td>{data?.host}</td>
                                    <td>{data?.port}</td>
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <div className='d-flex align-items-center gap-3'>
                                            <img src={view} alt="" className='actionIcons' onClick={() => viewContactsModalHandler(data)} title='View' />
                                            <img src={edit} alt="" className='actionIcons' onClick={() => editContactsModalHandler(data)} title='Edit' />
                                            <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={8}>
                                {getContactsEmailLoading ?
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                    : <h2 className='text-center mt-5'>No Record Found</h2>}
                            </td>
                        </tr>}

                        <tr>
                            <td colSpan={8}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {addContactModal &&
                    <AddContactsEmailModal
                        handleCloseAddContactsEmailModal={addContactsModalHandler}
                        action={addContactApiHandler}
                        loading={addContactsEmailLoading}
                    />
                }

                {editContactModal &&
                    <AddContactsEmailModal
                        handleCloseAddContactsEmailModal={editContactsModalHandler}
                        action={editContactsApiHandler}
                        loading={editContactsEmailLoading}
                        data={viewContactData}
                    />
                }

                {viewContactModal &&
                    <AddContactsEmailModal
                        handleCloseAddContactsEmailModal={viewContactsModalHandler}
                        data={viewContactData}
                        view={true}
                    />
                }

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Contact"
                        id={viewContactData?._id}
                        action={deleteContactsApiHandler}
                        loading={deleteContactsEmailLoading}
                    />
                }
            </div>
        </>
    )
}

export default ContactsEmail;