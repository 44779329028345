import React, { useEffect } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddCityModal = (props) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            name: values?.name,
            stateId: props?.state?.value,
        };

        const dataWithId = {
            name: values?.name,
            stateId: props?.state?.value,
            cityId: props?.data?.value,
        };

        if (props?.data) {
            props?.actionUpdate(dataWithId);
        } else {
            props?.action(data);
        }
    };

    useEffect(() => {
        if (props?.data) {
            let { label } = props?.data;
            reset({ name: label });
        }
    }, [props?.data]);

    return (
        <ModalComponent
            size="md"
            show={true}
            handleClose={props.handleCloseAddCityModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">{props?.data ? "Edit" : "Add"} City</h1>
                        <div className="row mt-4">
                            <div className="col-12">
                                <label className="fs-4 mb-3">Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='First Name' {...register("name")} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>
                        </div>

                        <div className="text-center">
                            <Button
                                label="Submit"
                                className="mt-5 themeBtn"
                                loading={props?.loading}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddCityModal;