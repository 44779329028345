import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './assets/scss/style.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import theme from './themes';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Suspense>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App />
                    <ToastContainer autoClose={2000} position="top-center" />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </Suspense>
);
