import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import { useGetCentersQuery, useGetCountriesQuery, useGetPartnersQuery, useGetUsersQuery } from "../../services/Api";
import Select from 'react-select';
import moment from "moment/moment";
import { add } from "../../assets/imagesPath";
import AddPartnerModal from "./AddPartnerModal";

const AddProjectModal = (props) => {

    const [name, setName] = useState("");
    const [partner, setPartner] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [status, setStatus] = useState('');
    const [endDate, setEndDate] = useState('');
    const [location, setLocation] = useState('');
    const [partnerName, setPartnerName] = useState('');
    const [centers, setCenters] = useState([]);
    const [team, setTeam] = useState([]);
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const [openPartnerModalCondition, setOpenPartnerModalCondition] = useState("");
    const [success, setSuccess] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [countryName, setCountryName] = useState('');
    const [userName, setUserName] = useState('');
    const [projectCode, setProjectCode] = useState('');

    const {
        data: getPartners,
        refetch: getPartnersRefetch,
    } = useGetPartnersQuery();

    // const {
    //     data: getCenters,
    //     refetch: getCentersRefetch,
    // } = useGetCentersQuery();

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const {
        data: getUsers,
        refetch: getUsersRefetch,
    } = useGetUsersQuery();

    const updateName = (type, value) => {
        const allEntities = {
            country: allCountries,
            pm: allUsers,
        };

        const setNameFunctions = {
            country: setCountryName,
            pm: setUserName,
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.value == value);
            if (entity) {
                setName(entity);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        updateName('country', props?.data?.country);
    }, [getCountries]);

    const addPartnersModalHandler = (status) => {
        setOpenPartnerModal((prev) => !prev);
        setOpenPartnerModalCondition(status);
    };

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    const onSubmit = () => {

        const data = {
            name: name,
            partner: partner?.value || partnerName?.value,
            startDate: startDate,
            endDate: endDate,
            location: location,
            centers: centers,
            country: selectedCountry?.value || countryName?.value,
            status: status,
            teamMembers: team,
            projectCode: projectCode,
            projectManager: selectedUser?.value || userName?.value,
        };

        const dataWithId = {
            name: name,
            partner: partner?.value || props?.data?.partner?.id,
            startDate: startDate,
            endDate: endDate,
            location: location,
            centers: centers?.length ? centers : props?.data?.centers,
            status: status,
            country: selectedCountry?.value || props?.data?.country?.id,
            id: props?.data?._id,
            teamMembers: team?.length ? team : props?.data?.teamMembers,
            projectCode: projectCode,
            projectManager: selectedUser?.value || props?.data?.projectManager?.id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    const validationHandler = () => {
        let noError = false;

        nameValidationHandler();
        locationValidationHandler();
        startDateValidationHandler();
        endDateValidationHandler();
        partnerValidationHandler();
        centersValidationHandler();
        statusValidationHandler();
        codeValidationHandler();
        teamValidationHandler();

        if (name && location && status && startDate && (team?.length || props?.data?.teamMembers) && endDate && projectCode && (partner || props?.data?.partner?.name)) {
            onSubmit();
        } else {
            return false;
        }

        return noError;
    };

    const nameValidationHandler = () => {
        if (!name) {
            setError("name", { type: "custom", message: "Name is required" });
        } else {
            clearErrors("name");
        }
    };

    const codeValidationHandler = () => {
        if (!projectCode) {
            setError("code", { type: "custom", message: "Project Code is required" });
        } else {
            clearErrors("code");
        }
    };

    const statusValidationHandler = () => {
        if (!status) {
            setError("status", { type: "custom", message: "Status is required" });
        } else {
            clearErrors("status");
        }
    };

    const locationValidationHandler = () => {
        if (!location) {
            setError("location", { type: "custom", message: "Location is required" });
        } else {
            clearErrors("location");
        }
    };

    const startDateValidationHandler = () => {
        if (!startDate) {
            setError("startDate", { type: "custom", message: "Start Date is required" });
        } else {
            clearErrors("startDate");
        }
    };

    const endDateValidationHandler = () => {
        if (!endDate) {
            setError("endDate", { type: "custom", message: "End Date is required" });
        } else {
            clearErrors("endDate");
        }
    };

    const partnerValidationHandler = () => {
        if (!partner && !props?.data?.partner?.name) {
            setError("partner", { type: "custom", message: "Partner is required" });
        } else {
            clearErrors("partner");
        }
    };

    const centersValidationHandler = () => {
        if (!centers) {
            setError("center", { type: "custom", message: "Center(s) is required" });
        } else {
            clearErrors("center");
        }
    };

    const teamValidationHandler = () => {
        if (!team) {
            setError("team", { type: "custom", message: "Team member(s) is required" });
        } else {
            clearErrors("team");
        }
    };

    const handleChange = (option) => {
        setPartner(option);
    };

    const handleCenterChange = (selectedOptions) => {
        const centers = selectedOptions?.map(option => option?.value);
        setCenters(centers);
    };

    const handleTeamChange = (selectedOptions) => {
        const team = selectedOptions?.map(option => option?.value);
        setTeam(team);
    };

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const preprocessUserData = (user) => {
        return user?.map(user => ({
            label: user?.firstName + " " + user?.lastName,
            value: user?._id,
        }));
    };

    const filterCentersByCountry = (country, centers) => {
        return centers?.filter(center => center?.country?.name == country);
    };

    const filteredCenters = filterCentersByCountry(selectedCountry?.label, props?.centers);

    const allPartners = preprocessLocationData(getPartners || []);
    const allCenters = preprocessLocationData(filteredCenters || []);
    const allCountries = preprocessLocationData(getCountries || []);
    const allUsers = preprocessUserData(props?.users || []);

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            case 'pm':
                setSelectedUser(selectedOption);
                break;
            default:
                break;
        }
    };

    const getNamesByIds = (ids, centers) => {
        return ids?.map(id => {
            const center = centers?.find(center => center?.value == id);
            return center ? center : null;
        });
    };

    const names = getNamesByIds(props?.data?.centers, allCenters);
    const teamMembers = getNamesByIds(props?.data?.teamMembers, allUsers);

    const handleSuccess = (status) => {
        setSuccess(status);
    };

    useEffect(() => {
        if (allPartners) {
            const partner = allPartners?.find(partner => partner?.value == props?.data?.partner);
            if (partner) {
                setPartnerName(partner);
            } else {
                setPartnerName('Partner not found');
            }
        }
    }, [getPartners]);

    useEffect(() => {
        if (props?.data) {
            let startDate = moment(props?.data?.startDate).format("YYYY-MM-DD");
            let endDate = moment(props?.data?.endDate).format("YYYY-MM-DD");
            setName(props?.data?.name);
            setStartDate(startDate);
            setEndDate(endDate);
            setLocation(props?.data?.location);
            setStatus(props?.data?.status);
            setProjectCode(props?.data?.projectCode);
        }
    }, [props?.data]);

    useEffect(() => {
        getPartnersRefetch();
        // getCentersRefetch();
        getCountriesRefetch();
        getUsersRefetch();
    }, []);

    useEffect(() => {
        if (success) {
            getPartnersRefetch();
        }
    }, [success]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddProjectModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Project</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Project</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Name' name="name" onChange={(e) => setName(e.target.value)} value={name} disabled={props?.view ? true : false} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Project Code</label>
                                <input type="text" className="themeInput fs-4" placeholder='Code' name="code" onChange={(e) => setProjectCode(e.target.value)} value={projectCode} disabled={props?.view ? true : false} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Partner</label>

                                <div className='d-flex align-items-center justify-content-between'>
                                    <Select
                                        options={allPartners}
                                        onChange={handleChange}
                                        className='w-100 me-3'
                                        name="partner"
                                        placeholder={props?.data ? props?.data?.partner?.name : "Select"}
                                        isDisabled={props?.view ? true : false}
                                    />

                                    <img src={add} alt="" className='actionIcons' onClick={() => addPartnersModalHandler(true)} />
                                </div>

                                {errors.partner && <p className='errorMsg'>{errors.partner.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Project Manager</label>
                                <Select
                                    options={allUsers}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="pm"
                                    placeholder={props?.data ? props?.data?.projectManager?.firstName + " " + props?.data?.projectManager?.lastName : "Select"}
                                    isDisabled={props?.view ? true : false}
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Team Members</label>
                                <Select
                                    options={allUsers}
                                    onChange={handleTeamChange}
                                    className='w-100 me-3'
                                    name="team"
                                    defaultValue={teamMembers}
                                    isDisabled={props?.view ? true : false}
                                    isMulti={true}
                                />

                                {errors.team && <p className='errorMsg'>{errors.team.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Start Date</label>
                                <input type="date" className="themeInput fs-4" name="startDate" onChange={(e) => setStartDate(e.target.value)} value={startDate} disabled={props?.view ? true : false} />

                                {errors.startDate && <p className='errorMsg'>{errors.startDate.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">End Date</label>
                                <input type="date" className="themeInput fs-4" placeholder='Name' name="endDate" onChange={(e) => setEndDate(e.target.value)} value={endDate} disabled={props?.view ? true : false} />

                                {errors.endDate && <p className='errorMsg'>{errors.endDate.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Country</label>
                                <Select
                                    options={allCountries}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="country"
                                    placeholder={props?.data ? props?.data?.country?.name : "Select"}
                                    isDisabled={props?.view ? true : false}
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Location</label>
                                <input type="text" className="themeInput fs-4" placeholder='Location' name="location" onChange={(e) => setLocation(e.target.value)} value={location} disabled={props?.view ? true : false} />

                                {errors.location && <p className='errorMsg'>{errors.location.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Center</label>
                                <Select
                                    options={allCenters}
                                    onChange={handleCenterChange}
                                    className='w-100 me-3'
                                    name="center"
                                    defaultValue={names}
                                    isDisabled={props?.view ? true : false}
                                    isMulti={true}
                                />

                                {errors.center && <p className='errorMsg'>{errors.center.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Status</label>
                                <select className="themeInput fs-4" name="status" onChange={(e) => setStatus(e.target.value)} value={status}>
                                    <option value="">Select status</option>
                                    <option value="in_progress">InProgress</option>
                                    <option value="completed">Completed</option>
                                    <option value="draft">Draft</option>
                                </select>

                                {errors.status && <p className='errorMsg'>{errors.status.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={validationHandler}
                                />
                            </div>
                            : null}

                        {openPartnerModal &&
                            <AddPartnerModal
                                handleCloseAddPartnerModal={addPartnersModalHandler}
                                isOpenCondition={openPartnerModalCondition}
                                success={handleSuccess}
                            />
                        }
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddProjectModal;