import AddIcon from '@mui/icons-material/Add';
import React, { memo, useState } from 'react';
import { Box, Grid, Paper, TextField, Typography, IconButton, Button, Collapse } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Question from './Question';
import Subsection from './Subsection';

function Section({ section, updateSection, deleteSection, viewOnly }) {
    const [isHovered, setIsHovered] = useState({ hovered: false, questionIndex: null });

    const handleAddQuestion = () => {
        const newQuestion = { _id: Date.now().toString(), text: '', type: 'text', required: false, options: [] };
        updateSection({
            ...section,
            questions: [...section?.questions, newQuestion]
        });
    };

    const handleAddSubsection = () => {
        const newSubsection = {
            _id: Date.now().toString(),
            title: '',
            questions: []
        };
        updateSection({
            ...section,
            subsections: [...section?.subsections, newSubsection]
        });
    };

    const handleDeleteQuestion = (questionId) => {
        updateSection({
            ...section,
            questions: section?.questions.filter((q) => q._id !== questionId)
        });
    };

    const handleDeleteSubsection = (subsectionId) => {
        updateSection({
            ...section,
            subsections: section?.subsections.filter((sub) => sub._id !== subsectionId)
        });
    };

    return (
        <Paper sx={{ mt: 2, p: 3 }}>
            <Grid container spacing={2}>
                <Grid item lg={11}>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Add section title"
                        value={section.title}
                        onChange={(e) => updateSection({ ...section, title: e.target.value })}
                    />
                </Grid>
                {viewOnly ? null :
                    <Grid item lg={1} container justifyContent="flex-end">
                        <IconButton onClick={() => deleteSection(section?._id)}>
                            <DeleteIcon sx={{ fontSize: 22, color: 'red' }} />
                        </IconButton>
                    </Grid>
                }
            </Grid>

            <Box>
                {section?.questions?.map((question, qIndex) => (
                    <>
                        <Box
                            onMouseEnter={() => setIsHovered({ hovered: true, questionIndex: qIndex })}
                            onMouseLeave={() => setIsHovered({ hovered: false, questionIndex: null })}
                            sx={{ width: 1, minHeight: '15px', cursor: 'pointer' }}
                        >
                            {viewOnly ? null :
                                <Collapse in={isHovered?.hovered == true && isHovered?.questionIndex == qIndex} timeout={500}>
                                    <Button
                                        onClick={() => {
                                            updateSection({
                                                ...section,
                                                questions: [
                                                    ...section?.questions?.slice(0, qIndex),
                                                    { _id: Date.now().toString(), text: '', type: 'text', required: false, options: [] },
                                                    ...section?.questions?.slice(qIndex)
                                                ]
                                            });
                                        }}
                                        fullWidth
                                    >
                                        Add new question
                                    </Button>
                                </Collapse>
                            }
                        </Box>

                        <Question
                            key={question._id}
                            question={question}
                            updateQuestion={(updatedQuestion) => {
                                updateSection({
                                    ...section,
                                    questions: section.questions.map((q) => (q._id === updatedQuestion._id ? updatedQuestion : q))
                                });
                            }}
                            deleteQuestion={handleDeleteQuestion}
                            viewOnly={viewOnly}
                        />
                    </>
                ))}
            </Box>

            <Box>
                {section?.subsections?.map((subsection, subIndex) => (
                    <>
                        <Subsection
                            key={subsection._id}
                            subsection={subsection}
                            updateSubsection={(updatedSubsection) => {
                                updateSection({
                                    ...section,
                                    subsections: section.subsections.map((sub) =>
                                        sub._id === updatedSubsection._id ? updatedSubsection : sub
                                    )
                                });
                            }}
                            deleteSubsection={handleDeleteSubsection}
                            boxShadow={3}
                            viewOnly={viewOnly}
                        />
                    </>
                ))}
            </Box>

            {viewOnly ? null :
                <Grid container spacing={3} pt={3} justifyContent={'center'}>
                    <Grid item lg={6}>
                        <CustomAddButton title={'Add new question'} onClick={handleAddQuestion} />
                    </Grid>

                    <Grid item lg={6}>
                        <CustomAddButton title={'Add new inner section'} onClick={handleAddSubsection} />
                    </Grid>
                </Grid>
            }
        </Paper>
    );
}

export default memo(Section);

const CustomAddButton = memo(({ title, onClick }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                p: 2.5,
                bgcolor: '#d9e3ff',
                borderRadius: 3,
                cursor: 'pointer'
            }}
        >
            <AddIcon sx={{ color: '#5770d0', fontSize: 22, mr: 1 }} />
            <Typography sx={{ color: '#5770d0', fontWeight: '500' }}>{title}</Typography>
        </Box>
    );
});
