import React, { useEffect, useState } from 'react';
import { useEmailTemplateMutation, useGetEmailTemplateByIDQuery } from '../../services/Api';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Button from "../../components/button/Button";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import { loader } from '../../assets/imagesPath';
window.jQuery = require("jquery");

const EditEmailTemplates = () => {

    const [subject, setSubject] = useState('');
    const location = useLocation();

    const [emailTemplate, { isLoading: emailTemplateLoading }] = useEmailTemplateMutation();

    const {
        data: getEmailTemplateByID,
        refetch: getEmailTemplateByIDRefetch,
        isLoading: getEmailTemplateByIDLoading,
    } = useGetEmailTemplateByIDQuery({ params: { id: location?.state?.data?.id } });

    const [content, setContent] = useState("");

    const emailTemplateApiHandler = () => {

        const data = {
            type: location?.state?.data?.type,
            subject: subject,
            html: content,
            id: location?.state?.data?.id,
        };

        emailTemplate({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Template updated successfully");
                getEmailTemplateByIDRefetch();
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        getEmailTemplateByIDRefetch();
    }, []);

    useEffect(() => {
        if (getEmailTemplateByID) {
            setSubject(getEmailTemplateByID?.subject);
            setContent(getEmailTemplateByID?.html);
        }
    }, [getEmailTemplateByID]);

    const handleChange = (content) => {
        setContent(content);
    };

    return (
        <>
            {getEmailTemplateByIDLoading ?
                <div className='text-center'>
                    <img src={loader} alt="" height={100} />
                </div>
                :
                <div className="row">

                    <div className="col-12 text-end mb-5">
                        <Button
                            label="Save"
                            className="themeBtn"
                            onClick={emailTemplateApiHandler}
                            loading={emailTemplateLoading}
                        />
                    </div>

                    <div className="col-12 mb-5">
                        <label className="fs-4 mb-3">Subject</label>
                        <input type="text" className="themeInput fs-4" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} value={subject} />
                    </div>

                    <div className="col-12">
                        <ReactSummernote
                            value={content}
                            options={{
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    // ['style', ['style']],
                                    ['font', ['bold', 'underline', 'clear']],
                                    // ['fontname', ['fontname']],
                                    ['para', ['ul', 'ol']],
                                    // ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen', 'codeview']]
                                ]
                            }}
                            onChange={handleChange}
                        />

                        {/* <h1 className='mt-5'>PREVIEW</h1>
                    <div
                        className="col-12 border border-dark"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div> */}
                    </div>
                </div>
            }
        </>
    )
}

export default EditEmailTemplates