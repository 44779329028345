import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { avatar, loader } from '../../../assets/imagesPath';
import Button from '../../../components/button/Button';
import { BASE_URL_FILES, BASE_URL_IMAGES } from '../../../services/ApiEndpoints';
import PATHS from '../../../routes/Paths';
import { useSelector } from 'react-redux';
import PaginationComponent from '../../../components/pagination/Pagination';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useGetCollectorStatusQuery, useViewAssesmentDetailQuery } from '../../../services/Api';

const ViewAssesmentForAdmin = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();
    const assesmentData = location?.state?.data;
    const assesmentDataRecordId = location?.state?.recordId;
    const userId = location?.state?.userId;
    const auth = useSelector((data) => data?.auth);
    const role = auth?.userDetail?.role;
    const newRecordData = location?.state?.newRecord;

    const {
        data: viewAssesmentDetail,
        refetch: viewAssesmentDetailRefetch,
        isLoading: viewAssesmentDetailLoading,
    } = useViewAssesmentDetailQuery({ params: { id: newRecordData?.assessment || assesmentData?.id } });

    const {
        data: getCollectorStatus,
        refetch: getCollectorStatusRefetch,
    } = useGetCollectorStatusQuery({ params: { id: assesmentData?.id } });

    const itemsPerPage = 20;
    const totalItems = viewAssesmentDetail?.users?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getCollectorStatus?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    let dueDate = moment(viewAssesmentDetail?.dueDate).format("YYYY-MM-DD, HH:mm A");

    useEffect(() => {
        viewAssesmentDetailRefetch();
        getCollectorStatusRefetch();
    }, []);

    return (
        <>
            {viewAssesmentDetailLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row'>

                    <div className="col-12 mb-5">
                        <div className="assesDiv">
                            <div className='d-flex align-items-start justify-content-between'>
                                <div>
                                    <h3>Title</h3>
                                    <p>{viewAssesmentDetail?.title}</p>
                                </div>

                                <Button
                                    label={viewAssesmentDetail?.status}
                                    className={`${viewAssesmentDetail?.status == "pending" ? "bg-warning" : viewAssesmentDetail?.status == "in_progress" ? "bg-info" : viewAssesmentDetail?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                />
                            </div>

                            <div className='mt-4'>
                                <h3>Description</h3>
                                <p>{viewAssesmentDetail?.description}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Country</h3>
                                <p>{viewAssesmentDetail?.country?.name}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Total Collectors</h3>
                                <p>{getCollectorStatus?.length}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>No. Of Questionnaires</h3>
                                <p>{viewAssesmentDetail?.questionnaires?.length}</p>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <h3>Project</h3>
                                    <p>{assesmentData?.project?.name ?? "-"}</p>
                                </div>
                                <div className="col-md-3">
                                    <h3>Partner</h3>
                                    <p>{assesmentData?.partner?.name ?? "-"}</p>
                                </div>
                                <div className="col-md-3">
                                    <h3>Due Date</h3>
                                    <p>{dueDate}</p>
                                </div>
                                <div className="col-md-3">
                                    <h3>No of Records</h3>
                                    <p>{assesmentData?.numberOfRecords}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link text-dark active" data-bs-toggle="tab" href="#Questionnaires"><h2 className="fs-1">Questionnaires</h2></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-dark" data-bs-toggle="tab" href="#Users"><h2 className="fs-1">Collectors</h2></a>
                            </li>
                        </ul>

                        <div class="tab-content">

                            <div class="tab-pane active" id="Questionnaires">
                                {viewAssesmentDetail?.questionnaires?.map((data, index) => {
                                    return (
                                        <div className="col-12 mb-4 mt-5" key={index}>
                                            <div className="assesDiv">
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div>
                                                        <h3>Title</h3>
                                                        <p>{data?.title}</p>
                                                    </div>

                                                    <div className='d-flex align-items-start gap-3'>
                                                        {data?.guideFile ?
                                                            <a href={BASE_URL_FILES + data?.guideFile} target="_blank">
                                                                <Button
                                                                    label="View User Guide"
                                                                    className="themeBtn"
                                                                />
                                                            </a>
                                                            : null}

                                                        <Button
                                                            label="View"
                                                            className="themeBtn bg-success border-0"
                                                            onClick={() => navigate(PATHS.viewSolvedAssesment, { state: { data: data, recordId: '', userId: '', assessmentId: '', viewOnly: true } })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <h3>Description</h3>
                                                    <p>{data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div class="tab-pane fade" id="Users">
                                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Records Completed</th>
                                            <th>Records InProgress</th>
                                            <th>Records Not Started</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.length ? (
                                            paginatedData?.map((data, index) => {
                                                return (
                                                    <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.viewConductorAssesmentByAdmin, { state: { data: viewAssesmentDetail, userId: data?.collectorId, name: data?.collectorName } })}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={BASE_URL_IMAGES + data?.user?.avatar || avatar}
                                                                    className="rounded-circle me-2"
                                                                    alt=""
                                                                    height={30}
                                                                    width={30}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = avatar;
                                                                    }}
                                                                />
                                                                {data?.collectorName}
                                                            </div>
                                                        </td>
                                                        <td className='text-capitalize'>{data?.userStatus?.replace(/_/g, " ")}</td>
                                                        <td>{data?.recordCompleted}</td>
                                                        <td>{data?.recordInProgress}</td>
                                                        <td>{data?.recordNotStarted}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8}>
                                                    {viewAssesmentDetailLoading ? (
                                                        <div className="text-center">
                                                            <img src={loader} height={100} alt="" />
                                                        </div>
                                                    ) : (
                                                        <h2 className="text-center mt-5">No Record Found</h2>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
                            </div>
                        </div>

                    </div>

                </div>
            }
        </>
    )
}

export default ViewAssesmentForAdmin;