import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import { useGetPartnersQuery, useGetProjectsQuery, useGetQuestionnairesQuery, useGetUsersQuery } from "../../services/Api";
import Select from 'react-select';
import moment from "moment/moment";
import { add } from "../../assets/imagesPath";
import AddPartnerModal from "./AddPartnerModal";

const AddAssesmentModal = (props) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState('');
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [partnerName, setPartnerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const [openPartnerModalCondition, setOpenPartnerModalCondition] = useState("");
    const [success, setSuccess] = useState("");

    const {
        data: getPartners,
        refetch: getPartnersRefetch,
    } = useGetPartnersQuery();

    const {
        data: getProjects,
        refetch: getProjectsRefetch,
    } = useGetProjectsQuery();

    const {
        data: getQuestionnaires,
        refetch: getQuestionnairesRefetch,
    } = useGetQuestionnairesQuery();

    const {
        data: getUsers,
        refetch: getUsersRefetch,
    } = useGetUsersQuery();

    const addPartnersModalHandler = (status) => {
        setOpenPartnerModal((prev) => !prev);
        setOpenPartnerModalCondition(status);
    };

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    useEffect(() => {

        const transformedUserArray = props?.data?.users?.map(item => ({
            user: item?.user,
            status: 'not_started'
        }));

        const initialSelectedQuestionnaire = props?.data?.questionnaires || [];
        setSelectedQuestionnaire(initialSelectedQuestionnaire);
        setSelectedUser(transformedUserArray || []);
    }, [props?.data?.questionnaires, props?.data?.users]);

    const transformedSelectedUserArray = selectedUser?.map(item => ({
        user: item?.value || item?.user,
        status: 'not_started'
    }));

    const getQuestionnaireFinalData = () => {
        return selectedQuestionnaire;
    };

    const onSubmit = () => {

        const transformedUserArray = selectedUser?.map(item => ({
            user: item?.value,
            status: 'not_started'
        }));

        const questionnaireFinalData = getQuestionnaireFinalData();

        const data = {
            title: title,
            description: description,
            dueDate: dueDate,
            partner: selectedPartner?.value || partnerName?.value,
            project: selectedProject?.value || projectName?.value,
            questionnaires: selectedQuestionnaire,
            users: transformedUserArray,
        };

        const dataWithId = {
            title: title,
            description: description,
            dueDate: dueDate,
            partner: selectedPartner?.value || partnerName?.value,
            project: selectedProject?.value || projectName?.value,
            questionnaires: questionnaireFinalData,
            users: transformedSelectedUserArray,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    const validationHandler = () => {
        let noError = false;

        titleValidationHandler();
        descriptionValidationHandler();
        dueDateValidationHandler();
        partnerValidationHandler();
        projectValidationHandler();
        questionnaireValidationHandler();
        userValidationHandler();

        if (title && description && dueDate && (selectedPartner || partnerName?.value) && (selectedProject || projectName?.value) && (selectedQuestionnaire?.length || props?.data?.questionnaires) && (selectedUser?.length || props?.data?.users)) {
            onSubmit();
        } else {
            return false;
        }

        return noError;
    };

    const titleValidationHandler = () => {
        if (!title) {
            setError("title", { type: "custom", message: "Title is required" });
        } else {
            clearErrors("title");
        }
    };

    const descriptionValidationHandler = () => {
        if (!description) {
            setError("description", { type: "custom", message: "Description is required" });
        } else {
            clearErrors("description");
        }
    };

    const dueDateValidationHandler = () => {
        if (!dueDate) {
            setError("dueDate", { type: "custom", message: "Date is required" });
        } else {
            clearErrors("dueDate");
        }
    };

    const partnerValidationHandler = () => {
        if (!selectedPartner && !partnerName) {
            setError("partner", { type: "custom", message: "Partner is required" });
        } else {
            clearErrors("partner");
        }
    };

    const projectValidationHandler = () => {
        if (!selectedProject && !projectName) {
            setError("project", { type: "custom", message: "Project is required" });
        } else {
            clearErrors("project");
        }
    };

    const questionnaireValidationHandler = () => {
        if (!selectedQuestionnaire?.length && !props?.data?.questionnaires) {
            setError("questionnaire", { type: "custom", message: "Questionnaire is required" });
        } else {
            clearErrors("questionnaire");
        }
    };

    const userValidationHandler = () => {
        if (!selectedUser?.length && !props?.data?.users) {
            setError("user", { type: "custom", message: "User is required" });
        } else {
            clearErrors("user");
        }
    };

    const handleChange = (selectedOption, { name }) => {
        switch (name) {
            case 'partner':
                setSelectedPartner(selectedOption);
                break;
            case 'project':
                setSelectedProject(selectedOption);
                break;
            default:
                break;
        }
    };

    const handleUsersChange = (selectedOptions) => {
        let users = selectedOptions?.map(option => option);
        setSelectedUser(users);
    };

    const handleQuestionnaireChange = (selectedOptions) => {
        let questionnaire = selectedOptions?.map(option => option?.value);
        setSelectedQuestionnaire(questionnaire);
    };

    const preprocessData = (data) => {
        return data?.map(data => ({
            label: data?.name || data?.firstName || data?.title,
            value: data?.id,
        }));
    };

    const useFilteredData = (data, selectedValue, key) => {

        const [filteredData, setFilteredData] = useState([]);

        useEffect(() => {
            if (selectedValue) {
                const filtered = data?.filter(
                    (item) => item[key] == selectedValue?.value
                );
                setFilteredData(filtered);
            } else {
                setFilteredData([]);
            }
        }, [selectedValue, data, key]);

        return filteredData;
    };

    const filteredProjects = useFilteredData(getProjects, selectedPartner, 'partner');

    // const allProjects = preprocessData(getProjects || []);
    const allProjects = preprocessData(filteredProjects || []);
    const allPartners = preprocessData(getPartners || []);
    const allQuestionnaires = preprocessData(getQuestionnaires || []);
    const allUsers = preprocessData(getUsers || []);

    const getQuestionnaireNamesByIds = (ids, data) => {
        return ids?.map(id => {
            const values = data?.find(values => values?.value == id);
            return values ? values : null;
        });
    };

    const getUserNamesByIds = (ids, data) => {
        return ids?.map(id => {
            const users = data?.find(users => users?.value == id?.user);
            return users ? users : null;
        });
    };

    const getQuestionnaireNames = getQuestionnaireNamesByIds(props?.data?.questionnaires, allQuestionnaires);
    const getUserNames = getUserNamesByIds(props?.data?.users, allUsers);

    const handleSuccess = (status) => {
        setSuccess(status);
    };

    useEffect(() => {
        if (props?.data) {
            updateName('partner', props?.data?.partner);
            updateName('project', props?.data?.project);
        }
    }, [getPartners, getProjects]);

    useEffect(() => {
        if (props?.data) {
            let dueDate = moment(props?.data?.dueDate).format("YYYY-MM-DD");
            setTitle(props?.data?.title);
            setDescription(props?.data?.description);
            setDueDate(dueDate);
        }
    }, [props?.data]);

    const updateName = (type, value) => {
        const allEntities = {
            partner: allPartners,
            project: allProjects,
        };

        const setNameFunctions = {
            partner: setPartnerName,
            project: setProjectName,
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.value == value);
            if (entity) {
                setName(entity);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        getPartnersRefetch();
        getProjectsRefetch();
        getQuestionnairesRefetch();
        getUsersRefetch();
    }, []);

    useEffect(() => {
        if (success) {
            getPartnersRefetch();
        }
    }, [success]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddAssesmentModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Assesment</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Assesment</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-12 mb-3">
                                <label className="fs-4 mb-3">Title</label>
                                <input type="text" className="themeInput fs-4" placeholder='Title' name="title" onChange={(e) => setTitle(e.target.value)} value={title} disabled={props?.view ? true : false} />

                                {errors.title && <p className='errorMsg'>{errors.title.message}</p>}
                            </div>

                            <div className="col-12 mb-3">
                                <label className="fs-4 mb-3">Description</label>
                                <textarea type="text" className="themeInput themeTextarea fs-4" placeholder='Description' name="name" onChange={(e) => setDescription(e.target.value)} value={description} disabled={props?.view ? true : false} />

                                {errors.description && <p className='errorMsg'>{errors.description.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Due Date</label>
                                <input type="date" className="themeInput fs-4" name="dueDate" onChange={(e) => setDueDate(e.target.value)} value={dueDate} disabled={props?.view ? true : false} />

                                {errors.dueDate && <p className='errorMsg'>{errors.dueDate.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Partners</label>

                                <div className='d-flex align-items-center justify-content-between'>
                                    <Select
                                        options={allPartners}
                                        onChange={handleChange}
                                        className='w-100 me-3'
                                        name="partner"
                                        placeholder={partnerName?.label}
                                        isDisabled={props?.view ? true : false}
                                    />

                                    <img src={add} alt="" className='actionIcons' onClick={() => addPartnersModalHandler(true)} />
                                </div>

                                {errors.partner && <p className='errorMsg'>{errors.partner.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Projects</label>
                                <Select
                                    options={allProjects}
                                    onChange={handleChange}
                                    className='w-100 me-3'
                                    name="project"
                                    placeholder={projectName?.label}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.project && <p className='errorMsg'>{errors.project.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Questionnaires</label>
                                <Select
                                    options={allQuestionnaires}
                                    onChange={handleQuestionnaireChange}
                                    className='w-100 me-3'
                                    name="questionnaire"
                                    defaultValue={getQuestionnaireNames}
                                    isMulti={true}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.questionnaire && <p className='errorMsg'>{errors.questionnaire.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Users</label>
                                <Select
                                    options={allUsers}
                                    onChange={handleUsersChange}
                                    className='w-100 me-3'
                                    name="user"
                                    defaultValue={getUserNames}
                                    isMulti={true}
                                    isDisabled={props?.view ? true : false}
                                />

                                {errors.user && <p className='errorMsg'>{errors.user.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={validationHandler}
                                />
                            </div>
                            : null}

                        {openPartnerModal &&
                            <AddPartnerModal
                                handleCloseAddPartnerModal={addPartnersModalHandler}
                                isOpenCondition={openPartnerModalCondition}
                                success={handleSuccess}
                            />
                        }
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddAssesmentModal;