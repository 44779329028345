import React from "react";
import { useLocation } from "react-router-dom";
import { Table } from "reactstrap";

const VewSentEmails = () => {

    const location = useLocation();
    const data = location?.state?.data;

    return (
        <div className="row">
            <div className="col-12">
                <h1>View Sent Emails</h1>

                <div className="row mt-4">

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">Title</label>
                        <input type="text" className="themeInput fs-4" disabled value={data?.from?.emailTitle} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4">From</label>
                        <input type="text" className="themeInput fs-4" disabled value={data?.from?.emailUser} />
                    </div>

                    <div className="col-12 mb-3">
                        <label className="fs-4">Subject</label>
                        <input type="text" className="themeInput fs-4" disabled value={data?.subject} />
                    </div>

                    <div className="col-12 mb-3">
                        <label className="fs-4">Email Body</label>
                        <div className="border border-dark p-2 fs-4" dangerouslySetInnerHTML={{ __html: data?.body }}></div>
                    </div>

                </div>

                <div className='row mt-5'>
                    <div className="col-12 formTable">
                        <label className="fs-4">To Users</label>
                        <Table className="no-wrap mt-2 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Organization Name</th>
                                    <th>Organization Type</th>
                                    <th>Sector</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.to?.map((data, index) => {
                                    return (
                                        <tr className="border-top mainDiv cursor" key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data?.title}</td>
                                            <td>{data?.name}</td>
                                            <td>{data?.email}</td>
                                            <td>{data?.organizationName}</td>
                                            <td>{data?.organizationType}</td>
                                            <td>{data?.sector}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VewSentEmails;