import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetQuestionnairesByIdQuery, useSubmitAnswersMutation } from '../../services/Api';
import { loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import { BASE_URL_FILES } from '../../services/ApiEndpoints';
import Section from './Section';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PATHS from "../../routes/Paths";

const StartAssesmentForConductor = () => {

    const [responses, setResponses] = useState({});
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useSelector((data) => data?.auth);

    const {
        data: getQuestionnairesById,
        refetch: getQuestionnairesByIdRefetch,
        isLoading: getQuestionnairesByIdLoading,
    } = useGetQuestionnairesByIdQuery({ params: { id: location?.state?.id, userId: auth?.userDetail?.id, assessmentId: location?.state?.assesmentId, recordId: location?.state?.recordId } });

    const [submitAnswers, { isLoading: submitAnswersLoading }] = useSubmitAnswersMutation();

    const handleInputChange = (sectionId, subsectionId, questionId, value) => {
        setResponses(prevResponses => {
            if (subsectionId) {
                return {
                    ...prevResponses,
                    [sectionId]: {
                        ...prevResponses[sectionId],
                        [subsectionId]: {
                            ...prevResponses[sectionId]?.[subsectionId],
                            [questionId]: value,
                        },
                    },
                };
            } else {
                return {
                    ...prevResponses,
                    [sectionId]: {
                        ...prevResponses[sectionId],
                        [questionId]: value,
                    },
                };
            }
        });

        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            if (subsectionId) {
                if (updatedErrors[sectionId]?.[subsectionId]?.[questionId]) {
                    delete updatedErrors[sectionId][subsectionId][questionId];
                    if (Object.keys(updatedErrors[sectionId][subsectionId]).length === 0) {
                        delete updatedErrors[sectionId][subsectionId];
                    }
                }
            } else {
                if (updatedErrors[sectionId]?.[questionId]) {
                    delete updatedErrors[sectionId][questionId];
                    if (Object.keys(updatedErrors[sectionId]).length === 0) {
                        delete updatedErrors[sectionId];
                    }
                }
            }
            return updatedErrors;
        });
    };

    const handleNext = () => {
        const validationErrors = validateCurrentSection();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length == 0) {
            if (currentSectionIndex < getQuestionnairesById?.sections?.length - 1) {
                setCurrentSectionIndex(currentSectionIndex + 1);
            }
        }
    };

    const handlePrevious = () => {
        if (currentSectionIndex > 0) {
            setCurrentSectionIndex(currentSectionIndex - 1);
        }
    };

    const submitAnswersApiHandler = () => {

        const validationErrors = validateCurrentSection();
        setErrors(validationErrors);

        const finalData = {
            questionnaireId: getQuestionnairesById?._id,
            userId: auth?.userDetail?.id,
            recordId: location?.state?.recordId,
            assessmentId: location?.state?.assesmentId,
            questionnaireStatus: 'completed',
            assessmentStatus: 'in_progress',
            sections: getQuestionnairesById?.sections?.map(section => ({
                _id: section._id,
                title: section.title,
                sortOrder: section.sortOrder,
                questions: section?.questions?.map(question => ({
                    _id: question._id,
                    text: question.text,
                    type: question.type,
                    required: question.required,
                    options: question.options,
                    sortOrder: question.sortOrder,
                    answer: responses[section._id]?.[question._id] || question.answer || '',
                    id: question._id
                })),
                subsections: section?.subsections?.map(subsection => ({
                    _id: subsection._id,
                    title: subsection.title,
                    sortOrder: subsection.sortOrder,
                    questions: subsection?.questions?.map(question => ({
                        _id: question._id,
                        text: question.text,
                        type: question.type,
                        required: question.required,
                        options: question.options,
                        sortOrder: question.sortOrder,
                        answer: responses[section._id]?.[subsection._id]?.[question._id] || question.answer || '',
                        id: question._id
                    })),
                }))
            }))
        };

        if (Object.keys(validationErrors).length == 0) {
            submitAnswers({ data: finalData })
                .unwrap()
                .then(() => {
                    toast.success("Assesment submitted successfully");
                    navigate(PATHS.assesmentsById);
                })
                .catch((error) => {
                    toast.error(error?.data?.message);
                });
        } else {
            toast.error("Please fill the required fields");
        }
    };

    useEffect(() => {
        getQuestionnairesByIdRefetch();
    }, []);

    const validateCurrentSection = () => {
        const currentSection = getQuestionnairesById?.sections[currentSectionIndex];
        const sectionErrors = {};

        // Validate section questions
        currentSection?.questions?.forEach((question) => {
            if (question.required && !responses[currentSection._id]?.[question._id]) {
                sectionErrors[question._id] = "This field is required";
            }
        });

        // Validate subsection questions
        currentSection?.subsections?.forEach((subsection) => {
            subsection?.questions?.forEach((question) => {
                if (question.required && !responses[currentSection._id]?.[subsection._id]?.[question._id]) {
                    if (!sectionErrors[subsection._id]) {
                        sectionErrors[subsection._id] = {};
                    }
                    sectionErrors[subsection._id][question._id] = "This field is required";
                }
            });
        });

        return sectionErrors;
    };

    return (
        <>
            {getQuestionnairesByIdLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row mt-5'>
                    <div className="col-12 mb-4">
                        <div className="assesDiv">
                            <div className='d-flex align-items-start justify-content-between'>
                                <div>
                                    <h3>Title</h3>
                                    <p>{getQuestionnairesById?.title}</p>
                                </div>

                                <div className='d-flex align-items-start gap-3'>
                                    {/* <Button
                                        label="Save as draft"
                                        className="themeBtn bg-success border-0"
                                    /> */}

                                    <a href={BASE_URL_FILES + getQuestionnairesById?.guideFile} target="_blank">
                                        <Button
                                            label="View User Guide"
                                            className="themeBtn"
                                        />
                                    </a>

                                    {/* <Button
                                        label={getQuestionnairesById?.status}
                                        className={`${getQuestionnairesById?.status == "pending" ? "bg-warning" : getQuestionnairesById?.status == "in_progress" ? "bg-info" : getQuestionnairesById?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                    /> */}
                                </div>
                            </div>

                            <div className='mt-4'>
                                <h3>Description</h3>
                                <p>{getQuestionnairesById?.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {getQuestionnairesById?.sections?.map((section, index) => (
                            <div style={{ display: index === currentSectionIndex ? 'block' : 'none' }}>
                                <Section
                                    key={section?._id}
                                    section={section}
                                    responses={responses}
                                    errors={errors}
                                    handleInputChange={handleInputChange}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="col-12 text-end mb-5">
                        {currentSectionIndex > 0 && (
                            <Button
                                label="← Previous"
                                className="themeBtn me-5"
                                onClick={handlePrevious}
                            />
                        )}
                        {currentSectionIndex < getQuestionnairesById?.sections?.length - 1 ? (
                            <Button
                                label="Next →"
                                className="themeBtn"
                                onClick={handleNext}
                            />
                        ) : (
                            <Button
                                label="Submit"
                                className="themeBtn"
                                onClick={submitAnswersApiHandler}
                                loading={submitAnswersLoading}
                            />
                        )}
                    </div>
                </div>
            }
        </>
    )
}

export default StartAssesmentForConductor;