import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import { BASE_URL_IMAGES } from "../../services/ApiEndpoints";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ImageViewer from "../imageViewer/ImageViewer";
// import { useGetCountriesQuery } from "../../services/Api";
// import Select from 'react-select';

const AddUserModal = (props) => {

    // const [selectedCountry, setSelectedCountry] = useState(null);
    // const [countryName, setCountryName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState(props?.data?.permissions ?? []);
    const [selectedRole, setSelectedRole] = useState(props?.data?.role);

    const validationSchema = props?.data
        ? Yup.object().shape({
            firstName: Yup.string().required("First Name is required"),
            lastName: Yup.string().required("Last Name is required"),
            email: Yup.string().required("Email is required"),
            phoneNo: Yup.string().required("Phone No. is required"),
            address: Yup.string().required("Address is required"),
            qualification: Yup.string().required("Qualification is required"),
            designation: Yup.string().required("Designation is required"),
        })
        : Yup.object().shape({
            firstName: Yup.string().required("First Name is required"),
            lastName: Yup.string().required("Last Name is required"),
            email: Yup.string().required("Email is required"),
            phoneNo: Yup.string().required("Phone No. is required"),
            address: Yup.string().required("Address is required"),
            qualification: Yup.string().required("Qualification is required"),
            designation: Yup.string().required("Designation is required"),
        });

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    // const {
    //     data: getCountries,
    //     refetch: getCountriesRefetch,
    // } = useGetCountriesQuery();

    // const preprocessLocationData = (locations) => {
    //     return locations?.map(locations => ({
    //         label: locations?.name,
    //         value: locations?._id,
    //     }));
    // };

    // const updateName = (type, value) => {
    //     const allEntities = {
    //         country: allCountries,
    //     };

    //     const setNameFunctions = {
    //         country: setCountryName,
    //     };

    //     const entities = allEntities[type];
    //     const setName = setNameFunctions[type];

    //     if (entities) {
    //         const entity = entities?.find(entity => entity?.value == value);
    //         if (entity) {
    //             setName(entity);
    //         } else {
    //             setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
    //         }
    //     } else {
    //         setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
    //     }
    // };

    // useEffect(() => {
    //     updateName('country', props?.data?.country);
    // }, [getCountries]);

    // const allCountries = preprocessLocationData(getCountries || []);

    // const handleLocationChange = (selectedOption, { name }) => {
    //     switch (name) {
    //         case 'country':
    //             setSelectedCountry(selectedOption);
    //             break;
    //         default:
    //             break;
    //     }
    // };

    let { avatar } = watch();

    const onSubmit = (values) => {

        let formData = new FormData();

        if (avatar) {
            formData.append('avatar', avatar);
        }

        const adminPermissions = [
            "dashboard",
            "users",
            "locations",
            "partners",
            "centers",
            "projects",
            "templates",
            "questionnaires",
            "assesments",
            "contactList",
            "outgoingEmails",
            "emailTemplate",
            "sentEmails",
        ];

        formData.append('firstName', values?.firstName);
        formData.append('lastName', values?.lastName);
        formData.append('email', values?.email);
        formData.append('address', values?.address);
        formData.append('phoneNo', values?.phoneNo);
        formData.append('qualification', values?.qualification);
        formData.append('designation', values?.designation);
        formData.append('role', selectedRole);

        if (selectedRole == "8989") {
            adminPermissions?.map((data, index) => {
                formData.append(`permissions[${index}]`, data);
            });
        } else {
            selectedPermissions?.map((data, index) => {
                formData.append(`permissions[${index}]`, data);
            });
        }

        if (props?.data) {
            formData.append('userId', props?.data?._id);
        }

        if (props?.data) {
            props?.actionUpdate(formData);
        } else {
            props?.action(formData);
        }
    };

    const uploadImgHandler = (e) => {
        let { name, files } = e.target;
        setValue(name, files[0]);
    };

    useEffect(() => {
        if (props?.data) {
            let { firstName, lastName, email, address, phoneNo, qualification, designation } = props?.data;
            reset({ firstName: firstName, lastName: lastName, email: email, address: address, phoneNo: phoneNo, qualification: qualification, designation: designation });
        }
    }, [props?.data]);

    // useEffect(() => {
    //     getCountriesRefetch();
    // }, []);

    const handlePermissionsChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setSelectedPermissions([...selectedPermissions, id.toLowerCase()]);
        } else {
            setSelectedPermissions(selectedPermissions.filter(item => item != id.toLowerCase()));
        }
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddUserModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">{props?.data ? "Edit" : "Add"} User</h1>
                        <div className="row mt-4">
                            <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                                <div style={{ width: "10rem", height: "10rem" }}>
                                    <ImageViewer
                                        src={avatar || BASE_URL_IMAGES + props?.data?.avatar}
                                        alt=''
                                        className='border img-fluid rounded-circle h-100'
                                    />
                                </div>

                                <div className='position-relative m-4'>
                                    <input type="file" className="opacity-0 uploadImgBtn" name='avatar'
                                        accept="image/jpeg, image/png, image/jpg" onChange={uploadImgHandler} />
                                    <p className="my-3 fs-3">Upload
                                        Image</p>
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">First Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='First Name' {...register("firstName")} />

                                {errors.firstName && <p className='errorMsg'>{errors.firstName.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Last Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Last Name' {...register("lastName")} />

                                {errors.lastName && <p className='errorMsg'>{errors.lastName.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email</label>
                                <input type="text" className="themeInput fs-4" placeholder='Email' {...register("email")} />

                                {errors.email && <p className='errorMsg'>{errors.email.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Address</label>
                                <input type="text" className="themeInput fs-4" placeholder='Address' {...register("address")} />

                                {errors.address && <p className='errorMsg'>{errors.address.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Phone</label>
                                <input type="text" className="themeInput fs-4" placeholder='Phone' {...register("phoneNo")} />

                                {errors.phoneNo && <p className='errorMsg'>{errors.phoneNo.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Qualification</label>
                                <input type="text" className="themeInput fs-4" placeholder='Qualification' {...register("qualification")} />

                                {errors.qualification && <p className='errorMsg'>{errors.qualification.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Designation</label>
                                <input type="text" className="themeInput fs-4" placeholder='Designation' {...register("designation")} />

                                {errors.designation && <p className='errorMsg'>{errors.designation.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Role</label>
                                <select className="themeInput w-100" value={selectedRole} onChange={handleRoleChange}>
                                    <option value="">{props?.data?.role == '8989' ? 'Admin' : props?.data?.role == '2000' ? 'Consultant' : props?.data?.role == '2003' ? 'Data Collector' : 'Select Role'}</option>
                                    <option value="8989">Admin</option>
                                    <option value="2003">Data Collector</option>
                                    <option value="2000">Consultant</option>
                                </select>
                            </div>

                            {selectedRole == "8989" ? null :
                                <>
                                    <div className="col-12 mt-4 mb-3">
                                        <h1>Permissions</h1>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Dashboard" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('dashboard')} />
                                        <label className="fs-4" for="Dashboard">Dashboard</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Users" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('users')} />
                                        <label className="fs-4" for="Users">Users</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Locations" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('locations')} />
                                        <label className="fs-4" for="Locations">Locations</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Partners" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('partners')} />
                                        <label className="fs-4" for="Partners">Partners</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Centers" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('centers')} />
                                        <label className="fs-4" for="Centers">Centers</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Projects" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('projects')} />
                                        <label className="fs-4" for="Projects">Projects</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Templates" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('templates')} />
                                        <label className="fs-4" for="Templates">Email Templates</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Questionnaires" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('questionnaires')} />
                                        <label className="fs-4" for="Questionnaires">Questionnaires</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="Assesments" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('assesments')} />
                                        <label className="fs-4" for="Assesments">Assesments</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="ContactList" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('contactList')} />
                                        <label className="fs-4" for="ContactList">Contact List</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="OutgoingEmails" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('outgoingEmails')} />
                                        <label className="fs-4" for="OutgoingEmails">Outgoing Emails</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="EmailTemplate" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('emailTemplate')} />
                                        <label className="fs-4" for="EmailTemplate">Email Template</label>
                                    </div>

                                    <div className="col-md-3 d-flex align-items-center gap-3 mb-4">
                                        <input type="checkbox" id="SentEmails" onChange={handlePermissionsChange} defaultChecked={selectedPermissions?.includes('sentEmails')} />
                                        <label className="fs-4" for="SentEmails">Sent Emails</label>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="text-center">
                            <Button
                                label="Submit"
                                className="mt-5 themeBtn"
                                loading={props?.loading}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddUserModal;