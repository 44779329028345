import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useFilterChartByAnswersMutation } from '../../services/Api';
import Button from '../../components/button/Button';

const QuestionnairesCollapse = ({ data, questionnaireId, assesmentId }) => {
    const [openSections, setOpenSections] = useState({});
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [chartApiResponse, setChartApiResponse] = useState([]);

    const toggleSection = (id) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleQuestionSelect = (questionId) => {
        setSelectedQuestionId(questionId);
    };

    const [filterChartByAnswers, { isLoading: filterChartByAnswersLoading }] = useFilterChartByAnswersMutation();

    const filterChartWithApi = () => {
        const data = {
            assessmentIds: [questionnaireId],
            questionnaireIds: [assesmentId],
            questionIds: [selectedQuestionId],
        };

        filterChartByAnswers({ data: data })
            .unwrap()
            .then((payload) => {
                setChartApiResponse(payload);
            })
            .catch((error) => { });
    };

    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: "Answers",
                data: []
            }
        ],
        options: {
            chart: {
                type: "bar"
            },
            xaxis: {
                categories: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth"
            }
        }
    });

    const [lineChartOptions, setLineChartOptions] = useState({
        series: [
            {
                name: "Answers",
                data: []
            }
        ],
        options: {
            chart: {
                type: "line"
            },
            xaxis: {
                categories: []
            },
            stroke: {
                curve: "smooth"
            }
        }
    });

    const [pieChartOptions, setPieChartOptions] = useState({
        series: [],
        options: {
            chart: {
                type: 'pie'
            },
            labels: []
        }
    });

    const [frequencyChartOptions, setFrequencyChartOptions] = useState({
        series: [
            {
                name: "Answers",
                data: []
            }
        ],
        options: {
            chart: {
                type: "bar"  // You can change this to "line" in line chart section if needed
            },
            xaxis: {
                categories: []
            },
            yaxis: {
                max: 100,  // Limit the Y-axis to 100% for percentage display
                title: {
                    text: "Percentage (%)"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth"
            }
        }
    });

    const appendDataToChart = () => {
        const answers = [];
        const values = [];

        const item = chartApiResponse?.[0];

        if (item) {
            item?.result?.forEach((resultItem) => {
                answers.push(resultItem.answer);
                values.push(resultItem.value);
            });
        };

        const total = values.reduce((acc, val) => acc + val, 0);

        const percentageValues = values.map((value) =>
            total ? parseFloat(((value / total) * 100).toFixed(2)) : 0
        );

        setChartOptions((prevOptions) => ({
            ...prevOptions,
            series: [
                {
                    name: "Answers",
                    data: values.length ? values : [],
                },
            ],
            options: {
                ...prevOptions.options,
                xaxis: {
                    categories: answers.length ? answers : [],
                },
                tooltip: {
                    y: {
                        formatter: (value) => `${value}`,
                    },
                },
            },
        }));

        setLineChartOptions((prevOptions) => ({
            ...prevOptions,
            series: [
                {
                    name: "Answers",
                    data: values.length ? values : [],
                },
            ],
            options: {
                ...prevOptions.options,
                xaxis: {
                    categories: answers.length ? answers : [],
                },
                tooltip: {
                    y: {
                        formatter: (value) => `${value}`,
                    },
                },
            },
        }));

        setFrequencyChartOptions((prevOptions) => ({
            ...prevOptions,
            series: [
                {
                    name: "Answers",
                    data: percentageValues.length ? percentageValues : [],
                },
            ],
            options: {
                ...prevOptions.options,
                xaxis: {
                    categories: answers.length ? answers : [],
                },
                yaxis: {
                    max: 100,
                    title: {
                        text: "Percentage (%)",
                    },
                },
                tooltip: {
                    y: {
                        formatter: (value) => `${value.toFixed(2)}%`,
                    },
                },
            },
        }));

        setPieChartOptions((prevOptions) => ({
            ...prevOptions,
            series: values,
            options: {
                ...prevOptions.options,
                labels: answers
            }
        }));
    };

    useEffect(() => {
        appendDataToChart();
    }, [chartApiResponse[0]]);

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    {chartApiResponse?.length ? (
                        <>
                            <h2 className="mb-4 text-warning">Q: {chartApiResponse[0]?.questionTitle}</h2>
                            <div>
                                <ul className="nav nav-tabs" id="chartTabs" role="tablist">
                                    <li className="nav-item fs-5" role="presentation">
                                        <button className="nav-link text-dark active" id="bar-chart-tab" data-bs-toggle="tab" data-bs-target="#bar-chart" type="button" role="tab" aria-controls="bar-chart" aria-selected="true">
                                            Bar Chart
                                        </button>
                                    </li>
                                    <li className="nav-item fs-5" role="presentation">
                                        <button className="nav-link text-dark" id="line-chart-tab" data-bs-toggle="tab" data-bs-target="#line-chart" type="button" role="tab" aria-controls="line-chart" aria-selected="false">
                                            Line Chart
                                        </button>
                                    </li>
                                    <li className="nav-item fs-5" role="presentation">
                                        <button className="nav-link text-dark" id="pie-chart-tab" data-bs-toggle="tab" data-bs-target="#pie-chart" type="button" role="tab" aria-controls="pie-chart" aria-selected="false">
                                            Pie Chart
                                        </button>
                                    </li>
                                    <li className="nav-item fs-5" role="presentation">
                                        <button className="nav-link text-dark" id="table-tab" data-bs-toggle="tab" data-bs-target="#table" type="button" role="tab" aria-controls="table" aria-selected="false">
                                            Table
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="chartTabsContent">
                                    <div className="tab-pane fade show active" id="bar-chart" role="tabpanel" aria-labelledby="bar-chart-tab">
                                        <ul className="nav nav-tabs mt-3" role="tablist">
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark active" id="bar-quantitative-tab" data-bs-toggle="tab" data-bs-target="#bar-quantitative" type="button" role="tab" aria-controls="bar-quantitative" aria-selected="true">
                                                    Quantitative
                                                </button>
                                            </li>
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark" id="bar-frequency-tab" data-bs-toggle="tab" data-bs-target="#bar-frequency" type="button" role="tab" aria-controls="bar-frequency" aria-selected="false">
                                                    Frequency
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="bar-quantitative" role="tabpanel" aria-labelledby="bar-quantitative-tab">
                                                <Chart
                                                    type="bar"
                                                    width="100%"
                                                    height="390"
                                                    options={chartOptions.options}
                                                    series={chartOptions.series}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="bar-frequency" role="tabpanel" aria-labelledby="bar-frequency-tab">
                                                <Chart
                                                    type="bar"
                                                    width="100%"
                                                    height="390"
                                                    options={frequencyChartOptions.options}
                                                    series={frequencyChartOptions.series}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="line-chart" role="tabpanel" aria-labelledby="line-chart-tab">
                                        <ul className="nav nav-tabs mt-3" role="tablist">
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark active" id="line-quantitative-tab" data-bs-toggle="tab" data-bs-target="#line-quantitative" type="button" role="tab" aria-controls="line-quantitative" aria-selected="true">
                                                    Quantitative
                                                </button>
                                            </li>
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark" id="line-frequency-tab" data-bs-toggle="tab" data-bs-target="#line-frequency" type="button" role="tab" aria-controls="line-frequency" aria-selected="false">
                                                    Frequency
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="line-quantitative" role="tabpanel" aria-labelledby="line-quantitative-tab">
                                                <Chart
                                                    type="line"
                                                    width="100%"
                                                    height="390"
                                                    options={lineChartOptions.options}
                                                    series={lineChartOptions.series}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="line-frequency" role="tabpanel" aria-labelledby="line-frequency-tab">
                                                <Chart
                                                    type="line"
                                                    width="100%"
                                                    height="390"
                                                    options={frequencyChartOptions.options}
                                                    series={frequencyChartOptions.series}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="pie-chart" role="tabpanel" aria-labelledby="pie-chart-tab">
                                        <ul className="nav nav-tabs mt-3" role="tablist">
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark active" id="pie-quantitative-tab" data-bs-toggle="tab" data-bs-target="#pie-quantitative" type="button" role="tab" aria-controls="pie-quantitative" aria-selected="true">
                                                    Quantitative
                                                </button>
                                            </li>
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark" id="pie-frequency-tab" data-bs-toggle="tab" data-bs-target="#pie-frequency" type="button" role="tab" aria-controls="pie-frequency" aria-selected="false">
                                                    Frequency
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="line-quantitative" role="tabpanel" aria-labelledby="line-quantitative-tab">
                                                <Chart
                                                    type="pie"
                                                    width="100%"
                                                    height="390"
                                                    options={pieChartOptions.options}
                                                    series={pieChartOptions.series}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="line-frequency" role="tabpanel" aria-labelledby="line-frequency-tab">
                                                <Chart
                                                    type="pie"
                                                    width="100%"
                                                    height="390"
                                                    options={frequencyChartOptions.options}
                                                    series={frequencyChartOptions.series}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="table" role="tabpanel" aria-labelledby="table-tab">
                                        <ul className="nav nav-tabs mt-3" role="tablist">
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark active" id="table-quantitative-tab" data-bs-toggle="tab" data-bs-target="#table-quantitative" type="button" role="tab" aria-controls="table-quantitative" aria-selected="true">
                                                    Quantitative
                                                </button>
                                            </li>
                                            <li className="nav-item fs-5" role="presentation">
                                                <button className="nav-link text-dark" id="table-frequency-tab" data-bs-toggle="tab" data-bs-target="#table-frequency" type="button" role="tab" aria-controls="table-frequency" aria-selected="false">
                                                    Frequency
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="table-quantitative" role="tabpanel" aria-labelledby="table-quantitative-tab">
                                                <div className="table-responsive mt-3">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Answer</th>
                                                                <th>Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chartApiResponse?.map((item, index) =>
                                                                <>
                                                                    {item.result.map((resultItem, i) => (
                                                                        <tr key={`${index}-${i}`}>
                                                                            <td>{resultItem.answer}</td>
                                                                            <td>{resultItem.value}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr>
                                                                        <td colSpan={2}><b>Total Value: {item.totalSubmission}</b></td>
                                                                    </tr>
                                                                </>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="table-frequency" role="tabpanel" aria-labelledby="table-frequency-tab">
                                                <div className="table-responsive mt-3">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Answer</th>
                                                                <th>Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chartApiResponse?.map((item, index) =>
                                                                <>
                                                                    {item.result.map((resultItem, i) => (
                                                                        <tr key={`${index}-${i}`}>
                                                                            <td>{resultItem.answer}</td>
                                                                            <td>{((resultItem.value / item.totalSubmission) * 100).toFixed(2)}%</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr>
                                                                        <td colSpan={2}><b>Total Value: {item.totalSubmission}</b></td>
                                                                    </tr>
                                                                </>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="col-md-4">
                    {data?.sections?.length ? (
                        <>
                            <div className="accordion" id="accordionExample">
                                {data?.sections?.map((section, sectionIndex) => {
                                    const sectionRadioQuestions = section?.questions?.filter((question) => question.type === "radio");
                                    const hasSectionRadioQuestions = sectionRadioQuestions.length > 0;

                                    return hasSectionRadioQuestions ? (
                                        <div className="accordion-item" key={section._id}>
                                            <h2 className="accordion-header" id={`heading-${sectionIndex}`}>
                                                <button
                                                    className="accordion-button fs-4"
                                                    type="button"
                                                    onClick={() => toggleSection(section._id)}
                                                    aria-expanded={openSections[section._id] ? "true" : "false"}
                                                >
                                                    {section.title}
                                                </button>
                                            </h2>

                                            <div
                                                id={`collapse-${sectionIndex}`}
                                                className={`accordion-collapse collapse ${openSections[section._id] ? "show" : ""}`}
                                                aria-labelledby={`heading-${sectionIndex}`}
                                            >
                                                <div className="accordion-body fs-4">
                                                    {sectionRadioQuestions.map((question) => (
                                                        <div key={question._id}>
                                                            <label className="d-flex align-items-center gap-2">
                                                                <input
                                                                    type="radio"
                                                                    name="selectedQuestion"
                                                                    value={question._id}
                                                                    checked={selectedQuestionId === question._id}
                                                                    onChange={() => handleQuestionSelect(question._id)}
                                                                />
                                                                {` ${question.text}`}
                                                            </label>
                                                        </div>
                                                    ))}

                                                    {section?.subsections?.map((subsection, subIndex) => {
                                                        const subsectionRadioQuestions = subsection?.questions?.filter((question) => question.type === "radio");
                                                        const hasSubsectionRadioQuestions = subsectionRadioQuestions.length > 0;

                                                        return hasSubsectionRadioQuestions ? (
                                                            <div className="accordion mt-3" id={`subAccordion-${sectionIndex}`} key={subsection._id}>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id={`subHeading-${sectionIndex}-${subIndex}`}>
                                                                        <button
                                                                            className="accordion-button collapsed fs-4"
                                                                            type="button"
                                                                            onClick={() => toggleSection(subsection._id)}
                                                                            aria-expanded={openSections[subsection._id] ? "true" : "false"}
                                                                        >
                                                                            {subsection.title}
                                                                        </button>
                                                                    </h2>

                                                                    <div
                                                                        id={`subCollapse-${sectionIndex}-${subIndex}`}
                                                                        className={`accordion-collapse collapse ${openSections[subsection._id] ? "show" : ""}`}
                                                                        aria-labelledby={`subHeading-${sectionIndex}-${subIndex}`}
                                                                    >
                                                                        <div className="accordion-body fs-4">
                                                                            {subsectionRadioQuestions.map((question) => (
                                                                                <div key={question._id}>
                                                                                    <label className="d-flex align-items-center gap-2">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="selectedQuestion"
                                                                                            value={question._id}
                                                                                            checked={selectedQuestionId === question._id}
                                                                                            onChange={() => handleQuestionSelect(question._id)}
                                                                                        />
                                                                                        {` ${question.text}`}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            <div className="text-end">
                                <Button
                                    label="Filter"
                                    className="themeBtn mt-3"
                                    onClick={filterChartWithApi}
                                    loading={filterChartByAnswersLoading}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default QuestionnairesCollapse;