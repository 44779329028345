import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { logo } from '../../assets/imagesPath';
import { useForgotPasswordMutation } from '../../services/Api';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '../../components/button/Button';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [forgotPassword, { isLoading: forgotPasswordLoading }] = useForgotPasswordMutation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email is invalid')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (value) => {
        const data = {
            email: value?.email,
            password: value?.password
        };

        forgotPassword({ data: data })
            .unwrap()
            .then((payload) => {
                toast.success(payload?.message);
                navigate(PATHS.otp, { state: { data: value?.email } });
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="text-center">
                        <img src={logo} alt="" className="img-fluid" />
                    </div>

                    <div className="mt-5">
                        <h5>Email</h5>
                        <input type="email" className="themeInput" placeholder="Email" {...register('email')} />
                        {errors.email && <p className="errorMsg">{errors.email.message}</p>}
                    </div>

                    <div className="text-center">
                        <Button
                            label="Submit"
                            className="mt-5 w-50 themeBtn"
                            loading={forgotPasswordLoading}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default ForgotPassword;
