import { SplitApiSettings } from './SplitApiSetting';
import { API_END_POINTS } from './ApiEndpoints';

export const api = SplitApiSettings.injectEndpoints({
    reducerPath: 'api',
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        /////////////////////////////<===MUTATIONS===>//////////////////////////////
        login: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.login,
                method: 'POST',
                body: data
            })
        }),

        addUser: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.usersCrud,
                method: 'POST',
                body: data
            })
        }),

        editUser: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.usersCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteUser: builder.mutation({
            query: ({ data }) => ({
                url: `${API_END_POINTS.usersCrud}/${data}`,
                method: 'DELETE'
            })
        }),

        addCountry: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.countryCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editCountry: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.countryCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteCountry: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.countryCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addStates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.statesCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editStates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.statesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteStates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.statesCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addCities: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.citiesCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editCities: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.citiesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteCities: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.citiesCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addDistricts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.districtsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editDistricts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.districtsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteDistricts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.districtsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addTehsils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.tehsilsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editTehsils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.tehsilsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteTehsils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.tehsilsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addUnionCouncils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.unionCouncilsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editUnionCouncils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.unionCouncilsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteUnionCouncils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.unionCouncilsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addVillages: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.villagesCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editVillages: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.villagesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteVillages: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.villagesCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addPartners: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.partnersCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editPartners: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.partnersCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deletePartners: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.partnersCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addCenters: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.centersCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editCenters: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.centersCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteCenters: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.centersCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addProjects: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.projectsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editProjects: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.projectsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteProjects: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.projectsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addTextQuestions: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editTextQuestions: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteQuestions: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.questionsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addOtherQuestions: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editOtherQuestions: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        uploadGuideFile: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadGuideFile,
                    method: 'POST',
                    body: data
                };
            }
        }),

        addQuestionnaires: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionnairesCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editQuestionnaires: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionnairesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteQuestionnaires: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.questionnairesCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addAssesments: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.assesmentsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editAssesments: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.assesmentsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteAssesments: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.assesmentsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        uploadCountries: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadCountries,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadStates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadStates,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadCities: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadCities,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadDistricts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadDistricts,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadTehsils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadTehsils,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadUnionCouncils: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadUnionCouncils,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadVillages: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadVillages,
                    method: 'POST',
                    body: data
                };
            }
        }),

        forgotPassword: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.forgotPassword,
                    method: 'POST',
                    body: data
                };
            }
        }),

        verifyOtp: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.verifyOtp,
                    method: 'POST',
                    body: data
                };
            }
        }),

        newPassword: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.newPassword,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        emailTemplate: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.emailTemplatesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        submitAnswers: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.answersCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        startNewRecord: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.startNewRecord,
                    method: 'POST',
                    body: data
                };
            }
        }),

        questionnaireRecord: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.questionnaireRecord,
                    method: 'POST',
                    body: data
                };
            }
        }),

        uploadTeacherAvatar: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.uploadTeacherAvatar,
                    method: 'POST',
                    body: data
                };
            }
        }),

        addTeacher: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.teachersCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editTeacher: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.teachersCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteTeacher: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.teachersCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        filterChartByAnswers: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.filterChartByAnswers,
                    method: 'POST',
                    body: data
                };
            }
        }),

        addContacts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editContacts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteContacts: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.contactsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addContactsEmail: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactEmailsCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editContactsEmail: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactEmailsCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteContactsEmail: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.contactEmailsCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        addContactEmailTemplates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactEmailTemplatesCrud,
                    method: 'POST',
                    body: data
                };
            }
        }),

        editContactEmailTemplates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactEmailTemplatesCrud,
                    method: 'PUT',
                    body: data
                };
            }
        }),

        deleteContactEmailTemplates: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `${API_END_POINTS.contactEmailTemplatesCrud}/${data}`,
                    method: 'DELETE',
                    body: data
                };
            }
        }),

        sendEmails: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactSentEmails,
                    method: 'POST',
                    body: data
                };
            }
        }),

        /////////////////////////////<===QUERIES===>////////////////////////////////

        getUsers: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.usersCrudList,
                    method: 'GET'
                };
            }
        }),

        getPaginatedUsers: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.usersCrud,
                    method: 'GET',
                    params
                };
            }
        }),

        getCountries: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.countryCrud,
                    method: 'GET'
                };
            }
        }),

        getStates: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.statesCrud,
                    method: 'GET'
                };
            }
        }),

        getCities: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.citiesCrud,
                    method: 'GET'
                };
            }
        }),

        getDistricts: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.districtsCrud,
                    method: 'GET'
                };
            }
        }),

        getTehsils: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.tehsilsCrud,
                    method: 'GET'
                };
            }
        }),

        getUnionCouncils: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.unionCouncilsCrud,
                    method: 'GET'
                };
            }
        }),

        getVillages: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.villagesCrud,
                    method: 'GET'
                };
            }
        }),

        getPartners: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.partnersCrud,
                    method: 'GET'
                };
            }
        }),

        getCenters: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.centersCrud,
                    method: 'GET'
                };
            }
        }),

        getProjects: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.projectsCrud,
                    method: 'GET'
                };
            }
        }),

        getAllQuestions: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.questionsCrud,
                    method: 'GET'
                };
            }
        }),

        getQuestionnaires: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.questionnairesCrud,
                    method: 'GET'
                };
            }
        }),

        getQuestionnairesById: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.questionnairesCrud}/${params?.params?.id}?userId=${params?.params?.userId ?? ''}&assessmentId=${params?.params?.assessmentId ?? ''}&recordId=${params?.params?.recordId ?? ''}`,
                    method: 'GET'
                };
            }
        }),

        getAssesments: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.assesmentsCrud,
                    method: 'GET'
                };
            }
        }),

        viewAssesmentDetail: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.assesmentsCrud}/${params?.id}`,
                    method: 'GET'
                };
            }
        }),

        getEmailTemplates: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.emailTemplatesCrud,
                    method: 'GET'
                };
            }
        }),

        getEmailTemplateByID: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.emailTemplatesCrud}/${params?.id}`,
                    method: 'GET'
                };
            }
        }),

        getCollectorAssesments: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.getCollectorAssesments}/${params?.id}`,
                    method: 'GET'
                };
            }
        }),

        getCollectorRecords: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.getCollectorRecords}/${params?.id}/collectorId/${params?.collectorId}`,
                    method: 'GET'
                };
            }
        }),

        getCollectorStatus: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.getCollectorStatus}/${params?.id}`,
                    method: 'GET'
                };
            }
        }),

        assessmentRecord: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.startNewRecord,
                    method: 'GET',
                    params
                };
            }
        }),

        getTeachers: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.teachersCrud,
                    method: 'GET',
                };
            }
        }),

        getAssesmentsByCountry: builder.query({
            query: ({ params }) => {
                return {
                    url: `${API_END_POINTS.getAssesmentsByCountry}/${params?.id}`,
                    method: 'GET'
                };
            }
        }),

        getContacts: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.contactsCrud,
                    method: 'GET',
                };
            }
        }),

        getContactsEmail: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.contactEmailsCrud,
                    method: 'GET',
                };
            }
        }),

        getContactEmailTemplates: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.contactEmailTemplatesCrud,
                    method: 'GET',
                };
            }
        }),

        getSentEmails: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.contactSentEmails,
                    method: 'GET',
                };
            }
        }),

        dashboardCount: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.dashboardCount,
                    method: 'GET',
                };
            }
        }),

    }),

    overrideExisting: true
});

export const {
    /////////////////////////////<===MUTATIONS===>//////////////////////////////
    useLoginMutation,
    useAddUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useAddCountryMutation,
    useEditCountryMutation,
    useDeleteCountryMutation,
    useAddStatesMutation,
    useEditStatesMutation,
    useDeleteStatesMutation,
    useAddCitiesMutation,
    useEditCitiesMutation,
    useDeleteCitiesMutation,
    useAddDistrictsMutation,
    useEditDistrictsMutation,
    useDeleteDistrictsMutation,
    useAddTehsilsMutation,
    useEditTehsilsMutation,
    useDeleteTehsilsMutation,
    useAddUnionCouncilsMutation,
    useEditUnionCouncilsMutation,
    useDeleteUnionCouncilsMutation,
    useAddVillagesMutation,
    useEditVillagesMutation,
    useDeleteVillagesMutation,
    useAddPartnersMutation,
    useEditPartnersMutation,
    useDeletePartnersMutation,
    useAddCentersMutation,
    useEditCentersMutation,
    useDeleteCentersMutation,
    useAddProjectsMutation,
    useEditProjectsMutation,
    useDeleteProjectsMutation,
    useAddTextQuestionsMutation,
    useEditTextQuestionsMutation,
    useDeleteQuestionsMutation,
    useAddOtherQuestionsMutation,
    useEditOtherQuestionsMutation,
    useAddQuestionnairesMutation,
    useEditQuestionnairesMutation,
    useDeleteQuestionnairesMutation,
    useAddAssesmentsMutation,
    useEditAssesmentsMutation,
    useDeleteAssesmentsMutation,
    useUploadCountriesMutation,
    useUploadStatesMutation,
    useUploadCitiesMutation,
    useUploadDistrictsMutation,
    useUploadTehsilsMutation,
    useUploadUnionCouncilsMutation,
    useUploadVillagesMutation,
    useForgotPasswordMutation,
    useVerifyOtpMutation,
    useNewPasswordMutation,
    useUploadGuideFileMutation,
    useEmailTemplateMutation,
    useSubmitAnswersMutation,
    useStartNewRecordMutation,
    useQuestionnaireRecordMutation,
    useUploadTeacherAvatarMutation,
    useAddTeacherMutation,
    useEditTeacherMutation,
    useDeleteTeacherMutation,
    useFilterChartByAnswersMutation,
    useAddContactsMutation,
    useEditContactsMutation,
    useDeleteContactsMutation,
    useAddContactsEmailMutation,
    useEditContactsEmailMutation,
    useDeleteContactsEmailMutation,
    useAddContactEmailTemplatesMutation,
    useEditContactEmailTemplatesMutation,
    useDeleteContactEmailTemplatesMutation,
    useSendEmailsMutation,
    /////////////////////////////<===QUERIES===>////////////////////////////////
    useGetUsersQuery,
    useGetPaginatedUsersQuery,
    useGetCountriesQuery,
    useGetStatesQuery,
    useGetCitiesQuery,
    useGetDistrictsQuery,
    useGetTehsilsQuery,
    useGetUnionCouncilsQuery,
    useGetVillagesQuery,
    useGetPartnersQuery,
    useGetCentersQuery,
    useGetProjectsQuery,
    useGetAllQuestionsQuery,
    useGetQuestionnairesQuery,
    useGetQuestionnairesByIdQuery,
    useGetAssesmentsQuery,
    useViewAssesmentDetailQuery,
    useGetEmailTemplatesQuery,
    useGetEmailTemplateByIDQuery,
    useGetCollectorAssesmentsQuery,
    useGetCollectorRecordsQuery,
    useGetCollectorStatusQuery,
    useAssessmentRecordQuery,
    useGetTeachersQuery,
    useGetAssesmentsByCountryQuery,
    useGetContactsQuery,
    useGetContactsEmailQuery,
    useGetContactEmailTemplatesQuery,
    useGetSentEmailsQuery,
    useDashboardCountQuery,
} = api;
