import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAssessmentRecordQuery, useQuestionnaireRecordMutation } from '../../services/Api';
import moment from 'moment/moment';
import { loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import { BASE_URL_FILES } from '../../services/ApiEndpoints';
import PATHS from '../../routes/Paths';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ViewAssesmentForConductor = () => {

    const [startIndex, setStartIndex] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const assesmentData = location?.state?.data;
    const assesmentDataRecordId = location?.state?.recordId == 0 ? "0" : location?.state?.recordId;
    const userId = location?.state?.userId;
    const auth = useSelector((data) => data?.auth);
    const role = auth?.userDetail?.role;
    const newRecordData = location?.state?.newRecord;

    const {
        data: assessmentRecord,
        isLoading: assessmentRecordLoading,
        refetch: assessmentRecordRefetch,
    } = useAssessmentRecordQuery({ params: { assessmentId: newRecordData?.assessment || assesmentData?.id, collectorId: newRecordData?.user || userId, recordId: newRecordData?.recordId || assesmentDataRecordId } });

    let dueDate = moment(assessmentRecord?.dueDate).format("YYYY-MM-DD");

    const [questionnaireRecord, { isLoading: questionnaireRecordLoading }] = useQuestionnaireRecordMutation();

    const questionnaireRecordApiHandler = (value, index) => {

        setStartIndex(index);

        const data = {
            assessment: assesmentData?.id || newRecordData?.assessment,
            user: userId || newRecordData?.user,
            recordId: assesmentDataRecordId || newRecordData?.recordId,
            questionnaire: value?._id,
        };

        questionnaireRecord({ data: data })
            .unwrap()
            .then(() => {
                navigate(PATHS.startAssesmentForConductor, { state: { id: value?._id, assesmentId: assesmentData?.id || newRecordData?.assessment, recordId: assesmentDataRecordId || newRecordData?.recordId } });
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        assessmentRecordRefetch();
    }, []);

    return (
        <>
            {assessmentRecordLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row'>

                    <div className="col-12 mb-5">
                        <div className="assesDiv">
                            <div className='d-flex align-items-start justify-content-between'>
                                <div>
                                    <h3>Title</h3>
                                    <p>{assessmentRecord?.title}</p>
                                </div>

                                <Button
                                    label={assessmentRecord?.status?.replace(/_/g, " ")}
                                    className={`${assessmentRecord?.status == "pending" ? "bg-warning" : assessmentRecord?.status == "in_progress" ? "bg-info" : assessmentRecord?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                />
                            </div>

                            <div className='mt-4'>
                                <h3>Description</h3>
                                <p>{assessmentRecord?.description}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Record Id</h3>
                                <p>{newRecordData?.recordId}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Project</h3>
                                <p>{assessmentRecord?.project}</p>
                            </div>

                            <div className="row mt-4">
                                <div className='col-md-4'>
                                    <h3>Country</h3>
                                    <p>{assessmentRecord?.country}</p>
                                </div>
                                <div className="col-md-4">
                                    <h3>Partner</h3>
                                    <p>{assessmentRecord?.partner}</p>
                                </div>
                                <div className="col-md-4">
                                    <h3>Submitted At</h3>
                                    <p>{dueDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link text-dark active" data-bs-toggle="tab" href="#Questionnaires"><h2 className="fs-1">Questionnaires</h2></a>
                            </li>
                            {role == 2003 ? null :
                                <li class="nav-item">
                                    <a class="nav-link text-dark" data-bs-toggle="tab" href="#Users"><h2 className="fs-1">Collectors</h2></a>
                                </li>
                            }
                        </ul>

                        <div class="tab-content">

                            <div class="tab-pane active" id="Questionnaires">
                                {assessmentRecord?.questionnaires?.map((data, index) => {
                                    return (
                                        <div className="col-12 mb-4 mt-5" key={index}>
                                            <div className="assesDiv">
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div>
                                                        <h3>Title</h3>
                                                        <p>{data?.title}</p>
                                                    </div>

                                                    <div className='d-flex align-items-start gap-3'>
                                                        {data?.guideFile ?
                                                            <a href={BASE_URL_FILES + data?.guideFile} target="_blank">
                                                                <Button
                                                                    label="View User Guide"
                                                                    className="themeBtn"
                                                                />
                                                            </a>
                                                            : null}

                                                        {data?.status == "completed" ?
                                                            <Button
                                                                label="View"
                                                                className="themeBtn bg-warning border-0"
                                                                onClick={() => navigate(PATHS.viewSolvedAssesment, { state: { data: data, recordId: assesmentDataRecordId, userId: userId, assessmentId: assesmentData?.id } })}
                                                            />
                                                            :
                                                            <Button
                                                                label="Start"
                                                                className="themeBtn bg-success border-0"
                                                                onClick={() => questionnaireRecordApiHandler(data, index)}
                                                                loading={startIndex == index && questionnaireRecordLoading}
                                                            />
                                                        }

                                                        <Button
                                                            label={data?.status?.replace(/_/g, " ")}
                                                            className={`${data?.status == "pending" ? "bg-warning" : data?.status == "in_progress" ? "bg-info" : data?.status == "draft" ? "bg-danger" : ""} themeBtn text-capitalize border-0`}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <h3>Description</h3>
                                                    <p>{data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>

                    </div>

                </div>
            }
        </>
    )
}

export default ViewAssesmentForConductor;