import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetPartnersQuery, useGetProjectsQuery, useViewAssesmentDetailQuery } from '../../services/Api';
import moment from 'moment/moment';
import { avatar, loader } from '../../assets/imagesPath';
import AllQuestionsComponent from '../../components/questions/AllQuestionsComponent';

const ViewAssesment = () => {

    const [partnerName, setPartnerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const location = useLocation();
    const assesmentData = location?.state?.data;

    const {
        data: viewAssesmentDetail,
        refetch: viewAssesmentDetailRefetch,
        isLoading: viewAssesmentDetailLoading,
    } = useViewAssesmentDetailQuery({ params: { id: assesmentData?.id } });

    const {
        data: getPartners,
        refetch: getPartnersRefetch,
    } = useGetPartnersQuery();

    const {
        data: getProjects,
        refetch: getProjectsRefetch,
    } = useGetProjectsQuery();

    let dueDate = moment(viewAssesmentDetail?.dueDate).format("YYYY-MM-DD");

    const updateName = (type, value) => {

        const allEntities = {
            partner: getPartners,
            project: getProjects,
        };

        const setNameFunctions = {
            partner: setPartnerName,
            project: setProjectName,
        };

        const entities = allEntities[type];
        const setName = setNameFunctions[type];

        if (entities) {
            const entity = entities?.find(entity => entity?.id == value);
            if (entity) {
                setName(entity?.name);
            } else {
                setName(`${type.charAt(0).toUpperCase() + type.slice(1)} not found`);
            }
        } else {
            setName(`${type.charAt(0).toUpperCase() + type.slice(1)} data not available`);
        }
    };

    useEffect(() => {
        if (getPartners && getProjects) {
            updateName('partner', viewAssesmentDetail?.partner);
            updateName('project', viewAssesmentDetail?.project);
        }
    }, [getPartners, getProjects]);


    useEffect(() => {
        viewAssesmentDetailRefetch();
        getPartnersRefetch();
        getProjectsRefetch();
    }, []);

    const [answers, setAnswers] = useState([]);

    const handleChange = (questionId, value, checked, type) => {
        setAnswers(prevAnswers => {
            const answerIndex = prevAnswers.findIndex(answer => answer?.questionId == questionId);
            let newAnswers = [...prevAnswers];

            if (type == 'checkbox') {
                if (answerIndex > -1) {
                    const currentAnswer = newAnswers[answerIndex].answer;
                    if (checked) {
                        newAnswers[answerIndex].answer = [...currentAnswer, value];
                    } else {
                        newAnswers[answerIndex].answer = currentAnswer?.filter(val => val != value);
                    }
                } else {
                    newAnswers?.push({ questionnaireId: "667e987a9448a098c40773b6", questionId, answer: [value] });
                }
            } else {
                const answer = (type == 'radio' || type == 'select') ? [value] : value;
                if (answerIndex > -1) {
                    newAnswers[answerIndex].answer = answer;
                } else {
                    newAnswers?.push({ questionnaireId: "667e987a9448a098c40773b6", questionId, answer });
                }
            }

            return newAnswers;
        });
    };

    return (
        <>
            {viewAssesmentDetailLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row'>
                    <div className="col-md-12 mb-3">
                        <label className="fs-4 mb-3">Title</label>
                        <input type="text" className="themeInput fs-4" placeholder='Title' value={viewAssesmentDetail?.title} disabled />
                    </div>

                    <div className="col-md-12 mb-3">
                        <label className="fs-4 mb-3">Description</label>
                        <textarea type="text" className="themeInput themeTextarea fs-4" placeholder='Description' value={viewAssesmentDetail?.description} disabled />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">Due Date</label>
                        <input type="text" className="themeInput fs-4" value={dueDate} disabled />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">Partner</label>
                        <input type="text" className="themeInput fs-4" value={partnerName} disabled />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">Project</label>
                        <input type="text" className="themeInput fs-4" value={projectName} disabled />
                    </div>

                    <div className="col-md-6 mb-3">
                        <label className="fs-4 mb-3">No. of Questionnaires</label>
                        <input type="text" className="themeInput fs-4" value={viewAssesmentDetail?.questionnaires?.length} disabled />
                    </div>

                    <div className="col-md-12 mb-3">
                        <label className="fs-4 mb-3">Assigned to Users</label>
                        <div className="row">
                            {viewAssesmentDetail?.users?.map((data, index) => {
                                return (
                                    <div className="col-md-4" key={index}>
                                        <div class="card p-3">
                                            <div className='text-center'>
                                                <img src={avatar} alt="" className='rounded-circle' height={80} width={80} />
                                            </div>
                                            <h5 class="fs-4 mt-3 text-capitalize">Name: <span className='fw-light'>{data?.user?.firstName} {data?.user?.lastName}</span></h5>
                                            <h5 class="fs-4 mt-3">Email: <span className='fw-light'>{data?.user?.email}</span></h5>
                                            <h5 class="fs-4 mt-3">Designation: <span className='fw-light'>{data?.user?.designation}</span></h5>
                                            <h5 class="fs-4 mt-3 text-capitalize">Status: <span className='fw-light'>{data?.status.replace("_", " ")}</span></h5>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h2 className="fs-1 text-center">Questionnaires Detail</h2>
                    </div>

                    {viewAssesmentDetail?.questionnaires?.map((data, index) => {
                        return (
                            <div className="row mt-5">
                                <h3 className="fs-1 mb-3">Questionnaire {index + 1}</h3>

                                <div className="col-md-12 mb-4">
                                    <span className="fs-4 mb-3">Title</span>
                                    <input type="text" className="themeInput fs-4" value={data?.title} disabled />
                                </div>

                                <div className="col-md-12 mb-4">
                                    <label className="fs-4 mb-3">Description</label>
                                    <textarea type="text" className="themeInput themeTextarea fs-4" placeholder='Description' value={data?.description} disabled />
                                </div>

                                <h2 className="fs-1 text-center">Questions</h2>

                                <AllQuestionsComponent
                                    questions={data?.questions}
                                    disabled={true}
                                    handleChange={handleChange}
                                />
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}

export default ViewAssesment;