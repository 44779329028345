import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAddPartnersMutation } from "../../services/Api";

const AddContactsEmailModal = (props) => {

    const validationSchema = Yup.object().shape({
        emailTitle: Yup.string().required("Email Title is required"),
        host: Yup.string().required("Host is required"),
        port: Yup.string().required("Port is required"),
        password: Yup.string().required("Password is required"),
        emailUser: Yup.string().required('Email is required').email('Email is invalid'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            emailTitle: values?.emailTitle,
            host: values?.host,
            port: values?.port,
            password: values?.password,
            emailUser: values?.emailUser,
        };

        const dataWithId = {
            emailTitle: values?.emailTitle,
            host: values?.host,
            port: values?.port,
            password: values?.password,
            emailUser: values?.emailUser,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    useEffect(() => {
        if (props?.data) {
            let { emailTitle, host, port, password, email, emailUser } = props?.data;

            reset({ emailTitle: emailTitle, host: host, port: port, password: password, email: email, emailUser: emailUser });
        }
    }, [props?.data]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddContactsEmailModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Contact Email</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Contact Email</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email</label>
                                <input type="text" className="themeInput fs-4" placeholder='Email' {...register("emailUser")} disabled={props?.view ? true : false} />

                                {errors.emailUser && <p className='errorMsg'>{errors.emailUser.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email Title</label>
                                <input type="text" className="themeInput fs-4" placeholder='Email Title' {...register("emailTitle")} disabled={props?.view ? true : false} />

                                {errors.emailTitle && <p className='errorMsg'>{errors.emailTitle.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Password</label>
                                <input type="password" className="themeInput fs-4" placeholder='Password' {...register("password")} disabled={props?.view ? true : false} />

                                {errors.password && <p className='errorMsg'>{errors.password.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Port</label>
                                <input type="text" className="themeInput fs-4" placeholder='Port' {...register("port")} disabled={props?.view ? true : false} />

                                {errors.port && <p className='errorMsg'>{errors.port.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Host</label>
                                <input type="text" className="themeInput fs-4" placeholder='Host' {...register("host")} disabled={props?.view ? true : false} />

                                {errors.host && <p className='errorMsg'>{errors.host.message}</p>}
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={handleSubmit(onSubmit)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddContactsEmailModal;