import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
// import { MultiSelect } from "../multiSelect/MultiSelect";
import { useGetContactEmailTemplatesQuery, useGetContactsEmailQuery, useGetContactsQuery } from "../../services/Api";
import Select from 'react-select';

const SendEmailModal = (props) => {

    // const [selectedContacts, setSelectedContacts] = useState([]);
    // const [selectedData, setSelectedData] = useState([]);
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);

    const {
        data: getContacts,
        refetch: getContactsRefetch,
    } = useGetContactsQuery();

    const {
        data: getContactsEmail,
        refetch: getContactsEmailRefetch,
    } = useGetContactsEmailQuery();

    const {
        data: getContactEmailTemplates,
        refetch: getContactEmailTemplatesRefetch,
    } = useGetContactEmailTemplatesQuery();

    const preprocessData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name || locations?.emailTitle || locations?.subject,
            value: locations?._id,
        }));
    };

    const allContactEmails = preprocessData(getContactsEmail || []);
    const allContactEmailTemplates = preprocessData(getContactEmailTemplates || []);

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'email':
                setSelectedEmail(selectedOption);
                break;
            case 'emailTemplate':
                setEmailTemplate(selectedOption);
                break;
            default:
                break;
        }
    };

    const onSubmit = () => {

        const data = {
            contactIds: props?.ids,
            emailTemplateId: emailTemplate?.value,
            emailId: selectedEmail?.value,
        };

        props?.action(data);
    };

    // const options = getContacts?.map(item => ({
    //     label: item?.name,
    //     value: item?.id
    // }));

    // const handleChange = (selectedOptions) => {
    //     const phones = selectedOptions?.map(option => option?.value);
    //     setSelectedData(phones);
    // };

    useEffect(() => {
        getContactsRefetch();
        getContactsEmailRefetch();
        getContactEmailTemplatesRefetch();
    }, []);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseSendEmailModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">Send Email</h1>
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Contact Email</label>
                                <div className="multiSelect">
                                    <Select
                                        options={allContactEmails}
                                        onChange={handleLocationChange}
                                        className='w-100 me-3'
                                        name="email"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email Template</label>
                                <div className="multiSelect">
                                    <Select
                                        options={allContactEmailTemplates}
                                        onChange={handleLocationChange}
                                        className='w-100 me-3'
                                        name="emailTemplate"
                                    />
                                </div>
                            </div>

                            {/* <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Contacts</label>
                                <div className="multiSelect">
                                    <MultiSelect options={options} value={selectedContacts} onChange={(e) => { setSelectedContacts(e); handleChange(e) }} />
                                </div>
                            </div> */}

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={onSubmit}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default SendEmailModal;