import React, { useEffect, useState } from 'react';
import { useAddContactEmailTemplatesMutation, useEditContactEmailTemplatesMutation } from '../../services/Api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from "../../components/button/Button";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import PATHS from '../../routes/Paths';
window.jQuery = require("jquery");

const AddContactEmailTemplates = () => {

    const [subject, setSubject] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const prevData = location?.state?.data;

    const [addContactEmailTemplates, { isLoading: addContactEmailTemplatesLoading }] = useAddContactEmailTemplatesMutation();
    const [editContactEmailTemplates, { isLoading: editContactEmailTemplatesLoading }] = useEditContactEmailTemplatesMutation();

    const [content, setContent] = useState("");

    const emailTemplateApiHandler = () => {

        const data = {
            subject: subject,
            html: content,
        };

        addContactEmailTemplates({ data: data })
            .unwrap()
            .then(() => {
                toast.success("Template added successfully");
                navigate(PATHS.contactEmailTemplates);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editEmailmailTemplateApiHandler = () => {

        const dataWithId = {
            subject: subject,
            html: content,
            id: prevData?.id,
        };

        editContactEmailTemplates({ data: dataWithId })
            .unwrap()
            .then(() => {
                toast.success("Template updated successfully");
                navigate(PATHS.contactEmailTemplates);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        if (prevData) {
            setSubject(prevData?.subject);
            setContent(prevData?.html);
        }
    }, [prevData]);

    const handleChange = (content) => {
        setContent(content);
    };

    return (
        <>
            <div className="row">

                <div className="col-12 text-end mb-5">
                    <Button
                        label="Save"
                        className="themeBtn"
                        onClick={prevData ? editEmailmailTemplateApiHandler : emailTemplateApiHandler}
                        loading={addContactEmailTemplatesLoading || editContactEmailTemplatesLoading}
                    />
                </div>

                <div className="col-12 mb-5">
                    <label className="fs-4 mb-3">Subject</label>
                    <input type="text" className="themeInput fs-4" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} value={subject} />
                </div>

                <div className="col-12">
                    <ReactSummernote
                        value={content}
                        options={{
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                                // ['style', ['style']],
                                ['font', ['bold', 'underline', 'clear']],
                                // ['fontname', ['fontname']],
                                ['para', ['ul', 'ol']],
                                // ['table', ['table']],
                                ['insert', ['link', 'picture', 'video']],
                                ['view', ['fullscreen', 'codeview']]
                            ]
                        }}
                        onChange={handleChange}
                    />

                    {/* <h1 className='mt-5'>PREVIEW</h1>
                    <div
                        className="col-12 border border-dark"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div> */}
                </div>
            </div>
        </>
    )
};

export default AddContactEmailTemplates;