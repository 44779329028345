import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { halfLogo } from '../../assets/imagesPath';
import { useLoginMutation } from '../../services/Api';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loggedIn } from '../../redux/AuthSlice';
import Button from '../../components/button/Button';
import { jwtDecode } from 'jwt-decode';

const LoginPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading: loginLoading }] = useLoginMutation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email is invalid'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (value) => {
        const data = {
            email: value?.email,
            password: value?.password
        };

        login({ data: data })
            .unwrap()
            .then((payload) => {
                if (payload.success) {
                    const decoded = jwtDecode(payload.token);
                    const userDetail = {
                        id: decoded?.userId,
                        name: decoded?.name,
                        role: decoded?.role,
                        isActive: decoded?.isActive,
                        permissions: payload?.permissions,
                    };
                    const response = {
                        token: payload.token,
                        user: userDetail,
                    };
                    dispatch(loggedIn(response));
                    toast.success('User logged in successfully');

                    if (decoded?.role == 8989) {
                        navigate(PATHS.dashboard);
                    } else if (decoded?.role == 2003) {
                        navigate(PATHS.assesmentsById);
                    } else if (payload?.permissions?.length > 0) {
                        const firstPermission = payload?.permissions[0];
                        const path = PATHS[firstPermission] || PATHS.dashboard;
                        navigate(path);
                    } else {
                        navigate(PATHS.dashboard);
                    }

                }
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="text-center">
                        <img src={halfLogo} alt="" className="img-fluid" />
                    </div>

                    <div className="mt-5">
                        <h5>Email</h5>
                        <input type="email" className="themeInput" placeholder="Email" {...register('email')} />
                        {errors.email && <p className="errorMsg">{errors.email.message}</p>}
                    </div>

                    <div className="mt-3">
                        <h5>Password</h5>
                        <input type="password" className="themeInput" placeholder="Password" {...register('password')} />
                        {errors.password && <p className="errorMsg">{errors.password.message}</p>}
                    </div>

                    <Link to={PATHS.forgotPassword}>
                        <h5 className="mt-4 text-end cursor" style={{ width: 'fit-content', float: 'right' }}>
                            Forgot Password?
                        </h5>
                    </Link>

                    <div className="text-center mt-5">
                        <Button label="Submit" className="mt-5 w-100 themeBtn" loading={loginLoading} onClick={handleSubmit(onSubmit)} />
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default LoginPage;
