import React from 'react';
import CheckboxQuestion from './CheckboxQuestion';
import SelectQuestion from './SelectQuestion';
import RadioQuestion from './RadioQuestion';
import TextQuestion from './TextQuestion';

const AllQuestionsComponent = ({ questions, handleChange, disabled }) => {
    return (
        <div>
            {questions?.map(question => {
                switch (question?.type) {
                    case 'checkbox':
                        return <CheckboxQuestion key={question?._id} question={question} handleChange={handleChange} disabled={disabled} />;
                    case 'select':
                        return <SelectQuestion key={question?._id} question={question} handleChange={handleChange} disabled={disabled} />;
                    case 'radio':
                        return <RadioQuestion key={question?._id} question={question} handleChange={handleChange} disabled={disabled} />;
                    case 'text':
                        return <TextQuestion key={question?._id} question={question} handleChange={handleChange} disabled={disabled} />;
                    default:
                        return null;
                }
            })}
        </div>
    )
}

export default AllQuestionsComponent;