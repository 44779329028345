import React from 'react';
import ViewQuestion from './ViewQuestion';

const ViewSubsection = ({ sectionId, subsection, responses }) => {
    return (
        <div className='subSectionDiv'>
            <h3 className='sectionHeader p-4'>{subsection?.title}</h3>
            <div className='ps-4 pe-4'>
                {subsection?.questions?.map(question => (
                    <ViewQuestion
                        key={question?._id}
                        sectionId={sectionId}
                        subsectionId={subsection?._id}
                        question={question}
                        responses={responses}
                    />
                ))}
            </div>
        </div>
    );
};

export default ViewSubsection;