import React from 'react';
import ModalComponent from '../modalSetup/Modal';
import { Modal } from 'react-bootstrap';
import Button from '../button/Button';
import { BASE_URL_IMAGES } from '../../services/ApiEndpoints';
import { avatar } from '../../assets/imagesPath';

const ViewUserModal = (props) => {
    const user = props?.data;

    return (
        <ModalComponent size="lg" show={true} handleClose={props.handleCloseViewUserModal}>
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center">View User</h2>
                        <div className="row mt-4">
                            <div className="col-12 mb-3 text-center">
                                <img
                                    src={BASE_URL_IMAGES + user?.avatar || avatar}
                                    className="rounded-circle"
                                    height={100}
                                    width={100}
                                    alt=""
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Role</label>
                                <input
                                    type="text"
                                    className="themeInput fs-4"
                                    placeholder="First Name"
                                    value={user?.role == '8989' ? 'Admin' : user?.role == '2000' ? 'Consultant' : 'Data Collector'}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Status</label>
                                <input
                                    type="text"
                                    className="themeInput fs-4"
                                    placeholder="Last Name"
                                    value={user?.isActive ? 'Active' : 'Inactive'}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">First Name</label>
                                <input type="text" className="themeInput fs-4" placeholder="First Name" value={user?.firstName} readOnly />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Last Name</label>
                                <input type="text" className="themeInput fs-4" placeholder="Last Name" value={user?.lastName} readOnly />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email</label>
                                <input type="text" className="themeInput fs-4" placeholder="Email" value={user?.email} readOnly />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Phone</label>
                                <input type="text" className="themeInput fs-4" placeholder="Phone" value={user?.phoneNo} readOnly />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Qualification</label>
                                <input
                                    type="text"
                                    className="themeInput fs-4"
                                    placeholder="Qualification"
                                    value={user?.qualification}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Designation</label>
                                <input
                                    type="text"
                                    className="themeInput fs-4"
                                    placeholder="Designation"
                                    value={user?.designation}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="text-center">
                            <Button label="Close" className="mt-5 themeBtn" onClick={() => props.handleCloseViewUserModal()} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default ViewUserModal;
