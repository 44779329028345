import React, { useEffect, useState } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAddPartnersMutation, useGetCountriesQuery } from "../../services/Api";
import { toast } from "react-toastify";
import Select from 'react-select';

const AddContactModal = (props) => {

    const [sectors, setSectors] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        type: Yup.string().required("Type is required"),
        organizationName: Yup.string().required("Organization Name is required"),
        organizationType: Yup.string().required("Organization Type is required"),
        title: Yup.string().required("Title is required"),
        phoneNo: Yup.string().required("Phone is required"),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        verifiedEmail: Yup.string().required('Email is required').email('Email is invalid'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const allCountries = preprocessLocationData(getCountries || []);

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            default:
                break;
        }
    };

    const onSubmit = (values) => {

        const data = {
            title: values?.title,
            name: values?.name,
            type: values?.type,
            organizationName: values?.organizationName,
            sector: sectors,
            organizationType: values?.organizationType,
            phoneNo: values?.phoneNo,
            email: values?.email,
            verifiedEmail: values?.verifiedEmail,
            country: selectedCountry?.label || props?.data?.country,
        };

        const dataWithId = {
            title: values?.title,
            name: values?.name,
            type: values?.type,
            organizationName: values?.organizationName,
            sector: sectors,
            organizationType: values?.organizationType,
            phoneNo: values?.phoneNo,
            email: values?.email,
            verifiedEmail: values?.verifiedEmail,
            country: selectedCountry?.label || props?.data?.country,
            id: props?.data?._id,
        };

        props?.action(props?.data ? dataWithId : data);
    };

    useEffect(() => {
        if (props?.data) {
            let { name, type, organizationName, title, phoneNo, email, verifiedEmail, sector } = props?.data;

            reset({ name: name, type: type, organizationName: organizationName, title: title, phoneNo: phoneNo, email: email, verifiedEmail: verifiedEmail });

            setSectors(sector);
        }
    }, [props?.data]);

    useEffect(() => {
        getCountriesRefetch();
    }, []);

    const sectorOptions = [
        { value: "Education", label: "Education" },
        { value: "Health and Nutrition", label: "Health and Nutrition" },
        { value: "Infrastructure and Engineering", label: "Infrastructure and Engineering" },
        { value: "Climate Change and Environment", label: "Climate Change and Environment" },
        { value: "Water, Sanitation & Waste Management", label: "Water, Sanitation & Waste Management" },
        { value: "Monitoring, Evaluation and Learning", label: "Monitoring, Evaluation and Learning" },
        { value: "ICT Based Survey & Research/Digitization", label: "ICT Based Survey & Research/Digitization" },
        { value: "Agriculture & Rural development", label: "Agriculture & Rural development" },
        { value: "Supplies, contract & Procurement", label: "Supplies, contract & Procurement" },
        { value: "Advocacy & Strategy", label: "Advocacy & Strategy" },
        { value: "Energy", label: "Energy" },
        { value: "Humanterian Assistance", label: "Humanterian Assistance" },
        { value: "Crisis and Peacebuilding", label: "Crisis and Peacebuilding" },
        { value: "Democracy & Governance", label: "Democracy & Governance" },
        { value: "International Development", label: "International Development" },
        { value: "Human Resource Development", label: "Human Resource Development" },
    ];

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddContactModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        {props?.view ?
                            <h1 className="text-center">View Contact</h1>
                            :
                            <h1 className="text-center">{props?.data ? "Edit" : "Add"} Contact</h1>
                        }
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Name' {...register("name")} disabled={props?.view ? true : false} />

                                {errors.name && <p className='errorMsg'>{errors.name.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Title</label>
                                <input type="text" className="themeInput fs-4" placeholder='Title' {...register("title")} disabled={props?.view ? true : false} />

                                {errors.title && <p className='errorMsg'>{errors.title.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Type</label>
                                <input type="text" className="themeInput fs-4" placeholder='Type' {...register("type")} disabled={props?.view ? true : false} />

                                {errors.type && <p className='errorMsg'>{errors.type.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Email</label>
                                <input type="text" className="themeInput fs-4" placeholder='Email' {...register("email")} disabled={props?.view ? true : false} />

                                {errors.email && <p className='errorMsg'>{errors.email.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Verify Email</label>
                                <input type="text" className="themeInput fs-4" placeholder='Verify Email' {...register("verifiedEmail")} disabled={props?.view ? true : false} />

                                {errors.verifiedEmail && <p className='errorMsg'>{errors.verifiedEmail.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Phone</label>
                                <input type="text" className="themeInput fs-4" placeholder='Phone' {...register("phoneNo")} disabled={props?.view ? true : false} />

                                {errors.phoneNo && <p className='errorMsg'>{errors.phoneNo.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Sector</label>
                                <select className="themeInput fs-4" onChange={(e) => setSectors(e.target.value)} value={sectors} disabled={props?.view ? true : false}>
                                    <option value="">{props?.data ? props?.data?.industry : "Select sector"}</option>
                                    {sectorOptions?.map((data, index) => {
                                        return (
                                            <option value={data?.value} key={index}>{data?.label}</option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Organization Name</label>
                                <input type="text" className="themeInput fs-4" placeholder='Organization Name' {...register("organizationName")} disabled={props?.view ? true : false} />

                                {errors.organizationName && <p className='errorMsg'>{errors.organizationName.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Organization Type</label>
                                <input type="text" className="themeInput fs-4" placeholder='Organization Type' {...register("organizationType")} disabled={props?.view ? true : false} />

                                {errors.organizationType && <p className='errorMsg'>{errors.organizationType.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className='fs-4 mb-3'>Country</label>
                                <Select
                                    options={allCountries}
                                    onChange={handleLocationChange}
                                    className='w-100 me-3'
                                    name="country"
                                    placeholder={props?.data ? props?.data?.country : "Select"}
                                    isDisabled={props?.view ? true : false}
                                />
                            </div>

                        </div>

                        {!props?.view ?
                            <div className="text-center">
                                <Button
                                    label="Submit"
                                    className="mt-5 themeBtn"
                                    loading={props?.loading}
                                    onClick={handleSubmit(onSubmit)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddContactModal;