import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useDeleteTeacherMutation, useGetCountriesQuery, useGetTeachersQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { active, deactive, del, edit, loader, view } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import PATHS from "../../routes/Paths";
import DeleteModal from "../../components/allModals/DeleteModal";

const Teachers = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewTeacherData, setViewTeacherData] = useState('');
    const [status, setStatus] = useState('all');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const navigate = useNavigate();

    const {
        data: getTeachers,
        refetch: getTeachersRefetch,
        isLoading: getTeachersLoading,
    } = useGetTeachersQuery();

    const {
        data: getCountries,
        refetch: getCountriesRefetch,
    } = useGetCountriesQuery();

    const handleLocationChange = (selectedOption, { name }) => {
        switch (name) {
            case 'country':
                setSelectedCountry(selectedOption);
                break;
            default:
                break;
        }
    };

    const preprocessLocationData = (locations) => {
        return locations?.map(locations => ({
            label: locations?.name,
            value: locations?._id,
        }));
    };

    const allCountries = preprocessLocationData(getCountries || []);

    const itemsPerPage = 20;
    const totalItems = getTeachers?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewTeacherData(data);
    };

    const [deleteTeacher, { isLoading: deleteTeacherLoading }] = useDeleteTeacherMutation();

    const deleteTeachersApiHandler = (id) => {
        deleteTeacher({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getTeachersRefetch();
                toast.success("Teacher deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    // const handleStatusChange = (event) => {
    //     setStatus(event.target.value);
    // };

    useEffect(() => {
        const applyFilters = () => {
            let filtered = getTeachers || [];
            if (selectedCountry) {
                filtered = filtered?.filter(partner => partner?.country == selectedCountry?.value);
            }
            if (status != 'all') {
                filtered = filtered.filter(partner => partner.isActive == (status == 'active'));
            }
            setFilteredData(filtered);
        };

        applyFilters();
    }, [status, selectedCountry, getTeachers]);

    useEffect(() => {
        getTeachersRefetch();
        getCountriesRefetch();
    }, []);

    return (
        <>
            <div>

                <h1 className='mb-4'>Teachers</h1>

                <div className='row'>
                    <div className='col-md-3'>
                        <input
                            id='localSearchInput'
                            className='themeInput w-100'
                            placeholder="Search teacher"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => localSearchTableFunction(e.target.value)}
                        />
                    </div>
                    {/* <div className='col-md-3'>
                        <select className='themeInput w-100' value={status} onChange={handleStatusChange}>
                            <option value="all">Filter by Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div> */}
                    <div className='col-md-3'>
                        <Select
                            options={allCountries}
                            onChange={handleLocationChange}
                            className='w-100 me-3 fs-4'
                            name="country"
                            placeholder="Filter by Country"
                        />
                    </div>
                    <div className='col-md-6 text-end'>
                        <Button
                            label="+ Add Teacher"
                            className="themeBtn"
                            onClick={() => navigate(PATHS.addTeacher)}
                        />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Teacher Name</th>
                            <th>CNIC</th>
                            <th>Gender</th>
                            <th>Joining Date</th>
                            {/* <th>Village Name</th> */}
                            <th>Contact No.</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.length ? filteredData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index}
                                // onClick={() => viewCentersModalHandler(data)}
                                >
                                    <td>{index + 1}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.cnic}</td>
                                    <td>{data?.gender}</td>
                                    <td>{moment(data?.joiningDate).format("DD-MM-YYYY")}</td>
                                    <td>{data?.telephoneNo}</td>
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <div className='d-flex align-items-center gap-3'>
                                            {/* <img src={view} alt="" className='actionIcons' title='View' /> */}
                                            <img src={edit} alt="" className='actionIcons' title='Edit' onClick={() => navigate(PATHS.editTeacher, { state: { data: data } })} />
                                            <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={7}>
                                {getTeachersLoading ?
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                    : <h2 className='text-center mt-5'>No Record Found</h2>}
                            </td>
                        </tr>}

                        <tr>
                            <td colSpan={7}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Teacher"
                        id={viewTeacherData?._id}
                        action={deleteTeachersApiHandler}
                        loading={deleteTeacherLoading}
                    />
                }
            </div>
        </>
    )
}

export default Teachers;