import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoginMutation, useNewPasswordMutation } from '../../services/Api';
import { userLogout } from '../../redux/AuthSlice';
import { eyeClose, eyeOpen, logo } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import PATHS from '../../routes/Paths';

const ResetPassword = () => {

    const [eyeOpenBtn, setEyeOpenBtn] = useState(false);
    const [eyeCloseBtn, setEyeCloseBtn] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
        // .min(8, 'Password must be at least 8 characters')
        // .matches(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        //     'Password must have a capital digit, a small digit, a number and a special character'
        // )
        ,
        password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Password not matched'
        ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const [newPassword, { isLoading: newPasswordLoading }] = useNewPasswordMutation();

    const onSubmit = (value) => {

        const data = {
            newPassword: value?.password
        };

        newPassword({ data: data })
            .unwrap()
            .then((payload) => {
                if (payload.success) {
                    toast.success("Password changed successfully");
                    dispatch(userLogout(null));
                    navigate(PATHS.signin);
                }
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    return (
        <div className='container mt-5'>
            <div className='row'>
                <div className="col-3"></div>
                <div className="col-6">
                    <div className='text-center'>
                        <img src={logo} alt="" className='img-fluid' />
                    </div>

                    <div className="position-relative mb-5 mt-5">
                        <input type={`${!eyeOpenBtn ? "password" : "text"}`} className='themeInput' placeholder='Password' {...register('password')} />
                        <img src={eyeOpenBtn ? eyeClose : eyeOpen} alt="" className='eyeBtn' onClick={() => setEyeOpenBtn(!eyeOpenBtn)} />

                        {errors.password && (
                            <p className='errorMsg'>{errors.password.message}</p>
                        )}
                    </div>

                    <div className="position-relative">
                        <input type={`${!eyeCloseBtn ? "password" : "text"}`} className='themeInput' placeholder='Confirm password' {...register('password_confirmation')} />
                        <img src={eyeCloseBtn ? eyeClose : eyeOpen} alt="" className='eyeBtn' onClick={() => setEyeCloseBtn(!eyeCloseBtn)} />

                        {errors.password_confirmation && (
                            <p className='errorMsg'>{errors.password_confirmation.message}</p>
                        )}
                    </div>

                    <div className="col-md-12 text-center">
                        <Button
                            label="Submit"
                            className="mt-5 w-25 themeBtn"
                            loading={newPasswordLoading}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    )
}

export default ResetPassword;