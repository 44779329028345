import React, { memo, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListItemIcon, Typography } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import NoteIcon from '@mui/icons-material/Note';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhoneIcon from '@mui/icons-material/Phone';
import RatingIcon from '@mui/icons-material/Star';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialpadIcon from '@mui/icons-material/Dialpad';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const options = [
    { value: 'text', label: 'Text', icon: <TextFieldsIcon /> },
    { value: 'note', label: 'Note', icon: <NoteIcon /> },
    { value: 'radio', label: 'Select One', icon: <RadioButtonCheckedIcon /> },
    { value: 'checkbox', label: 'Select Multiple', icon: <CheckBoxIcon /> },
    { value: 'file-upload', label: 'File upload', icon: <AttachFileIcon /> },
    { value: 'date', label: 'Date', icon: <DateRangeIcon /> },
    { value: 'time', label: 'Time', icon: <AccessTimeIcon /> },
    { value: 'date-time', label: 'Date & time', icon: <DateRangeIcon /> },
    { value: 'phone-number', label: 'Phone number', icon: <PhoneIcon /> },
    { value: 'number', label: 'Number', icon: <DialpadIcon /> },
    { value: 'rating', label: 'Rating', icon: <RatingIcon /> }
    // { label: 'Note', icon: <NoteIcon /> },
];

function CustomSelect({ value, onChange }) {
    return (
        <FormControl fullWidth>
            <Select
                variant="standard"
                value={value}
                onChange={onChange}
                // renderValue={(selected) => {
                //     const option = options.find((option) => option.label === selected);
                //     return (
                //         <div style={{ display: 'flex', alignItems: 'center' }}>
                //             {option && option.icon}
                //             <span style={{ marginLeft: 8 }}>{selected?.label}</span>
                //         </div>
                //     );
                // }}
            >
                {options.map((option) => (
                    <MenuItem key={option?.label} value={option?.value}>
                        <ListItemIcon>{option?.icon}</ListItemIcon>
                        <Typography variant="inherit">{option?.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default memo(CustomSelect);
