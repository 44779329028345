import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetQuestionnairesByIdQuery } from '../../services/Api';
import { loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import { BASE_URL_FILES } from '../../services/ApiEndpoints';
import { useSelector } from 'react-redux';
import ViewSection from './ViewSection';
import PATHS from '../../routes/Paths';

const ViewSolvedAssesment = () => {

    const location = useLocation();
    const [responses, setResponses] = useState({});
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const auth = useSelector((data) => data?.auth);
    const role = auth?.userDetail?.role;
    const navigate = useNavigate();

    const {
        data: getQuestionnairesById,
        refetch: getQuestionnairesByIdRefetch,
        isLoading: getQuestionnairesByIdLoading,
    } = useGetQuestionnairesByIdQuery({ params: { id: location?.state?.data?._id, userId: location?.state?.userId, assessmentId: location?.state?.assessmentId, recordId: location?.state?.recordId } });

    const handleNext = () => {
        if (currentSectionIndex < getQuestionnairesById?.sections?.length - 1) {
            setCurrentSectionIndex(currentSectionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentSectionIndex > 0) {
            setCurrentSectionIndex(currentSectionIndex - 1);
        }
    };

    useEffect(() => {
        getQuestionnairesByIdRefetch();
    }, []);

    useEffect(() => {
        if (getQuestionnairesById) {
            setResponses(getQuestionnairesById);
        }
    }, [getQuestionnairesById]);

    return (
        <>
            {getQuestionnairesByIdLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div>
                :
                <div className='row mt-5'>
                    <div className="col-12 mb-4">
                        <div className="assesDiv">
                            <div className=''>
                                <h3>Title</h3>
                                <p>{getQuestionnairesById?.title}</p>
                            </div>

                            <div className='mt-4'>
                                <h3>Description</h3>
                                <p className='mb-0'>{getQuestionnairesById?.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {getQuestionnairesById?.sections?.map((section, index) => (
                            <div style={{ display: index === currentSectionIndex ? 'block' : 'none' }}>
                                <ViewSection
                                    key={section?._id}
                                    section={section}
                                    responses={responses}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="col-12 text-end mb-5">
                        {currentSectionIndex > 0 && (
                            <Button
                                label="← Previous"
                                className="themeBtn me-5"
                                onClick={handlePrevious}
                            />
                        )}
                        {currentSectionIndex < getQuestionnairesById?.sections?.length - 1 ? (
                            <Button
                                label="Next →"
                                className="themeBtn"
                                onClick={handleNext}
                            />
                        ) : null}

                        {/* (
                        <Button
                            label="Close"
                            className="themeBtn"
                            onClick={() => navigate(role == 2003 ? PATHS.assesmentsById : PATHS.assesments)}
                        />
                        ) */}
                    </div>
                </div>
            }
        </>
    )
}

export default ViewSolvedAssesment;