import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { useGetCollectorRecordsQuery, useStartNewRecordMutation } from '../../services/Api';
import { loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import { toast } from 'react-toastify';

const AssesmentsForCouductor = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const assesmentData = location?.state?.data;
    const userId = location?.state?.userId;

    const {
        data: getCollectorRecords,
        refetch: getCollectorRecordsRefetch,
        isLoading: getCollectorRecordsLoading,
    } = useGetCollectorRecordsQuery({ params: { id: assesmentData?._id, collectorId: userId } });

    const [startNewRecord, { isLoading: startNewRecordLoading }] = useStartNewRecordMutation();

    const startNewRecordApiHandler = () => {

        const data = {
            assessment: assesmentData?._id,
            user: userId,
        };

        startNewRecord({ data: data })
            .unwrap()
            .then((payload) => {
                navigate(PATHS.viewAssesmentForConductor, { state: { newRecord: payload } });
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    useEffect(() => {
        getCollectorRecordsRefetch();
    }, []);

    return (
        <>
            {getCollectorRecordsLoading ?
                <div className='text-center'>
                    <img
                        src={loader}
                        height={100}
                    />
                </div> :
                <div className='col-12'>

                    <div className='d-flex align-items-center justify-content-between mb-5'>
                        <h1 className='mb-0'>Assesments</h1>

                        <div className='d-flex align-items-center gap-3'>
                            {/* <Button
                                label="View Questionnaire"
                                className="themeBtn"
                                onClick={() => navigate(PATHS.viewQuestionnaire, { state: { data: location?.state?.data, userId: auth?.userDetail?.id, assessmentId: assesmentData?._id } })}
                            /> */}

                            {(getCollectorRecords != undefined && getCollectorRecords?.length) < location?.state?.data?.numberOfRecords ?
                                <Button
                                    label="Start New Record"
                                    className="themeBtn"
                                    onClick={startNewRecordApiHandler}
                                    loading={startNewRecordLoading}
                                />
                                : null}
                        </div>
                    </div>

                    <Table className="no-wrap mt-3 align-middle" responsive borderless>
                        <thead>
                            <tr>
                                <th>Record Id</th>
                                <th>Assesment Status</th>
                                <th>Questionnaire Completed</th>
                                <th>Questionnaire InProgress</th>
                                <th>Questionnaire Not Started</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getCollectorRecords?.length ? getCollectorRecords?.map((data, index) => (
                                <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.viewAssesmentForConductor, { state: { data: location?.state?.data, recordId: data?.recordId, userId: userId } })}>
                                    <td>{data?.recordId}</td>
                                    <td className='text-capitalize'>{data?.status?.replace(/_/g, " ")}</td>
                                    <td>{data?.questionnaireCompleted}</td>
                                    <td>{data?.questionnaireInProgress}</td>
                                    <td>{data?.questionnaireNotStarted}</td>
                                </tr>
                            )) : <tr>
                                {getCollectorRecordsLoading ?
                                    <td colSpan={7}>
                                        <div className='text-center'>
                                            <img src={loader} height={100} alt="" />
                                        </div>
                                    </td>
                                    : <td colSpan={7}>
                                        <h2 className='text-center mt-5'>No Record Found</h2>
                                    </td>
                                }
                            </tr>}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    );
}

export default AssesmentsForCouductor;