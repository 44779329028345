import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useGetCollectorAssesmentsQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { loader } from '../../assets/imagesPath';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

const AssesmentsById = () => {

    const [currentPageForConductor, setCurrentPageForConductor] = useState(1);
    const navigate = useNavigate();
    const auth = useSelector((data) => data?.auth);
    const location = useLocation();

    const {
        data: getCollectorAssesments,
        refetch: getCollectorAssesmentsRefetch,
        isLoading: getCollectorAssesmentsLoading,
    } = useGetCollectorAssesmentsQuery({ params: { id: auth?.userDetail?.id } });

    const itemsPerPageForConductor = 20;
    const totalItemsForConductor = getCollectorAssesments?.items?.length;
    const totalPagesForConductor = Math.ceil(totalItemsForConductor / itemsPerPageForConductor);

    const onPageChangeForConductor = (page) => {
        setCurrentPageForConductor(page);
    };

    const paginatedDataForConductor = getCollectorAssesments?.items?.slice(
        (currentPageForConductor - 1) * itemsPerPageForConductor,
        currentPageForConductor * itemsPerPageForConductor
    );

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    useEffect(() => {
        getCollectorAssesmentsRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Assesments</h1>

                <div className='d-flex justify-content-between align-items-center'>
                    <input
                        id='localSearchInput'
                        className='themeInput w-50'
                        placeholder="Search Assesments"
                        type="search"
                        autoComplete="off"
                        onChange={(e) => localSearchTableFunction(e.target.value)}
                    />
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Partner</th>
                            <th>Project</th>
                            <th>Country</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Records</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedDataForConductor?.length ? paginatedDataForConductor?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.assesmentsForCouductor, { state: { data: data, userId: auth?.userDetail?.id } })}>
                                    <td>{data?.title}</td>
                                    <td>{data?.partner?.name}</td>
                                    <td>{data?.project?.name}</td>
                                    <td>{data?.country?.name}</td>
                                    <td>{moment(data?.dueDate).format("DD-MM-YYYY")}</td>
                                    <td className='text-capitalize'>{data?.status}</td>
                                    <td>{data?.numberOfRecords}</td>
                                </tr>
                            )
                        }) : <tr>
                            {getCollectorAssesmentsLoading ?
                                <td colSpan={7}>
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                </td>
                                : <td colSpan={7}>
                                    <h2 className='text-center mt-5'>No Record Found</h2>
                                </td>
                            }
                        </tr>}

                        <tr>
                            <td colSpan={7}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPageForConductor} totalPages={totalPagesForConductor} onPageChange={onPageChangeForConductor} />

            </div>
        </>
    )
}

export default AssesmentsById;