import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import PaginationComponent from "../../components/pagination/Pagination";
import { dots, loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import { useAddOtherQuestionsMutation, useAddTextQuestionsMutation, useDeleteQuestionsMutation, useEditOtherQuestionsMutation, useEditTextQuestionsMutation, useGetAllQuestionsQuery } from '../../services/Api';
import AddTextQuestionModal from '../../components/allModals/AddTextQuestionModal';
import AddOtherQuestionModal from '../../components/allModals/AddOtherQuestionModal';

const Questions = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [viewTextQuestionModal, setViewTextQuestionModal] = useState(false);
    const [addTextQuestionModal, setAddTextQuestionModal] = useState(false);
    const [editTextQuestionModal, setEditTextQuestionModal] = useState(false);

    const [viewOtherQuestionModal, setViewOtherQuestionModal] = useState(false);
    const [addOtherQuestionModal, setAddOtherQuestionModal] = useState(false);
    const [editOtherQuestionModal, setEditOtherQuestionModal] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [viewTextQuestionData, setViewTextQuestionData] = useState("");
    const [questionType, setQuestionType] = useState("");

    const {
        data: getAllQuestions,
        refetch: getAllQuestionsRefetch,
        isLoading: getAllQuestionsLoading,
    } = useGetAllQuestionsQuery();

    const itemsPerPage = 20;
    const totalItems = getAllQuestions?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getAllQuestions?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const viewTextQuestionsModalHandler = (data) => {
        setViewTextQuestionModal((prev) => !prev);
        setViewTextQuestionData(data);
    };

    const addTextQuestionsModalHandler = () => {
        setAddTextQuestionModal((prev) => !prev);
    };

    const editTextQuestionsModalHandler = (data) => {
        setEditTextQuestionModal((prev) => !prev);
        setViewTextQuestionData(data);
    };

    const viewOtherQuestionsModalHandler = (data) => {
        setViewOtherQuestionModal((prev) => !prev);
        setViewTextQuestionData(data);
    };

    const addOtherQuestionsModalHandler = (type) => {
        setAddOtherQuestionModal((prev) => !prev);
        setQuestionType(type);
    };

    const editOtherQuestionsModalHandler = (data) => {
        setEditOtherQuestionModal((prev) => !prev);
        setViewTextQuestionData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewTextQuestionData(data);
    };

    const [addTextQuestions, { isLoading: addTextQuestionsLoading }] = useAddTextQuestionsMutation();
    const [editTextQuestions, { isLoading: editTextQuestionsLoading }] = useEditTextQuestionsMutation();
    const [deleteQuestions, { isLoading: deleteQuestionsLoading }] = useDeleteQuestionsMutation();

    const [addOtherQuestions, { isLoading: addOtherQuestionsLoading }] = useAddOtherQuestionsMutation();
    const [editOtherQuestions, { isLoading: editOtherQuestionsLoading }] = useEditOtherQuestionsMutation();

    const addTextQuestionApiHandler = (data) => {
        addTextQuestions({ data: data })
            .unwrap()
            .then(() => {
                addTextQuestionsModalHandler();
                getAllQuestionsRefetch();
                toast.success("Question added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const addOtherQuestionApiHandler = (data) => {
        addOtherQuestions({ data: data })
            .unwrap()
            .then(() => {
                addOtherQuestionsModalHandler();
                getAllQuestionsRefetch();
                toast.success("Question added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editOtherQuestionApiHandler = (data) => {
        editOtherQuestions({ data: data })
            .unwrap()
            .then(() => {
                editOtherQuestionsModalHandler();
                getAllQuestionsRefetch();
                toast.success("Question updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editTextQuestionsApiHandler = (data) => {
        editTextQuestions({ data: data })
            .unwrap()
            .then(() => {
                editTextQuestionsModalHandler();
                getAllQuestionsRefetch();
                toast.success("Question updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteQuestionsApiHandler = (id) => {
        deleteQuestions({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getAllQuestionsRefetch();
                toast.success("Question deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    useEffect(() => {
        getAllQuestionsRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Questions</h1>

                <input
                    id='localSearchInput'
                    className='themeInput w-50 mb-5'
                    placeholder="Search Question"
                    type="search"
                    autoComplete="off"
                    onChange={(e) => localSearchTableFunction(e.target.value)}
                />

                <div className='d-flex justify-content-between align-items-center'>
                    <Button
                        label="+ Add Text Question"
                        className="themeBtn"
                        onClick={addTextQuestionsModalHandler}
                    />
                    <Button
                        label="+ Add Radio Question"
                        className="themeBtn"
                        onClick={() => addOtherQuestionsModalHandler("radio")}
                    />
                    <Button
                        label="+ Add Checkbox Question"
                        className="themeBtn"
                        onClick={() => addOtherQuestionsModalHandler("checkbox")}
                    />
                    <Button
                        label="+ Add Select Question"
                        className="themeBtn"
                        onClick={() => addOtherQuestionsModalHandler("select")}
                    />
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Question</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length ? paginatedData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data?.text}</td>
                                    <td>{data?.type}</td>
                                    <td>
                                        <div className="dropdown">
                                            <button className="btn btn-transparent table-dropdown-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img
                                                    src={dots}
                                                    className="img-fluid dots"
                                                    height={20}
                                                    width={20}
                                                    alt=""
                                                />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={() => viewOtherQuestionsModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >View Question</a>
                                                </li>
                                                <li onClick={() => data?.type == 'text' ? editTextQuestionsModalHandler(data) : editOtherQuestionsModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >Edit Question</a>
                                                </li>
                                                <li onClick={() => deleteModalHandler(data)}>
                                                    <a className="dropdown-item cursor fs-4" >Delete Question</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            {getAllQuestionsLoading ?
                                <td colSpan={7}>
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                </td>
                                : <h2 className='text-center mt-5'>No Record Found</h2>}
                        </tr>}

                        <tr>
                            <td colSpan={7}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {addTextQuestionModal &&
                    <AddTextQuestionModal
                        handleCloseAddTextQuestionModal={addTextQuestionsModalHandler}
                        action={addTextQuestionApiHandler}
                        loading={addTextQuestionsLoading}
                    />
                }

                {editTextQuestionModal &&
                    <AddTextQuestionModal
                        handleCloseAddTextQuestionModal={editTextQuestionsModalHandler}
                        action={editTextQuestionsApiHandler}
                        loading={editTextQuestionsLoading}
                        data={viewTextQuestionData}
                    />
                }

                {viewTextQuestionModal &&
                    <AddTextQuestionModal
                        handleCloseAddTextQuestionModal={viewTextQuestionsModalHandler}
                        data={viewTextQuestionData}
                        view={true}
                    />
                }

                {addOtherQuestionModal &&
                    <AddOtherQuestionModal
                        handleCloseAddOtherQuestionModal={addOtherQuestionsModalHandler}
                        type={questionType}
                        action={addOtherQuestionApiHandler}
                        loading={addOtherQuestionsLoading}
                    />
                }

                {editOtherQuestionModal &&
                    <AddOtherQuestionModal
                        handleCloseAddOtherQuestionModal={editOtherQuestionsModalHandler}
                        data={viewTextQuestionData}
                        action={editOtherQuestionApiHandler}
                        loading={editOtherQuestionsLoading}
                    />
                }

                {viewOtherQuestionModal &&
                    <AddOtherQuestionModal
                        handleCloseAddOtherQuestionModal={viewOtherQuestionsModalHandler}
                        data={viewTextQuestionData}
                        view={true}
                    />
                }

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Question"
                        id={viewTextQuestionData?._id}
                        action={deleteQuestionsApiHandler}
                        loading={deleteQuestionsLoading}
                    />
                }
            </div>
        </>
    )
}

export default Questions;