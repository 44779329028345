import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import PATHS from "../routes/Paths";
import { avatar } from "../assets/imagesPath";
import { userLogout } from "../redux/AuthSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const logoutFunction = () => {
    dispatch(userLogout(null));
    navigate(PATHS.signin);
    toast.success("User logged out successfully");
  };

  return (
    <Navbar className="appNavbar" expand="md">
      <div className="d-flex align-items-center">
        <h2 style={{ color: "#fff", marginTop: 7, marginLeft: 20 }}>Project Monitoring Dashboard</h2>
        <Button
          color="primary"
          className="d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen} className="justify-content-end">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle>
            <img
              src={avatar}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            {/* <Link to={PATHS.profile}>
              <DropdownItem className="fs-5">Profile</DropdownItem>
            </Link>
            <DropdownItem divider /> */}
            <DropdownItem onClick={logoutFunction} className="fs-5">
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;
