import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { logo } from '../../assets/imagesPath';
import { useVerifyOtpMutation } from '../../services/Api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button from '../../components/button/Button';
import OTPInput from 'react-otp-input';
import { jwtDecode } from 'jwt-decode';
import { loggedIn } from '../../redux/AuthSlice';

const Otp = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [verifyOtp, { isLoading: verifyOtpLoading }] = useVerifyOtpMutation();

    const {
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({
        mode: 'onTouched'
    });

    const onSubmit = () => {
        const data = {
            email: location?.state?.data,
            otp: otp
        };

        verifyOtp({ data: data })
            .unwrap()
            .then((payload) => {
                const decoded = jwtDecode(payload.token);
                // const userDetail = {
                //     id: decoded?.userId,
                //     name: decoded?.name,
                //     role: decoded?.role,
                //     isActive: decoded?.isActive,
                // }
                const response = {
                    token: payload.token
                    // user: userDetail,
                };
                dispatch(loggedIn(response));
                toast.success('OTP verified successfully');
                navigate(PATHS.resetPassword);
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const validationHandler = () => {
        otpValidationHandler();

        if (otp) {
            onSubmit();
        } else {
            return false;
        }
    };

    const otpValidationHandler = () => {
        if (!otp) {
            setError('otp', { type: 'custom', message: 'OTP is required' });
        } else {
            clearErrors('otp');
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="text-center">
                        <img src={logo} alt="" className="img-fluid" />
                    </div>

                    <div className="mt-5">
                        <h5>Email</h5>
                        <input type="email" className="themeInput" placeholder="Email" value={location?.state?.data} readOnly />
                    </div>

                    <h5 className="mt-5">OTP</h5>
                    <div className="otpBox">
                        <OTPInput
                            value={otp}
                            inputType="number"
                            onChange={setOtp}
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                        />
                        {errors.otp && <p className="errorMsg mt-4">{errors.otp.message}</p>}
                    </div>

                    <div className="text-center">
                        <Button label="Submit" className="mt-5 w-50 themeBtn" loading={verifyOtpLoading} onClick={validationHandler} />
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default Otp;
