import React from 'react';

const TextQuestion = ({ question, handleChange, disabled }) => {

    const handleTextChange = (event) => {
        handleChange(question.id, event.target.value, null, 'text');
    };

    return (
        <div>
            <h3 className='fs-2 mt-4'>{question?.text}</h3>
            <input
                type="text"
                className="themeInput fs-4"
                id={question?.id}
                name={question?.id}
                onChange={handleTextChange}
                disabled={disabled}
            />
        </div>
    );
};

export default TextQuestion;