// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// export const SplitApiSettings = createApi({
//   baseQuery: fetchBaseQuery({
//     prepareHeaders: async (headers, { getState }) => {
//       try {
//         const token = getState().auth?.token;
//         if (token) {
//           headers.set("authorization", `Bearer ${token}`);
//           headers.set("accept", "application/json");
//           // headers.set("Content-Type", "application/x-www-form-urlencoded");
//         } else {
//           headers.set("authorization", "");
//         }
//       } catch (err) {
//         headers.set("authorization", "");
//       }
//       return headers;
//     },
//   }),
//   endpoints: () => ({}),
//   tagTypes: [],
// });

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userLogout } from '../redux/AuthSlice';

const baseQuery = fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
        try {
            const token = getState().auth?.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('accept', 'application/json');
            } else {
                headers.set('authorization', '');
            }
        } catch (err) {
            headers.set('authorization', '');
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status == 401) {
        api.dispatch(userLogout(null));
        window.location.replace('/signin');
    }
    return result;
};

export const SplitApiSettings = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    tagTypes: []
});
