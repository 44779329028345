import './App.css';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes/Router';

const App = () => {
    return <Router />;
};

export default App;
