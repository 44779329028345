import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from '../../../routes/Paths';
import { useGetCollectorRecordsQuery } from '../../../services/Api';
import { useSelector } from 'react-redux';
import { loader } from '../../../assets/imagesPath';

const ViewConductorAssesmentByAdmin = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const assesmentData = location?.state?.data;
    const auth = useSelector((data) => data?.auth);
    const userId = location?.state?.userId;

    const {
        data: getCollectorRecords,
        refetch: getCollectorRecordsRefetch,
        isLoading: getCollectorRecordsLoading,
    } = useGetCollectorRecordsQuery({ params: { id: assesmentData?._id, collectorId: userId } });

    useEffect(() => {
        getCollectorRecordsRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <div className='d-flex align-items-center justify-content-between mb-5'>
                    <h1 className='mb-0'>{location?.state?.name}'s Assesment Detail</h1>
                </div>

                <Table className="no-wrap mt-3 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>Record Id</th>
                            <th>Assesment Status</th>
                            <th>Questionnaire Completed</th>
                            <th>Questionnaire InProgress</th>
                            <th>Questionnaire Not Started</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCollectorRecords?.length ? getCollectorRecords?.map((data, index) => (
                            <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.viewAssesmentDetailByAdmin, { state: { assessmentId: assesmentData?._id, recordId: data?.recordId, collectorId: userId } })}>
                                <td>{data?.recordId}</td>
                                <td className='text-capitalize'>{data?.status?.replace(/_/g, " ")}</td>
                                <td>{data?.questionnaireCompleted}</td>
                                <td>{data?.questionnaireInProgress}</td>
                                <td>{data?.questionnaireNotStarted}</td>
                            </tr>
                        )) : <tr>
                            {getCollectorRecordsLoading ?
                                <td colSpan={7}>
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                </td>
                                : <td colSpan={7}>
                                    <h2 className='text-center mt-5'>No Record Found</h2>
                                </td>
                            }
                        </tr>}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default ViewConductorAssesmentByAdmin;