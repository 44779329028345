import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useAddUserMutation, useDeleteUserMutation, useEditUserMutation, useGetPaginatedUsersQuery } from '../../services/Api';
import { active, avatar, deactive, del, edit, loader, view } from '../../assets/imagesPath';
import ViewUserModal from '../../components/allModals/ViewUserModal';
import AddUserModal from '../../components/allModals/AddUserModal';
import Button from '../../components/button/Button';
import DeleteModal from '../../components/allModals/DeleteModal';
import { toast } from 'react-toastify';
import ChangeStatusModal from '../../components/allModals/ChangeStatusModal';
import { BASE_URL_IMAGES } from '../../services/ApiEndpoints';

const Users = () => {
    const [viewUserModal, setViewUserModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [editUserModal, setEditUserModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [viewUserData, setViewUserData] = useState('');
    const [status, setStatus] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize] = useState(10);
    const [querySearch, setQuerySearch] = useState('');
    const [role, setRole] = useState('');

    const { data: getPaginatedUsers, refetch: getPaginatedUsersRefetch, isLoading: getPaginatedUsersLoading } = useGetPaginatedUsersQuery({
        params:
        {
            pageNumber,
            pageSize,
            querySearch,
            isActive: status,
            role,
        }
    });

    // const { data: getCountries, refetch: getCountriesRefetch } = useGetCountriesQuery();

    // const handleLocationChange = (selectedOption, { name }) => {
    //     switch (name) {
    //         case 'country':
    //             setSelectedCountry(selectedOption);
    //             break;
    //         default:
    //             break;
    //     }
    // };

    // const preprocessLocationData = (locations) => {
    //     return locations?.map((locations) => ({
    //         label: locations?.name,
    //         value: locations?.value
    //     }));
    // };

    // const allCountries = preprocessLocationData(getCountries || []);

    const viewUserModalHandler = (data) => {
        setViewUserModal((prev) => !prev);
        setViewUserData(data);
    };

    const addUserModalHandler = () => {
        setAddUserModal((prev) => !prev);
    };

    const editUserModalHandler = (data) => {
        setEditUserModal((prev) => !prev);
        setViewUserData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewUserData(data);
    };

    const changeStatusModalHandler = (data) => {
        setChangeStatusModal((prev) => !prev);
        setViewUserData(data);
    };

    const [addUser, { isLoading: addUserLoading }] = useAddUserMutation();
    const [editUser, { isLoading: editUserLoading }] = useEditUserMutation();
    const [deleteUser, { isLoading: deleteUserLoading }] = useDeleteUserMutation();
    const [changeStatus, { isLoading: changeStatusLoading }] = useEditUserMutation();

    const addUserApiHandler = (data) => {
        addUser({ data: data })
            .unwrap()
            .then(() => {
                addUserModalHandler();
                getPaginatedUsersRefetch();
                toast.success('User added successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editUserApiHandler = (data) => {
        editUser({ data: data })
            .unwrap()
            .then(() => {
                editUserModalHandler();
                getPaginatedUsersRefetch();
                toast.success('User updated successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteUserApiHandler = (id) => {
        deleteUser({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getPaginatedUsersRefetch();
                toast.success('User deleted successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const changeStatusApiHandler = (user) => {
        const data = {
            isActive: user?.isActive ? false : true,
            userId: user?.id
        };

        changeStatus({ data: data })
            .unwrap()
            .then(() => {
                changeStatusModalHandler();
                getPaginatedUsersRefetch();
                toast.success('Status changed successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    useEffect(() => {
        getPaginatedUsersRefetch();
    }, []);

    return (
        <>
            <div className="col-12">
                <h1 className="mb-4">Users</h1>

                <div className="row">
                    <div className="col-md-3">
                        <input
                            id="localSearchInput"
                            className="themeInput w-100"
                            placeholder="Search user"
                            type="search"
                            autoComplete="off"
                            onChange={(e) => setQuerySearch(e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <select className="themeInput w-100" value={status} onChange={handleStatusChange}>
                            <option value="">Filter by Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select className="themeInput w-100" value={role} onChange={handleRoleChange}>
                            <option value="">Filter by Role</option>
                            <option value="8989">Admin</option>
                            <option value="2003">Collector</option>
                        </select>
                    </div>
                    <div className="col-md-3 text-end">
                        <Button label="+ Add User" className="themeBtn" onClick={addUserModalHandler} />
                    </div>
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Designation</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getPaginatedUsers?.items?.length ? (
                            getPaginatedUsers?.items?.map((data, index) => {
                                return (
                                    <tr className="border-top mainDiv cursor" key={index} onClick={() => viewUserModalHandler(data)}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={BASE_URL_IMAGES + data?.avatar || avatar}
                                                    className="rounded-circle me-2"
                                                    alt=""
                                                    height={30}
                                                    width={30}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = avatar;
                                                    }}
                                                />
                                                {data?.firstName} {data?.lastName}
                                            </div>
                                        </td>
                                        <td>{data?.email}</td>
                                        <td>{data?.designation}</td>
                                        <td>{data?.role == '8989' ? 'Admin' : data?.role == '2000' ? 'Consultant' : 'Data Collector'}</td>
                                        <td>{data?.isActive ? 'Active' : 'Inactive'}</td>
                                        <td onClick={(e) => e.stopPropagation()}>
                                            <div className='d-flex align-items-center gap-3'>
                                                <img src={view} alt="" className='actionIcons' onClick={() => viewUserModalHandler(data)} title='View' />
                                                <img src={edit} alt="" className='actionIcons' onClick={() => editUserModalHandler(data)} title='Edit' />
                                                <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                                <img src={data?.isActive ? active : deactive} alt="" className='actionIconsd' onClick={() => changeStatusModalHandler(data)} title={data?.isActive ? 'Deactivate' : 'Activate'} style={{ width: "3.5rem" }} />
                                            </div>
                                            {/* <div className="dropdown">
                                                <button
                                                    className="btn btn-transparent table-dropdown-btn"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <img src={dots} className="img-fluid dots" height={20} width={20} alt="" />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li onClick={() => viewUserModalHandler(data)}>
                                                        <a className="dropdown-item cursor fs-4">View User</a>
                                                    </li>
                                                    <li onClick={() => editUserModalHandler(data)}>
                                                        <a className="dropdown-item cursor fs-4">Edit User</a>
                                                    </li>
                                                    <li onClick={() => deleteModalHandler(data)}>
                                                        <a className="dropdown-item cursor fs-4">Delete User</a>
                                                    </li>
                                                    <li onClick={() => changeStatusModalHandler(data)}>
                                                        <a className="dropdown-item cursor fs-4">
                                                            {data?.isActive ? 'Deactivate' : 'Activate'}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={8}>
                                    {getPaginatedUsersLoading ? (
                                        <div className="text-center">
                                            <img src={loader} height={100} alt="" />
                                        </div>
                                    ) : (
                                        <h2 className="text-center mt-5">No Record Found</h2>
                                    )}
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td colSpan={8}>
                                <h2 className="text-center mt-5" id="noRecordsFound" style={{ display: 'none' }}>
                                    No Record Found
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <div className='text-end'>
                    <button
                        onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
                        disabled={getPaginatedUsers?.paginationMetadata?.currentPage == 1}
                        className='themeBtn'
                    >
                        Previous
                    </button>
                    {/* <span>
                        Page {getPaginatedUsers?.paginationMetadata?.currentPage} of {getPaginatedUsers?.paginationMetadata?.totalPages}
                    </span> */}
                    <button
                        onClick={() => setPageNumber((prev) => prev + 1)}
                        disabled={getPaginatedUsers?.paginationMetadata?.currentPage == getPaginatedUsers?.paginationMetadata?.totalPages}
                        className='themeBtn'
                    >
                        Next
                    </button>
                </div>

                {addUserModal && (
                    <AddUserModal handleCloseAddUserModal={addUserModalHandler} action={addUserApiHandler} loading={addUserLoading} />
                )}

                {editUserModal && (
                    <AddUserModal
                        handleCloseAddUserModal={editUserModalHandler}
                        actionUpdate={editUserApiHandler}
                        loading={editUserLoading}
                        data={viewUserData}
                    />
                )}

                {viewUserModal && <ViewUserModal handleCloseViewUserModal={viewUserModalHandler} data={viewUserData} />}

                {deleteModal && (
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="user"
                        id={viewUserData?._id}
                        action={deleteUserApiHandler}
                        loading={deleteUserLoading}
                    />
                )}

                {changeStatusModal && (
                    <ChangeStatusModal
                        handleCloseChangeStatusModal={changeStatusModalHandler}
                        confirmationMessage={`Are you sure you want to ${viewUserData?.isActive ? 'Deactivate' : 'Activate'}`}
                        name={viewUserData?.firstName}
                        id={viewUserData?._id}
                        data={viewUserData}
                        action={changeStatusApiHandler}
                        loading={changeStatusLoading}
                    />
                )}
            </div>
        </>
    );
};

export default Users;
