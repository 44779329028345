import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useAddAssesmentsMutation, useDeleteAssesmentsMutation, useEditAssesmentsMutation, useGetAssesmentsQuery, useGetQuestionnairesQuery, useGetUsersQuery } from '../../services/Api';
import PaginationComponent from "../../components/pagination/Pagination";
import { del, edit, loader } from '../../assets/imagesPath';
import Button from '../../components/button/Button';
import DeleteModal from "../../components/allModals/DeleteModal";
import { toast } from 'react-toastify';
import AddAssesmentModal from '../../components/allModals/AddAssesmentModal';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import moment from 'moment/moment';

const Assesments = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [viewAssesmentModal, setViewAssesmentModal] = useState(false);
    const [addAssesmentModal, setAddAssesmentModal] = useState(false);
    const [editAssesmentModal, setEditAssesmentModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewAssesmentData, setViewAssesmentData] = useState("");
    const navigate = useNavigate();

    const {
        data: getAssesments,
        refetch: getAssesmentsRefetch,
        isLoading: getAssesmentsLoading,
    } = useGetAssesmentsQuery();

    const itemsPerPage = 20;
    const totalItems = getAssesments?.items?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = getAssesments?.items?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const viewAssesmentsModalHandler = (data) => {
        setViewAssesmentModal((prev) => !prev);
        setViewAssesmentData(data);
    };

    const addAssesmentsModalHandler = () => {
        setAddAssesmentModal((prev) => !prev);
    };

    const editAssesmentsModalHandler = (data) => {
        setEditAssesmentModal((prev) => !prev);
        setViewAssesmentData(data);
    };

    const deleteModalHandler = (data) => {
        setDeleteModal((prev) => !prev);
        setViewAssesmentData(data);
    };

    const [addAssesments, { isLoading: addAssesmentsLoading }] = useAddAssesmentsMutation();
    const [editAssesments, { isLoading: editAssesmentsLoading }] = useEditAssesmentsMutation();
    const [deleteAssesments, { isLoading: deleteAssesmentsLoading }] = useDeleteAssesmentsMutation();

    const addAssesmentApiHandler = (data) => {
        addAssesments({ data: data })
            .unwrap()
            .then(() => {
                addAssesmentsModalHandler();
                getAssesmentsRefetch();
                toast.success("Assesment added successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const editAssesmentsApiHandler = (data) => {
        editAssesments({ data: data })
            .unwrap()
            .then(() => {
                editAssesmentsModalHandler();
                getAssesmentsRefetch();
                toast.success("Assesment updated successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const deleteAssesmentsApiHandler = (id) => {
        deleteAssesments({ data: id })
            .unwrap()
            .then(() => {
                deleteModalHandler();
                getAssesmentsRefetch();
                toast.success("Assesment deleted successfully");
            })
            .catch((error) => {
                toast.error(error?.data?.message);
            });
    };

    const localSearchTableFunction = () => {
        const input = document.getElementById("localSearchInput");
        const filter = input.value.toUpperCase();
        var length = document.getElementsByClassName("mainDiv").length;
        let recordsFound = false;
        const noRecordsFoundMessage = document.getElementById("noRecordsFound");

        for (var i = 0; i < length; i++) {
            if (
                document
                    .getElementsByClassName("mainDiv")
                [i].innerHTML.toUpperCase()
                    .indexOf(filter) > -1
            ) {
                document.getElementsByClassName("mainDiv")[i].style.display = "table-row";
                recordsFound = true;
            } else {
                document.getElementsByClassName("mainDiv")[i].style.display = "none";
            }
        }

        if (recordsFound) {
            noRecordsFoundMessage.style.display = "none";
        } else {
            noRecordsFoundMessage.style.display = "block";
        }
    };

    const {
        data: getUsers,
        refetch: getUsersRefetch,
    } = useGetUsersQuery();

    const {
        data: getQuestionnaires,
        refetch: getQuestionnairesRefetch,
    } = useGetQuestionnairesQuery();

    useEffect(() => {
        getAssesmentsRefetch();
        getUsersRefetch();
        getQuestionnairesRefetch();
    }, []);

    return (
        <>
            <div className='col-12'>

                <h1 className='mb-4'>Assesments</h1>

                <div className='d-flex justify-content-between align-items-center'>
                    <input
                        id='localSearchInput'
                        className='themeInput w-50'
                        placeholder="Search Assesments"
                        type="search"
                        autoComplete="off"
                        onChange={(e) => localSearchTableFunction(e.target.value)}
                    />

                    <Button
                        label="+ Add Assesment"
                        className="themeBtn"
                        onClick={() => navigate(PATHS.addAssesment, { state: { users: getUsers, quest: getQuestionnaires } })}
                    />
                </div>

                <Table className="no-wrap mt-5 align-middle" responsive borderless>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Partner</th>
                            <th>Project</th>
                            <th>Country</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Collectors</th>
                            <th>Records</th>
                            <th>Created by</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length ? paginatedData?.map((data, index) => {
                            return (
                                <tr className="border-top mainDiv cursor" key={index} onClick={() => navigate(PATHS.viewAssesmentForAdmin, { state: { data: data } })}>
                                    <td>{data?.title}</td>
                                    <td>{data?.partner?.name}</td>
                                    <td>{data?.project?.name}</td>
                                    <td>{data?.country?.name}</td>
                                    <td>{moment(data?.dueDate).format("DD-MM-YYYY")}</td>
                                    <td className='text-capitalize'>{data?.status}</td>
                                    <td>{data?.users?.length}</td>
                                    <td>{data?.numberOfRecords}</td>
                                    <td>{data?.createdBy?.firstName} {data?.createdBy?.lastName}</td>
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <div className='d-flex align-items-center gap-3'>
                                            <img src={edit} alt="" className='actionIcons' onClick={() => navigate(PATHS.addAssesment, { state: { data: data, users: getUsers, quest: getQuestionnaires } })} title='Edit' />
                                            <img src={del} alt="" className='actionIcons' onClick={() => deleteModalHandler(data)} title='Delete' />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            {getAssesmentsLoading ?
                                <td colSpan={10}>
                                    <div className='text-center'>
                                        <img src={loader} height={100} alt="" />
                                    </div>
                                </td>
                                : <td colSpan={10}>
                                    <h2 className='text-center mt-5'>No Record Found</h2>
                                </td>
                            }
                        </tr>}

                        <tr>
                            <td colSpan={10}>
                                <h2 className='text-center mt-5' id="noRecordsFound" style={{ display: "none" }}>No Record Found</h2>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

                {addAssesmentModal &&
                    <AddAssesmentModal
                        handleCloseAddAssesmentModal={addAssesmentsModalHandler}
                        action={addAssesmentApiHandler}
                        loading={addAssesmentsLoading}
                    />
                }

                {editAssesmentModal &&
                    <AddAssesmentModal
                        handleCloseAddAssesmentModal={editAssesmentsModalHandler}
                        action={editAssesmentsApiHandler}
                        loading={editAssesmentsLoading}
                        data={viewAssesmentData}
                    />
                }

                {viewAssesmentModal &&
                    <AddAssesmentModal
                        handleCloseAddAssesmentModal={viewAssesmentsModalHandler}
                        data={viewAssesmentData}
                        view={true}
                    />
                }

                {deleteModal &&
                    <DeleteModal
                        handleCloseDeleteModal={deleteModalHandler}
                        confirmationMessage="Are you sure you want to delete this"
                        name="Assesment"
                        id={viewAssesmentData?._id}
                        action={deleteAssesmentsApiHandler}
                        loading={deleteAssesmentsLoading}
                    />
                }
            </div>
        </>
    )
}

export default Assesments;