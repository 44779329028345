import React from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";

const ChangeStatusModal = (props) => {

    return (
        <ModalComponent
            size="md"
            show={true}
            handleClose={props?.handleCloseChangeStatusModal}
        >
            <Modal.Body className="overflow p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="modalLabel text-center">{props?.confirmationMessage}
                        <span className="text-capitalize"> {props?.name}</span>?
                    </h2>
                </div>

                <div className="text-center">
                    <Button
                        label="Confirm"
                        className='mt-4 w-25 themeBtn'
                        onClick={() => {
                            props?.action(props?.data)
                        }}
                        loading={props?.loading}
                    />
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default ChangeStatusModal;