import { Link } from "react-router-dom";
import { halfLogo } from "../assets/imagesPath";

const Logo = () => {
  return (
    <Link to="/">
      <img src={halfLogo} alt="" className="w-100" style={{ height: "8rem" }} />
    </Link>
  );
};

export default Logo;
