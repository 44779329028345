import React, { useEffect } from "react";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddTeacherTrainingModal = (props) => {

    const validationSchema = Yup.object().shape({
        Title: Yup.string().required("Title is required"),
        DateFrom: Yup.string().required("Date From is required"),
        DateTo: Yup.string().required('Date To is required'),
        Provider: Yup.string().required("Provider is required"),
        Duration: Yup.string().required("Duration is required"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {

        const data = {
            title: values?.Title,
            dateFrom: values?.DateFrom,
            dateTo: values?.DateTo,
            duration: values?.Duration,
            provider: values?.Provider,
        };

        props?.trainingData(data);
    };

    // useEffect(() => {
    //     if (props?.data) {
    //         let { name, address, industry, type } = props?.data;
    //         reset({ name: name, address: address, industry: industry, type: type });
    //     }
    // }, [props?.data]);

    return (
        <ModalComponent
            size="lg"
            show={true}
            handleClose={props.handleCloseAddTeacherTrainingModal}
        >
            <Modal.Body className="customScroll">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">{props?.data ? "Edit" : "Add"} Teacher Training</h1>
                        <div className="row mt-4">

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Title</label>
                                <input type="text" className="themeInput fs-4" placeholder='Title' {...register("Title")} />

                                {errors.Title && <p className='errorMsg'>{errors.Title.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Provider</label>
                                <input type="text" className="themeInput fs-4" placeholder='Provider' {...register("Provider")} />

                                {errors.Provider && <p className='errorMsg'>{errors.Provider.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Date From</label>
                                <input type="date" className="themeInput fs-4" {...register("DateFrom")} />

                                {errors.DateFrom && <p className='errorMsg'>{errors.DateFrom.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Date To</label>
                                <input type="date" className="themeInput fs-4" {...register("DateTo")} />

                                {errors.DateTo && <p className='errorMsg'>{errors.DateTo.message}</p>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="fs-4 mb-3">Duration</label>
                                <input type="text" className="themeInput fs-4" placeholder='Duration' {...register("Duration")} />

                                {errors.Duration && <p className='errorMsg'>{errors.Duration.message}</p>}
                            </div>

                        </div>

                        <div className="text-center">
                            <Button
                                label="Submit"
                                className="mt-5 themeBtn"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalComponent >
    );
};

export default AddTeacherTrainingModal;