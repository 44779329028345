import React, { memo } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    FormControlLabel,
    Switch
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomSelect from './CustomSelect';

function Question({ question, updateQuestion, deleteQuestion, viewOnly }) {
    const handleAddOption = () => {
        const newOption = '';
        updateQuestion({
            ...question,
            options: [...question.options, newOption]
        });
    };

    const handleDeleteOption = (optionIndex) => {
        updateQuestion({
            ...question,
            options: question.options.filter((_, index) => index !== optionIndex)
        });
    };

    return (
        <Paper sx={{ p: 3, bgcolor: '#f8f8f8', boxShadow: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={10}>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Please type your question label"
                        value={question.text}
                        onChange={(e) => {
                            updateQuestion({ ...question, text: e.target.value });
                        }}
                    />
                </Grid>
                {viewOnly ? null :
                    <Grid item lg={2}>
                        <CustomSelect value={question.type} onChange={(e) => updateQuestion({ ...question, type: e.target.value })} />
                    </Grid>
                }
            </Grid>

            {(question?.type === 'checkbox' || question?.type === 'radio') && (
                <Box sx={{ p: 2, width: 1 }}>
                    {question?.options?.map((option, index) => (
                        <Grid container spacing={1} key={index} justifyContent="center" my={0.5}>
                            <Grid item lg={6}>
                                <TextField
                                    fullWidth
                                    placeholder={`Option ${index + 1}`}
                                    value={option}
                                    onChange={(e) => {
                                        const newOptions = [...question.options];
                                        newOptions[index] = e.target.value;
                                        updateQuestion({ ...question, options: newOptions });
                                    }}
                                />
                            </Grid>
                            <Grid item lg={1}>
                                <Button fullWidth variant="contained" onClick={() => handleDeleteOption(index)}>
                                    -
                                </Button>
                            </Grid>
                            <Grid item lg={1}>
                                <Button fullWidth variant="contained" onClick={handleAddOption}>
                                    +
                                </Button>
                            </Grid>
                        </Grid>
                    ))}

                    {question?.options?.length == 0 && (
                        <Button variant="contained" onClick={handleAddOption}>
                            Add Option
                        </Button>
                    )}
                </Box>
            )}

            {viewOnly ? null :
                <Grid mt={2} lg={12} container justifyContent="flex-end">
                    <FormControlLabel
                        checked={question?.required}
                        onChange={(e) => updateQuestion({ ...question, required: e.target.checked })}
                        control={<Switch />}
                        label="Required"
                    />
                    <IconButton onClick={() => deleteQuestion(question._id)}>
                        <DeleteIcon sx={{ fontSize: 22, color: 'red' }} />
                    </IconButton>
                </Grid>
            }
        </Paper>
    );
}

export default memo(Question);
